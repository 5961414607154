import React, { FC, FormEvent, ChangeEvent, useId } from "react";

import { useDropdown } from "~/hooks";

import {
  FormSubmitButton,
  DropDownField,
  Form,
  RoundedRadioButtonField,
} from "~/components/atoms";
import { LabelWithCheckBox } from "~/components/molecules";
import { TextEditor } from "~/components/organisms";

import {
  EditorStateType,
  AvatarAndNameEmployeeType,
  PreviewWithFileType,
  ProvidingServiceType,
  JobCategoryPhaseType,
  DropDownOnChangeType,
  OptionType,
  AttachFileType,
  RawDraftContentState,
} from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  mentions: AvatarAndNameEmployeeType[];
  files: PreviewWithFileType[];
  onChangeFiles: (files: PreviewWithFileType[]) => void;
  editorState: EditorStateType;
  readOnly?: boolean;
  setEditorState: (editorState: EditorStateType) => void;
  selectableProvidingServices: Pick<ProvidingServiceType, "id" | "name">[];
  selectedProvidingService?: OptionType;
  setProvidingService: (newValue: DropDownOnChangeType<OptionType>) => void;
  selectableJobCategoryPhases: Pick<JobCategoryPhaseType, "id" | "name">[];
  selectedJobCategoryPhase?: {
    label: string;
    value: string;
  };
  onChangeJobCategoryPhase: (e: ChangeEvent<HTMLInputElement>) => void;
  shareable: boolean;
  setShareable: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  submitButtonLabel?: string;
  className?: string;
  defaultAutoFucus?: boolean;
  blockQuote?: {
    content: RawDraftContentState;
    employee: AvatarAndNameEmployeeType;
    files: AttachFileType[];
    createdAt: string;
  };
};

export const InsightPostForm: FC<PropsType> = ({
  isSubmitting,
  mentions,
  files,
  onChangeFiles,
  editorState,
  readOnly = false,
  setEditorState,
  selectableProvidingServices,
  selectedProvidingService,
  setProvidingService,
  selectableJobCategoryPhases,
  selectedJobCategoryPhase,
  onChangeJobCategoryPhase,
  shareable,
  setShareable,
  handleSubmit,
  submitButtonLabel = "投稿する",
  className = "",
  defaultAutoFucus = false,
  blockQuote,
}: PropsType) => {
  const [dropdown] = useDropdown();

  const withAllSelectableJobCategoryPhases = [
    { id: "", name: "すべて", jobCategoryId: "", displayOrder: 0 },
    ...selectableJobCategoryPhases,
  ];

  const uniqueId = useId();

  return (
    <Form className={`space-y-6 ${className}`} onSubmit={handleSubmit}>
      <TextEditor
        readOnly={readOnly}
        mentions={mentions}
        editorState={editorState}
        setEditorState={setEditorState}
        files={files}
        onChangeFiles={onChangeFiles}
        required
        defaultAutoFucus={defaultAutoFucus}
        blockQuote={blockQuote}
      />
      <DropDownField
        name="providingServiceId"
        options={dropdown.convertToDropdown(selectableProvidingServices)}
        placeholder="全ジャンル共通(ジャンル選択)"
        value={selectedProvidingService}
        onChange={setProvidingService}
      />
      {/* HACK: RoundedRadioButtonFieldのクラスのmt-3にあわせて強制的にmt-3をつけているが、同じスタイルのままここのmt-3は削除したい*/}
      <div className="flex flex-wrap !mt-3">
        {withAllSelectableJobCategoryPhases.map((jobCategoryPhase) => (
          <RoundedRadioButtonField
            key={jobCategoryPhase.id}
            name={`jobCategoryPhaseId-${uniqueId}-${jobCategoryPhase.id}`}
            value={jobCategoryPhase.id}
            labelText={jobCategoryPhase.name}
            checked={selectedJobCategoryPhase?.value === jobCategoryPhase.id}
            onChange={onChangeJobCategoryPhase}
            className="mr-3 mt-3"
          />
        ))}
      </div>
      <div>
        <LabelWithCheckBox
          labelText="シェア可能"
          name="shareable"
          labelEnd
          checked={shareable}
          onChange={setShareable}
          labelClassName="text-secondary-600"
        />
        <FormSubmitButton
          color="primary"
          className="w-full mt-2"
          value={submitButtonLabel}
          isReadOnly={isSubmitting}
        />
      </div>
    </Form>
  );
};
