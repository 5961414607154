import React, { FC } from "react";

import { calculateMaxPoint } from "~/utils";

import { useBoolean } from "~/hooks";

import { BorderedTitle, Icon } from "~/components/atoms";

import { SkillEvaluationStandardSummaryDetail } from "./SkillEvaluationStandardSummaryDetail";

import { SkillEvaluationStandardSummaryCategoryWithSummariesType } from "~/domains";

type PropsType = {
  skillEvaluationStandardSummaryCategory: SkillEvaluationStandardSummaryCategoryWithSummariesType;
  className?: string;
  memberSelectedSkillEvaluationStandardIds?: string[];
  managerSelectedSkillEvaluationStandardIds?: string[];
};

export const SkillEvaluationStandardSummaryCategoryDetail: FC<PropsType> = ({
  skillEvaluationStandardSummaryCategory,
  className = "",
  memberSelectedSkillEvaluationStandardIds = [],
  managerSelectedSkillEvaluationStandardIds = [],
}: PropsType) => {
  const { isChecked, toggle } = useBoolean(false);
  const { name, skillEvaluationStandardSummaries } =
    skillEvaluationStandardSummaryCategory;
  const maxPoints = skillEvaluationStandardSummaries.map((summary) =>
    calculateMaxPoint(
      summary.skillEvaluationStandards.map((standard) => standard.point),
    ),
  );
  const sumMaxPoint = maxPoints.reduce((sum, element) => sum + element, 0);
  return (
    <div className={className}>
      <BorderedTitle title={name} />
      <div className="mt-6 space-y-6">
        <button
          type="button"
          onClick={toggle}
          className="text-lg border-b border-secondary-400 pb-2 font-semibold flex items-center justify-end w-full text-secondary-600"
        >
          <div className="flex items-center space-x-2">
            <span>/ {sumMaxPoint}</span>
            <Icon
              icon={isChecked ? "ioRemoveOutline" : "ioAdd"}
              size="1.25rem"
            />
          </div>
        </button>
        {skillEvaluationStandardSummaries.map((summary, index) => (
          <SkillEvaluationStandardSummaryDetail
            key={summary.id}
            isOpen={isChecked}
            index={index}
            skillEvaluationStandardSummary={summary}
            memberSelectedSkillEvaluationStandardIds={
              memberSelectedSkillEvaluationStandardIds
            }
            managerSelectedSkillEvaluationStandardIds={
              managerSelectedSkillEvaluationStandardIds
            }
          />
        ))}
      </div>
    </div>
  );
};
