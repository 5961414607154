import React, { FC } from "react";

import Logo from "~/assets/images/logos/kagami.png";

const LogoSize = {
  md: { class: "w-9", width: "36", height: "36" },
  lg: { class: "w-16", width: "64", height: "64" },
};

type PropsType = {
  className?: string;
  size: keyof typeof LogoSize;
};

export const LogoImage: FC<PropsType> = ({
  size,
  className = "",
}: PropsType) => {
  return (
    <img
      src={Logo as string}
      alt="Logo"
      width={LogoSize[size].width}
      height={LogoSize[size].height}
      className={`shrink-0 p-1 ${LogoSize[size].class} ${className}`}
    />
  );
};
