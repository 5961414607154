import { SlideNavigationType } from "~/components/molecules";

import { EmployeeRoleType, ManagerRole, OwnerRole } from "~/domains";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  selectedRole?: EmployeeRoleType;
  businessSectionId?: string;
};

export const useDocumentFileCategoriesSlideNavigation = ({
  selectedRole,
  businessSectionId = "",
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "本部",
      href: `/document_file_category/owner?business_section_id=${businessSectionId}`,
      selected: selectedRole === OwnerRole,
    },
    {
      label: "部署",
      href: `/document_file_category/manager?business_section_id=${businessSectionId}`,
      selected: selectedRole === ManagerRole,
    },
  ];

  return {
    navigations,
  };
};
