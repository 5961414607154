import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useApisMembersInsightPostCommentsInsightPostWithCommentsShow,
  useApisMembersInsightPostsNew,
  useRightSidebarState,
  useAnchorScroll,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  InsightPostDetailWithEditForm,
  InsightPostCommentsWithInsightPost,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersInsightPostCommentsShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const { isReplyOpen, setReplyOpen } = useRightSidebarState("isReplyOpen");

  const breadCrumbItems = [
    { label: "リフレクション", href: "/insight_posts" },
    { label: "詳細", href: `/insight_post_comments/${id}` },
  ];

  const { data: fetchNewData } = useApisMembersInsightPostsNew();
  const { data, refetch, isError } =
    useApisMembersInsightPostCommentsInsightPostWithCommentsShow({
      insightPostCommentId: id,
    });

  useEffect(() => {
    setReplyOpen();
  }, [setReplyOpen]);

  useAnchorScroll(!!data);

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {data && (
            <InsightPostDetailWithEditForm
              insightPost={data.insightPost}
              mentions={fetchNewData?.mentionableEmployees || []}
              selectableProvidingServices={
                fetchNewData?.selectableProvidingServices || []
              }
              selectableJobCategoryPhases={
                fetchNewData?.selectableJobCategoryPhases || []
              }
              openComment={setReplyOpen}
              deleteItem={() => navigate("/")}
              className="mt-2"
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {isReplyOpen && data && (
            <>
              <RightSidebarTitle title="リプライ" />
              <InsightPostCommentsWithInsightPost
                className="mt-6 mx-4"
                insightPost={data.insightPost}
                mentions={fetchNewData?.mentionableEmployees || []}
                insightPostComments={data.insightPostComments}
                refetch={refetch}
                highlightInsightPostCommentId={id}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
