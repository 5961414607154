/**
 * 2次元配列をCSV形式のBlobに変換する関数
 *
 * @param rows - CSVの各行データを含む2次元配列
 * @returns Blob - CSVデータを格納したBlobオブジェクト
 *
 * @example
 * const rows = [
 *   ["日時", "部署", "課", "氏名"],
 *   ["2024/10/31 10:10", "飲食事業部", "カフェサンタ", "松原悠馬"]
 * ];
 * arrayToCsv(rows) は次のCSV形式のBlobを生成します：
 *
 * "日時,部署,課,氏名\n2024/10/31 10:10,飲食事業部,カフェサンタ,松原悠馬"
 */
export const arrayToCsv = (rows: (string | number | undefined)[][]): Blob => {
  // 各行（row）をCSVの1行の形式に変換して結合し、CSVデータを作成
  const csvContent = rows
    .map(
      (row) =>
        // 各要素（item）をCSVのセルの形式に変換して結合
        row
          .map((item) =>
            // itemがカンマ、改行、またはダブルクォーテーションを含む場合は、ダブルクォーテーションで囲み、内部のダブルクォーテーションをエスケープ
            item?.toString()?.includes(",") ||
            item?.toString()?.includes("\n") ||
            // eslint-disable-next-line quotes
            item?.toString()?.includes('"')
              ? // eslint-disable-next-line quotes
                `"${item?.toString()?.replace(/"/g, '""')}"`
              : // 特殊文字を含まない場合はそのまま文字列に変換
                item,
          )
          .join(","), // 各itemをカンマで区切り、CSVの1行を作成
    )
    .join("\n"); // 各行を改行で区切り、CSV全体の内容を作成

  /**
   * @example
   * rowsが次のような2次元配列の場合:
   * [
   *   ["日時", "部署", "課", "氏名"],
   *   ["2024/10/31 10:10", "飲食事業部", "カフェサンタ", "松原悠馬"]
   * ]
   *
   * `csvContent`は次のような文字列になります:
   * "日時,部署,課,氏名\n2024/10/31 10:10,飲食事業部,カフェサンタ,松原悠馬"
   *
   * - カンマで区切られた各項目が1行として扱われ、行ごとに改行で区切られています
   * - 改行やカンマを含むセルは、ダブルクォーテーションで囲まれ、内部のダブルクォーテーションはエスケープされます
   */

  // 作成したCSV内容をBlobに変換し、テキスト/CSV形式として返却
  return new Blob([`\uFEFF${csvContent}`], { type: "text/csv" });
};
