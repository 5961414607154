import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useApisMembersKnowledgeInsightPostsShow,
  useBoolean,
  useKnowledgeSlideNavigation,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  KnowledgeInsightPostDetailWithEditForm,
  KnowledgeInsightPostCommentsWithKnowledgeInsightPost,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import { ManagerRole } from "~/domains";

export const MembersKnowledgeInsightPostsShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const { isChecked: replayOpen, toggle } = useBoolean(true);
  const { data, refetch, isError } = useApisMembersKnowledgeInsightPostsShow({
    id,
  });
  const breadCrumbItems = [
    { label: "ナレッジシェア", href: `/knowledge_post/${ManagerRole.type}` },
    { label: "詳細", href: `/knowledge_insight_posts/${id}` },
  ];
  const { navigations } = useKnowledgeSlideNavigation({
    url: "/knowledge_post",
    selectedRole: data ? data.knowledgeInsightPost.employeeRole : ManagerRole,
  });

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
          {data && (
            <KnowledgeInsightPostDetailWithEditForm
              knowledgeInsightPost={data.knowledgeInsightPost}
              openComment={toggle}
              deleteItem={() => navigate("/knowledge_post/team")}
              isHighlight={data.knowledgeInsightPost.id === id}
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {replayOpen && data && (
            <>
              <RightSidebarTitle title="リプライ" />
              <KnowledgeInsightPostCommentsWithKnowledgeInsightPost
                className="mt-6 mx-4"
                refetch={refetch}
                mentions={data.mentionableEmployees}
                knowledgeInsightPost={data.knowledgeInsightPost}
                knowledgeInsightPostComments={data.knowledgeInsightPostComments}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
