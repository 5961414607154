import React, { FC } from "react";

import {
  useApisAnalyticsPlansEmployeesInsightPostObjectivesIndex,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { AnalyticsInsightPostObjectivesTemplate } from "~/components/templates";

import { EmployeeRole } from "~/domains";

export const AnalyticsPlansInsightPostObjectivesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const breadCrumbItems = [
    {
      label: "リフレクション目標分析",
      href: "/analytics_plans/insight_post_objectives",
    },
  ];

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployee,
    onEmployeeChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    isSelectEmployee: true,
    selectableEmployeeRoles: [EmployeeRole.MANAGER, EmployeeRole.MEMBER],
  });

  const { data, isLoading, hasNextPage, fetchNextPage } =
    useApisAnalyticsPlansEmployeesInsightPostObjectivesIndex({
      employeeId: selectedEmployee?.id || "",
      config: {
        enabled: !!selectedEmployee?.id,
      },
    });

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <AnalyticsInsightPostObjectivesTemplate
            isLoading={isLoading}
            data={data}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            selectedDivisions={selectedDivisions}
            optionSelectedSections={optionSelectedSections}
            selectableDivisions={selectableDivisions}
            optionSelectableSections={optionSelectableSections}
            selectedDropdownSections={optionSelectedSections}
            selectedEmployee={selectedEmployee}
            onEmployeeChange={onEmployeeChange}
            selectableEmployees={selectableEmployees}
            onDivisionChange={onDivisionChange}
            onSectionChange={onSectionChange}
          />
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
