import React, { FC, FormEvent, ChangeEvent } from "react";

import LogoLandscape from "~/assets/images/logos/kagami_landscape.png";

import {
  FormSubmitButton,
  Form,
  Link,
  SignInWrapper,
} from "~/components/atoms";
import {
  DisplayablePasswordFiled,
  LabelWithTextField,
} from "~/components/molecules";

type PropsType = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  loginText?: string;
};

export const SignIn: FC<PropsType> = ({
  handleSubmit,
  onChangeEmail,
  onChangePassword,
  className = "",
  loginText,
}: PropsType) => {
  return (
    <SignInWrapper className={className}>
      <div className="mx-6 px-4 pb-12 pt-11 miniTablet:px-14 max-w-xl w-full bg-white border-secondary-400 border-solid border rounded-lg shadow">
        <div className="flex justify-center w-8/12 mx-auto p-3">
          <img
            src={LogoLandscape as string}
            alt="Logo"
            width="269"
            height="58.5"
          />
        </div>
        {loginText && (
          <h1 className="text-center font-semibold text-lg mt-4 text-secondary-600">
            {loginText}
          </h1>
        )}
        <Form
          className={`${loginText ? "mt-6" : "mt-12"} rounded-lg mx-auto`}
          onSubmit={handleSubmit}
        >
          <LabelWithTextField
            labelText="メールアドレス"
            type="email"
            placeholder="email@example.com"
            name="email"
            autoComplete="email"
            onChange={onChangeEmail}
            size="md"
          />
          <DisplayablePasswordFiled
            label="パスワード"
            name="password"
            autoComplete="current-password"
            onChange={onChangePassword}
            size="md"
            className="mt-6"
          />
          <div className="text-center mt-9">
            <FormSubmitButton
              value="ログインする"
              color="primary"
              className="w-full"
              size="lg"
            />
          </div>
        </Form>
        <div className="text-center mt-9">
          <Link
            to="/password/reset"
            className="text-primary-600 hover:text-primary-800"
          >
            パスワードをお忘れですか？
          </Link>
        </div>
      </div>
    </SignInWrapper>
  );
};
