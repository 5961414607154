import React, { FC } from "react";

import ErrorImage from "~/assets/images/500.png";

import { ButtonSizes, ButtonColor } from "~/constants/buttonAndForm";

export const ErrorFallback: FC = () => {
  return (
    <>
      <div className="mt-6 w-full miniTablet:w-1/2 px-6 mx-auto text-center">
        <div className="">
          <img src={ErrorImage as string} alt="404" className="" />
        </div>
        <p className="mt-10 text-left miniTablet:text-center miniTablet:text-lg font-bold">
          大変申し訳ございません。システムエラーが発生しました。
          <br />
          通信環境やデバイスに問題がなく、何度もエラーが発生する場合は
          <br />
          大変お手数ですが、KAGAMI担当までご連絡をお願いします。
        </p>
        <a
          href="/"
          className={`${ButtonSizes["md"]} ${ButtonColor["contain"]["primary"]} border border-solid rounded mt-8 w-full block`}
        >
          トップページはこちら
        </a>
      </div>
    </>
  );
};
