import React, { FC, ReactNode, useId } from "react";

import ReactInfiniteScrollComponent from "react-infinite-scroll-component";

import { Button, NoContentMessage } from "~/components/atoms";
import { Loading } from "~/components/molecules";

type PropsType = {
  itemsLength: number | undefined;
  nextFetchFunction: () => void;
  hasMore: boolean | undefined;
  children: ReactNode;
  className?: string;
  scrollClassName?: string;
  moreButtonClassName?: string;
  noContentMessage?: string;
  inverse?: boolean;
  scrollableTarget?: string;
  isLoading?: boolean;
  height?: string;
};

export const InfiniteScrollWithMoreButton: FC<PropsType> = ({
  itemsLength = 0,
  nextFetchFunction,
  hasMore = false,
  children,
  inverse = false,
  className = "",
  scrollClassName = "",
  moreButtonClassName = "",
  scrollableTarget,
  noContentMessage = "データがありません",
  isLoading = false,
  height,
}: PropsType) => {
  const uniqueId = useId();
  return (
    <div id={scrollableTarget || uniqueId} className={className}>
      <ReactInfiniteScrollComponent
        dataLength={itemsLength}
        next={nextFetchFunction}
        hasMore={hasMore}
        loader={<></>}
        scrollableTarget={scrollableTarget || uniqueId}
        inverse={inverse}
        height={height}
        className={`flex hidden-scrollbar ${
          inverse
            ? "flex-col-reverse !overflow-auto"
            : "flex-col !overflow-visible"
        } ${scrollClassName}`}
      >
        {children}
      </ReactInfiniteScrollComponent>
      {isLoading ? (
        <Loading />
      ) : hasMore ? (
        <div className={`text-center mt-9 ${moreButtonClassName}`}>
          <Button
            onClick={nextFetchFunction}
            text="もっと見る"
            color="gray"
            outline
            size="sm"
            className="!text-primary-600 w-full"
          />
        </div>
      ) : (
        itemsLength < 1 && (
          <NoContentMessage text={noContentMessage} className="mt-4" />
        )
      )}
    </div>
  );
};
