import React, { ChangeEvent, FC, FormEvent, MouseEvent } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersMeetingNotesCompletedsUpdate,
  useApisManagersMeetingNotesDestroy,
} from "~/hooks";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
} from "~/components/atoms";
import {
  LabelWithTextField,
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
  RangeDatePicker,
  LabelWithDropDownField,
  LabelWithMultipleDropdownField,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ChildType,
  MeetingNoteType,
  MultiValueType,
  OptionType,
  ParentType,
  RangeDatePropsType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  className?: string;
  meetingNotes: MeetingNoteType[];
  hasNextPage?: boolean;
  keyword: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  selectableDropdownCompleteStatusIds: OptionType[];
  selectedDropdownCompleteStatusId?: SingleValueType<OptionType>;
  selectableDropdownCreatedEmployees: OptionType[];
  selectedDropdownCreatedEmployees: MultiValueType<OptionType>;
  selectableDropdownParticipationEmployees: OptionType[];
  selectedDropdownParticipationEmployees: MultiValueType<OptionType>;
  selectableDropdownDivisions: BusinessDivisionType[];
  selectedDropdownDivisions: BusinessDivisionType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onChangeDropdownCompleteStatusId: (
    value: SingleValueType<OptionType>,
  ) => void;
  onChangeDropdownCreatedEmployees: (value: MultiValueType<OptionType>) => void;
  onChangeDropdownParticipationEmployees: (
    value: MultiValueType<OptionType>,
  ) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  updateIndexItem: (item: MeetingNoteType, index: number) => void;
  findAndRemove: (func: (item: MeetingNoteType) => boolean) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  fetchNextPage: () => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export const ManagersMeetingNotesIndexTemplate: FC<PropsType> = ({
  className = "",
  meetingNotes,
  hasNextPage,
  isLoading,
  startDate,
  endDate,
  keyword,
  selectableDropdownCompleteStatusIds,
  selectedDropdownCompleteStatusId,
  selectableDropdownCreatedEmployees,
  selectedDropdownCreatedEmployees,
  selectableDropdownParticipationEmployees,
  selectedDropdownParticipationEmployees,
  selectableDropdownDivisions,
  selectedDropdownDivisions,
  selectableDropdownSections,
  selectedDropdownSections,
  onDivisionChange,
  onSectionChange,
  onChangeDropdownCreatedEmployees,
  onChangeDropdownParticipationEmployees,
  onChangeDropdownCompleteStatusId,
  fetchNextPage,
  onChangeDateRange,
  updateIndexItem,
  findAndRemove,
  onChangeKeyword,
  onConditionReset,
  onSearchSubmit,
}: PropsType) => {
  const { mutate: deleteRequest } = useApisManagersMeetingNotesDestroy();

  const { mutate: completeRequest } =
    useApisManagersMeetingNotesCompletedsUpdate();

  const handleDelete = (meetingNote: MeetingNoteType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: meetingNote.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === meetingNote.id);
        },
      },
    );
  };

  const handleComplete = ({
    meetingNote,
    index,
  }: {
    meetingNote: MeetingNoteType;
    index: number;
  }) => {
    const isConfirm = confirm("本当に完了しますか？");
    if (!isConfirm) return;

    completeRequest(
      {
        meetingNoteId: meetingNote.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          updateIndexItem(data.meetingNote, index);
        },
      },
    );
  };

  const generateMeetingNoteLists = (meetingNote: MeetingNoteType) => {
    return [
      meetingNote.postDate,
      meetingNote.title,
      meetingNote.businessSections.map((section) => section.name).join("、"),
      meetingNote.employee.name,
      meetingNote.participationEmployees.map((employee) => employee.name),
      meetingNote.completedAt ? "完了" : "未完了",
    ];
  };
  return (
    <div className={className}>
      <Form onSubmit={onSearchSubmit} className="space-y-6">
        <div className="grid miniTablet:grid-cols-2 gap-4">
          <MultiParentsWithMultiChildrenDropdownField
            allParents={selectableDropdownDivisions}
            parentsValue={selectedDropdownDivisions}
            parentLabel="部署"
            parentsOnChange={onDivisionChange}
            allChildren={selectableDropdownSections}
            childrenValue={selectedDropdownSections}
            childLabel="課"
            childrenOnChange={onSectionChange}
          />
          <LabelWithMultipleDropdownField
            name="createdEmployees"
            options={selectableDropdownCreatedEmployees}
            value={selectedDropdownCreatedEmployees}
            onChange={onChangeDropdownCreatedEmployees}
            labelText="作成者"
          />
          <RangeDatePicker
            labelText="期間指定"
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeDateRange}
          />
          <LabelWithMultipleDropdownField
            name="participationEmployees"
            options={selectableDropdownParticipationEmployees}
            value={selectedDropdownParticipationEmployees}
            onChange={onChangeDropdownParticipationEmployees}
            labelText="参加者"
          />
          <LabelWithDropDownField
            labelText="ステータス"
            name="completed"
            options={selectableDropdownCompleteStatusIds}
            value={selectedDropdownCompleteStatusId}
            onChange={onChangeDropdownCompleteStatusId}
          />
          <LabelWithTextField
            labelText="フリーワード"
            type="search"
            name="keyword"
            placeholder="フリーワード検索"
            value={keyword}
            onChange={onChangeKeyword}
          />
        </div>
        <div className="flex items-center justify-end space-x-4">
          <Button
            color="gray"
            outline
            className="w-full miniTablet:w-auto"
            text="リセット"
            onClick={onConditionReset}
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full miniTablet:w-auto"
          />
        </div>
      </Form>
      <InfiniteScrollWithMoreButton
        itemsLength={meetingNotes.length}
        nextFetchFunction={fetchNextPage}
        isLoading={isLoading}
        hasMore={hasNextPage}
        className="mt-9"
      >
        <div className="grid grid-cols-6">
          <GridHeaderCells
            texts={[
              "日付",
              "タイトル",
              "部署",
              "作成者",
              "参加者",
              "ステータス",
            ]}
          />
          {meetingNotes.map((meetingNote, index) => (
            <GridRow
              key={meetingNote.id}
              lists={generateMeetingNoteLists(meetingNote)}
              openedMenu={
                <OpenedMenuInGrid
                  cols={6}
                  buttons={[
                    {
                      text: "編集",
                      link: `/managers/meeting_notes/${meetingNote.id}/edit`,
                    },
                    {
                      text: "削除",
                      onClick: () => handleDelete(meetingNote),
                    },
                    {
                      text: meetingNote.completedAt ? "未完了" : "完了",
                      onClick: () => {
                        handleComplete({
                          meetingNote,
                          index,
                        });
                      },
                    },
                  ]}
                />
              }
            />
          ))}
        </div>
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
