import React, { FC } from "react";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { InsightFocusKaizenContentTemplateForm } from "~/components/organisms";

export const ManagersInsightFocusKaizenContentTemplatesNew: FC = () => {
  const breadCrumbItems = [
    {
      label: "各種テンプレ",
      href: "/managers/insight_focus_kaizen_content_templates",
    },
    {
      label: "新規作成",
      href: "/managers/insight_focus_kaizen_content_templates/new",
    },
  ];

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          <InsightFocusKaizenContentTemplateForm />
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    </>
  );
};
