import { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";

import {
  useInput,
  useArray,
  useProvidersEmployeeLayout,
  useApisMembersEmployeeSurveySubmissionsIndex,
  usePostSortType,
  useCompleteStatusDropdown,
  useDateRange,
  useMultipleDropdown,
  useApisMembersEmployeeSurveySubmissionsSearchItemsSearchItemsIndex,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
  useBoolean,
} from "~/hooks";

import {
  EmployeeSurveySubmissionType,
  ApiMembersEmployeeSurveySubmissionsIndexRequestType,
  SortType,
  OptionType,
  SingleValueType,
  RangeDatePropsType,
  MultiValueType,
  ChildType,
  ParentType,
} from "~/domains";

type ReturnType = {
  keyword: string;
  isFetching: boolean;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  startDate: Date | null;
  endDate: Date | null;
  employeeSurveySubmissions: EmployeeSurveySubmissionType[];
  sort: SortType;
  selectableEmployeeSurveys: OptionType[];
  selectedEmployeeSurveys: MultiValueType<OptionType>;
  onChangeEmployeeSurveys: (newValue: MultiValueType<OptionType>) => void;
  onDivisionChange: (value: ParentType[]) => void;
  onSectionChange: (value: ChildType[]) => void;
  onEmployeesChange: (value: ParentType[]) => void;
  onArchivedEmployeesChange: (value: ParentType[]) => void;
  selectedDropdownEmployees: ParentType[];
  selectableDropdownEmployees: ParentType[];
  selectableDropdownDivisions: ParentType[];
  selectedDropdownDivisions: ParentType[];
  selectableDropdownSections: ChildType[];
  selectedDropdownSections: ChildType[];
  selectedDropdownArchivedEmployees: ParentType[];
  selectableDropdownArchivedEmployees: ParentType[];
  selectableDropdownHasBookmarkIds: OptionType[];
  selectedDropdownHasBookmarkId?: SingleValueType<OptionType>;
  onChangeDropdownHasBookmarkId: (value: SingleValueType<OptionType>) => void;
  selectableDropdownHasReplyIds: OptionType[];
  selectedDropdownHasReplyId?: SingleValueType<OptionType>;
  onChangeDropdownHasReplyId: (value: SingleValueType<OptionType>) => void;
  isBelongingEmployee: boolean;
  onChangeIsBelongingEmployee: (e: ChangeEvent<HTMLInputElement>) => void;
  setSort: (val: SortType) => void;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onChangeKeyword: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  refetch: () => void;
};

type PropsType = {
  onlyShareable?: boolean;
};

export const useSearchMembersEmployeeSurveySubmissions = ({
  onlyShareable,
}: PropsType = {}): ReturnType => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const { sort, snakeKeyValue, setSort } = usePostSortType();
  const { setFalseRightSidebarMain } = useProvidersEmployeeLayout();
  const { items, setItems } = useArray<EmployeeSurveySubmissionType>([]);

  const [{ value: keyword, onChange: onChangeKeyword }, setKeyword] =
    useInput("");
  const {
    isChecked: isBelongingEmployee,
    onChange: onChangeIsBelongingEmployee,
    setFalse: setBelongingEmployeeFalse,
  } = useBoolean(false);
  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange();
  const [
    {
      statusValue: hasBookmark,
      selectedDropdownCompleteStatusId: selectedDropdownHasBookmarkId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasBookmarkIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasBookmarkId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "ブックマークあり",
      false: "ブックマークなし",
    },
  });
  const [
    {
      statusValue: hasReply,
      selectedDropdownCompleteStatusId: selectedDropdownHasReplyId,
      selectableDropdownCompleteStatusIds: selectableDropdownHasReplyIds,
      onChangeDropdownCompleteStatusId: onChangeDropdownHasReplyId,
    },
  ] = useCompleteStatusDropdown({
    initialStatusNames: {
      true: "リプライあり",
      false: "リプライなし",
    },
  });
  const [
    {
      dropdownSelectableValue: selectableEmployeeSurveys,
      dropdownValue: selectedEmployeeSurveys,
      onChange: onChangeEmployeeSurveys,
      setSelectableOption: setSelectableEmployeeSurveys,
    },
  ] = useMultipleDropdown();
  useApisMembersEmployeeSurveySubmissionsSearchItemsSearchItemsIndex({
    config: {
      enabled: !!currentEmployee,
      onSuccess: (data) => {
        setSelectableEmployeeSurveys(
          data.employeeSurveys.map((survey) => ({
            id: survey.id,
            name: survey.title,
          })),
        );
      },
    },
  });

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployees,
    onEmployeesChange,
    selectableArchivedEmployees,
    selectedArchivedEmployees,
    onArchivedEmployeesChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    isSelectEmployee: true,
  });

  const [params, setParams] =
    useState<ApiMembersEmployeeSurveySubmissionsIndexRequestType>({
      sortType: snakeKeyValue,
      shareable: onlyShareable,
    });

  const { isFetching, fetchNextPage, hasNextPage, refetch } =
    useApisMembersEmployeeSurveySubmissionsIndex({
      params: { ...params },
      config: {
        onSuccess: (data) => {
          setItems(
            data.pages.map((page) => page.employeeSurveySubmissions).flat(),
          );
        },
      },
    });

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setParams({
      keyword,
      isBelongingEmployee,
      sortType: snakeKeyValue,
      hasBookmark,
      hasReply,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      employeeIds: [
        ...selectedEmployees.map((ele) => ele.id),
        ...selectedArchivedEmployees.map((ele) => ele.id),
      ].map((ele) => ele),
      businessDivisionIds: selectedDivisions?.map((division) => division.id),
      businessSectionIds: optionSelectedSections?.map((section) => section.id),
      employeeSurveyIds: selectedEmployeeSurveys.map((survey) => survey.value),
    });
    setFalseRightSidebarMain();
  };

  const onConditionReset = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setKeyword("");
    setParams({
      sortType: snakeKeyValue,
      hasBookmark: undefined,
      hasReply: undefined,
      startDate: null,
      endDate: null,
      isBelongingEmployee: false,
      employeeIds: [],
      businessDivisionIds: [],
      businessSectionIds: [],
    });
    setBelongingEmployeeFalse();
    setFalseRightSidebarMain();
  };

  useEffect(() => {
    setParams((prevParams) => ({
      ...prevParams,
      sortType: snakeKeyValue,
    }));
  }, [snakeKeyValue]);

  return {
    sort,
    setSort,
    keyword,
    isFetching,
    fetchNextPage,
    hasNextPage,
    startDate,
    endDate,
    employeeSurveySubmissions: items,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    onChangeDropdownHasBookmarkId,
    selectableDropdownHasReplyIds,
    selectedDropdownHasReplyId,
    onChangeDropdownHasReplyId,
    onChangeKeyword,
    onChangeDateRange,
    onSearchSubmit,
    onConditionReset,
    refetch,
    selectableEmployeeSurveys,
    selectedEmployeeSurveys,
    onChangeEmployeeSurveys,
    onDivisionChange,
    onSectionChange,
    onEmployeesChange,
    onArchivedEmployeesChange,
    selectedDropdownEmployees: selectedEmployees,
    selectableDropdownEmployees: selectableEmployees,
    selectableDropdownDivisions: selectableDivisions,
    selectedDropdownDivisions: selectedDivisions,
    selectableDropdownSections: optionSelectableSections,
    selectedDropdownSections: optionSelectedSections,
    selectableDropdownArchivedEmployees: selectableArchivedEmployees,
    selectedDropdownArchivedEmployees: selectedArchivedEmployees,
    isBelongingEmployee,
    onChangeIsBelongingEmployee,
  };
};
