import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersArchivedProvidingServicesDestroy,
  useApisManagersArchivedProvidingServicesIndex,
  useApisManagersArchivedProvidingServicesRestoresUpdate,
  useArray,
} from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";

import { ProvidingServiceWithBusinessSectionsType } from "~/domains";

export const ManagersArchivedProvidingServicesIndex: FC = () => {
  const {
    items: providingServices,
    setItems: setProvidingServices,
    findAndRemove,
  } = useArray<ProvidingServiceWithBusinessSectionsType>([]);
  const { fetchNextPage, hasNextPage, isFetching } =
    useApisManagersArchivedProvidingServicesIndex({
      config: {
        onSuccess: (data) => {
          setProvidingServices(
            data.pages.map((page) => page.providingServices).flat(),
          );
        },
      },
    });

  const { mutate: deleteRequest } =
    useApisManagersArchivedProvidingServicesDestroy();
  const { mutate: restoreRequest } =
    useApisManagersArchivedProvidingServicesRestoresUpdate();

  const handleDelete = (
    providingService: ProvidingServiceWithBusinessSectionsType,
  ) => {
    const isConfirm = confirm("この操作は取り消せません。本当に削除しますか？");
    if (!isConfirm) return;
    deleteRequest(
      {
        id: providingService.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((service) => service.id === providingService.id);
        },
      },
    );
  };

  const handleRestore = (
    providingService: ProvidingServiceWithBusinessSectionsType,
  ) => {
    const isConfirm = confirm("本当に有効化しますか？");
    if (!isConfirm) return;
    restoreRequest(
      {
        archivedProvidingServiceId: providingService.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((service) => service.id === providingService.id);
        },
      },
    );
  };

  const generateGridCell = (
    providingService: ProvidingServiceWithBusinessSectionsType,
  ) => {
    return [
      providingService.name,
      providingService.allBusinessSectionsDistribution
        ? "すべての課"
        : providingService.businessSections
            .map((section) => section.name)
            .join(" / "),
    ];
  };

  const breadCrumbItems = [
    { label: "ジャンル設定", href: "/managers/providing_services" },
    { label: "無効ジャンル", href: "/managers/archived_providing_services" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <InfiniteScrollWithMoreButton
          itemsLength={providingServices.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isFetching}
          hasMore={hasNextPage}
          className="mt-12"
        >
          <div className="grid grid-cols-2">
            <GridHeaderCells texts={["ジャンル", "課"]} />
            <>
              {providingServices.map((providingService) => (
                <GridRow
                  key={providingService.id}
                  lists={generateGridCell(providingService)}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={2}
                      buttons={[
                        {
                          text: "有効化",
                          onClick: () => handleRestore(providingService),
                        },
                        {
                          text: "削除",
                          onClick: () => handleDelete(providingService),
                        },
                      ]}
                    />
                  }
                />
              ))}
            </>
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
