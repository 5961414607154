import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersArchivedJobCategoryPhasesDestroy,
  useApisManagersArchivedJobCategoryPhasesIndex,
  useApisManagersArchivedJobCategoryPhasesRestoresUpdate,
  useArray,
} from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";

import { JobCategoryPhaseType } from "~/domains";

export const ManagersArchivedJobCategoryPhasesIndex: FC = () => {
  const {
    items: jobCategoryPhases,
    setItems: setJobCategoryPhases,
    findAndRemove,
  } = useArray<JobCategoryPhaseType>([]);
  const { fetchNextPage, hasNextPage, isFetching } =
    useApisManagersArchivedJobCategoryPhasesIndex({
      config: {
        onSuccess: (data) => {
          setJobCategoryPhases(
            data.pages.map((page) => page.jobCategoryPhases).flat(),
          );
        },
      },
    });

  const { mutate: jobCategoryPhaseDestroy } =
    useApisManagersArchivedJobCategoryPhasesDestroy();
  const { mutate: jobCategoryPhaseRestore } =
    useApisManagersArchivedJobCategoryPhasesRestoresUpdate();

  const deleteJobCategoryPhase = (jobCategoryPhase: JobCategoryPhaseType) => {
    const isConfirm = confirm("この操作は取り消せません。本当に削除しますか？");
    if (!isConfirm) return;
    jobCategoryPhaseDestroy(
      {
        id: jobCategoryPhase.id,
      },
      {
        onSuccess: (res) => {
          findAndRemove((job) => job.id === jobCategoryPhase.id);
          toast(res.message);
        },
      },
    );
  };

  const restoreJobCategoryPhase = (jobCategoryPhase: JobCategoryPhaseType) => {
    const isConfirm = confirm("本当に有効化しますか？");
    if (!isConfirm) return;
    jobCategoryPhaseRestore(
      {
        archivedJobCategoryPhaseId: jobCategoryPhase.id,
      },
      {
        onSuccess: (res) => {
          findAndRemove((job) => job.id === jobCategoryPhase.id);
          toast(res.message);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "職種設定", href: "/managers/job_categories" },
    {
      label: "無効リフレクション項目",
      href: "/managers/archived_job_category_phases",
    },
  ];

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <InfiniteScrollWithMoreButton
            itemsLength={jobCategoryPhases.length}
            nextFetchFunction={fetchNextPage}
            isLoading={isFetching}
            hasMore={hasNextPage}
            className="mt-12"
          >
            <div className="grid grid-cols-1">
              <GridHeaderCells texts={["リフレクション項目名"]} />
              <>
                {jobCategoryPhases.map((jobCategoryPhase) => (
                  <GridRow
                    key={jobCategoryPhase.id}
                    lists={[jobCategoryPhase.name]}
                    openedMenu={
                      <OpenedMenuInGrid
                        cols={1}
                        buttons={[
                          {
                            text: "有効化",
                            onClick: () =>
                              restoreJobCategoryPhase(jobCategoryPhase),
                          },
                          {
                            text: "削除",
                            onClick: () =>
                              deleteJobCategoryPhase(jobCategoryPhase),
                          },
                        ]}
                      />
                    }
                  />
                ))}
              </>
            </div>
          </InfiniteScrollWithMoreButton>
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
