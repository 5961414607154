import React, { FormEvent, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useApisMembersInsightPostBookmarkFoldersDestroy,
  useApisMembersInsightPostBookmarkFoldersUpdate,
  useApisMembersKnowledgeInsightPostBookmarkFoldersDestroy,
  useApisMembersKnowledgeInsightPostBookmarkFoldersUpdate,
  useBoolean,
  useInput,
} from "~/hooks";

import { Link, HoverTooltip } from "~/components/atoms";
import { IconWithText, FloatingMenu } from "~/components/molecules";
import { BookmarkFolderForm } from "~/components/organisms";

import { PostDetailMenuItemType } from "~/domains";

type PropsType = {
  refetch: () => void;
  bookmarkFolderId: string;
  bookmarkFolderName: string;
  folderType: "InsightPost" | "KnowledgeInsightPost";
  breadCrumbHref: string;
};

export const BookmarkFolderNameWithEditForm: FC<PropsType> = ({
  refetch,
  bookmarkFolderId,
  bookmarkFolderName,
  folderType,
  breadCrumbHref,
}: PropsType) => {
  const navigate = useNavigate();
  const href =
    folderType === "InsightPost"
      ? `/bookmark/insight_post_bookmark_folders/${bookmarkFolderId}`
      : `/bookmark/knowledge_insight_post_bookmark_folders/${bookmarkFolderId}`;
  const [{ value, onChange }] = useInput(bookmarkFolderName);
  const editFormOpen = useBoolean(false);
  const menuModalOpen = useBoolean(false);

  const { mutate: deleteInsightBookmarkFolderRequest } =
    useApisMembersInsightPostBookmarkFoldersDestroy();
  const { mutate: deleteKnowledgeInsightBookmarkFolderRequest } =
    useApisMembersKnowledgeInsightPostBookmarkFoldersDestroy();

  const { mutate: updateInsightBookmarkFolderRequest } =
    useApisMembersInsightPostBookmarkFoldersUpdate();
  const { mutate: updateKnowledgeInsightBookmarkFolderRequest } =
    useApisMembersKnowledgeInsightPostBookmarkFoldersUpdate();

  const onDelete = () => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    if (folderType === "InsightPost") {
      deleteInsightBookmarkFolderRequest(
        {
          id: bookmarkFolderId,
        },
        {
          onSuccess: (data) => {
            onDeleteSuccess(data.message);
            navigate("/bookmark/insight_posts");
          },
        },
      );
    } else {
      deleteKnowledgeInsightBookmarkFolderRequest(
        {
          id: bookmarkFolderId,
        },
        {
          onSuccess: (data) => {
            onDeleteSuccess(data.message);
            navigate("/bookmark/knowledge_insight_posts");
          },
        },
      );
    }
  };

  const onDeleteSuccess = (successMessage: string) => {
    toast(successMessage);
    refetch();
    menuModalOpen.setFalse();
  };

  const onUpdateSuccess = (successMessage: string) => {
    toast(successMessage);
    refetch();
    editFormOpen.setFalse();
  };

  const handleUpdateSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (folderType === "InsightPost") {
      updateInsightBookmarkFolderRequest(
        {
          id: bookmarkFolderId,
          body: { name: value },
        },
        {
          onSuccess: (data) => onUpdateSuccess(data.message),
        },
      );
    } else {
      updateKnowledgeInsightBookmarkFolderRequest(
        {
          id: bookmarkFolderId,
          body: { name: value },
        },
        {
          onSuccess: (data) => onUpdateSuccess(data.message),
        },
      );
    }
  };

  const menuItems: PostDetailMenuItemType[] = [
    {
      icon: "ioCreateOutline",
      text: "編集する",
      onClick: () => {
        menuModalOpen.setFalse();
        editFormOpen.setTrue();
      },
      disabled: false,
    },
    {
      icon: "ioTrashOutline",
      text: "削除する",
      onClick: onDelete,
      disabled: false,
    },
  ];

  return editFormOpen.isChecked ? (
    <BookmarkFolderForm
      value={value}
      onFormClose={editFormOpen.setFalse}
      onChange={onChange}
      onSubmit={handleUpdateSubmit}
      className="mx-4"
    />
  ) : (
    <>
      <HoverTooltip
        text={bookmarkFolderName}
        className={`flex items-center justify-between w-full px-4 py-1.5 ${
          href === breadCrumbHref
            ? "bg-primary-600 text-white"
            : "hover:bg-primary-300"
        }`}
        textClassName="left-[6%]"
      >
        <Link to={href} className={"overflow-hidden w-full "}>
          <IconWithText
            icon={{
              icon: "ioFolderOpenOutline",
              size: "1rem",
              color:
                href === breadCrumbHref ? "text-white" : "text-secondary-600",
            }}
            text={bookmarkFolderName}
          />
        </Link>
        <FloatingMenu
          menuItems={menuItems}
          iconSize="1rem"
          iconColor={
            href === breadCrumbHref ? "text-white" : "text-secondary-600"
          }
          modal={{
            toggle: menuModalOpen.toggle,
            close: menuModalOpen.setFalse,
            opened: menuModalOpen.isChecked,
          }}
        />
      </HoverTooltip>
    </>
  );
};
