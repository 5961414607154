import React, { FC } from "react";

import { MenuType } from "~/hooks";

import {
  HomeSidebar,
  BookmarkSidebar,
  LikeNotificationSidebar,
  MentionNotificationSidebar,
  ReplyNotificationSidebar,
  EmployeesSidebar,
} from "~/components/organisms";

import {
  BreadCrumbItemType,
  ApiMembersUnreadNotificationCountsType,
} from "~/domains";

type propsType = {
  menu: MenuType;
  breadCrumbItems: BreadCrumbItemType[];
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
};

export const SidebarCurrentMenu: FC<propsType> = ({
  menu,
  breadCrumbItems,
  unreadNotification,
}: propsType) => {
  const SidebarMenu = {
    Home: (
      <HomeSidebar
        breadCrumbText={breadCrumbItems[0]?.label || "リフレクション"}
        unreadNotification={unreadNotification}
      />
    ),
    Notifications: <MentionNotificationSidebar />,
    Heart: <LikeNotificationSidebar />,
    ChatbubbleEllipses: <ReplyNotificationSidebar />,
    Mail: (
      <EmployeesSidebar breadCrumbHref={breadCrumbItems.at(-1)?.href || ""} />
    ),
    Bookmark: (
      <BookmarkSidebar breadCrumbHref={breadCrumbItems.at(-1)?.href || ""} />
    ),
    default: (
      <HomeSidebar
        breadCrumbText={breadCrumbItems[0]?.label || "リフレクション"}
        unreadNotification={unreadNotification}
      />
    ),
  };

  return SidebarMenu[menu] || SidebarMenu.default;
};
