import React, { FC, useState, FormEvent, ChangeEvent } from "react";

import { toast } from "react-toastify";

import { formatDateTime } from "~/libs";

import {
  useBoolean,
  useEditorState,
  useRightSidebarState,
  useApisMembersInsightPostsCreate,
  useApisMembersInsightPostsNew,
  useApisMembersInsightPostsInsightPostCommentsIndex,
  useSearchMembersInsightPosts,
  useInterval,
  useEditorStateLinkContentGetter,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  DatePicker,
  RightSidebarToggleIconWithLabel,
  InfiniteScroll,
  FilterIconWithSortMenu,
  TemplateSelectButtonWithModal,
} from "~/components/molecules";
import {
  InsightPostDetailWithEditForm,
  InsightPostForm,
  InsightPostCommentsWithInsightPost,
  InsightPostSearchForm,
  InsightPostsCountCalendar,
  InsightPostsPerformance,
} from "~/components/organisms";

import {
  DropDownOnChangeType,
  InsightFocusKaizenContentTemplateType,
  OptionType,
  PreviewWithFileType,
} from "~/domains";

export const MembersInsightPostsIndex: FC = () => {
  const {
    insightPosts,
    hasNextPage,
    isLoading,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    selectableDropdownSharedIds,
    selectedDropdownSharedId,
    startDate,
    endDate,
    sort,
    selectableJobCategories,
    selectedJobCategories,
    optionSelectableJobCategoryPhases,
    optionSelectedJobCategoryPhases,
    selectedProvidingServices,
    selectableProvidingServices,
    keyword,
    addInsightPost,
    deleteIndexInsightPost,
    updateIndexInsightPost,
    handleDateRangeWithSearch,
    onChangeProvidingServices,
    onChangeJobCategoryPhases,
    onChangeJobCategories,
    onChangeDateRange,
    setSort,
    fetchNextPage,
    onChangeKeyword,
    onSearchSubmit,
    onChangeDropdownHasBookmarkId,
    onChangeDropdownHasCommentId,
    onChangeDropdownSharedId,
    onConditionReset,
  } = useSearchMembersInsightPosts();
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [postDate, setPostDate] = useState<Date>(new Date());
  const [providingService, setProvidingService] = useState<OptionType>();
  const [jobCategoryPhase, setJobCategoryPhase] = useState<OptionType>({
    label: "すべて",
    value: "",
  });

  const {
    isChecked: shareable,
    setTrue: setShareableTrue,
    onChange: onChangeShareable,
  } = useBoolean(true);
  const [editorState, setEditorState] = useEditorState();
  const { fetchedData } = useEditorStateLinkContentGetter({
    watchEditorState: editorState.value,
    isWatch: true,
  });

  const {
    isSearchOpen,
    isReplyOpen,
    setReplyOpen,
    searchCalendarOpenToggle,
    setCalendarOpen,
    isOpen,
    isCalendarOpen,
    setClose,
    currentOpenLabel,
  } = useRightSidebarState("isCalendarOpen");
  const { mutate: postInsightPosts, isLoading: isSubmitting } =
    useApisMembersInsightPostsCreate();
  const { data: fetchNewData } = useApisMembersInsightPostsNew();
  const [selectedTemplate, setSelectedTemplate] =
    useState<
      Pick<InsightFocusKaizenContentTemplateType, "id" | "title" | "content">
    >();
  const [selectGetCommentInsightPostId, setSelectGetCommentInsightPostId] =
    useState<string>("");

  const { data: insightPostCommentsIndexData, refetch: commentsRefetch } =
    useApisMembersInsightPostsInsightPostCommentsIndex({
      insightPostId: selectGetCommentInsightPostId,
      config: {
        enabled: Boolean(selectGetCommentInsightPostId),
      },
    });

  const handleSearchDateOnChange = (date: Date) => {
    handleDateRangeWithSearch(date, date);
    setPostDate(date);
  };

  useInterval({
    onUpdate: async () => {
      isReplyOpen && selectGetCommentInsightPostId && (await commentsRefetch());
    },
    intervalMs: 1000,
  });

  const handleChangeJobCategoryPhase = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setJobCategoryPhase({ label: e.target.name, value: e.target.value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      content: editorState.jsonContent,
      contentText: editorState.plainText,
      postDate: formatDateTime(postDate, "yyyy-MM-dd"),
      shareable: shareable,
      providingServiceId: providingService?.value,
      jobCategoryPhaseId: jobCategoryPhase?.value,
      attachFiles: files,
      quotePostId: fetchedData?.id,
    };

    postInsightPosts(
      {
        body,
      },
      {
        onSuccess: (data) => {
          toast(data?.message);
          reset();
          addInsightPost(data.insightPost);
        },
      },
    );
  };

  const reset = () => {
    editorState.setPlainText("");
    setFiles([]);
    setShareableTrue();
    setSelectedTemplate(undefined);
    setProvidingService(undefined);
    setJobCategoryPhase({ label: "すべて", value: "" });
  };

  const openComment = (insightPostId: string) => {
    setSelectGetCommentInsightPostId(insightPostId);
    setReplyOpen();
  };

  const breadCrumbItems = [{ label: "リフレクション", href: "/insight_posts" }];

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {!isSearchOpen && (
            <DatePicker
              selectDate={postDate}
              onChange={handleSearchDateOnChange}
              parentClassName="mt-6"
            />
          )}
          {!isSearchOpen && fetchNewData && (
            <>
              <TemplateSelectButtonWithModal
                templates={
                  fetchNewData?.insightFocusKaizenContentTemplates || []
                }
                setEditorState={setEditorState}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                className="mt-6"
              />
              <InsightPostForm
                isSubmitting={isSubmitting}
                mentions={fetchNewData.mentionableEmployees}
                files={files}
                onChangeFiles={setFiles}
                editorState={editorState.value}
                setEditorState={editorState.onChange}
                selectableProvidingServices={
                  fetchNewData.selectableProvidingServices
                }
                selectedProvidingService={providingService}
                setProvidingService={(
                  newValue: DropDownOnChangeType<OptionType>,
                ) => setProvidingService(newValue as OptionType)}
                selectableJobCategoryPhases={
                  fetchNewData.selectableJobCategoryPhases
                }
                selectedJobCategoryPhase={jobCategoryPhase}
                onChangeJobCategoryPhase={handleChangeJobCategoryPhase}
                shareable={shareable}
                setShareable={onChangeShareable}
                handleSubmit={handleSubmit}
                className="mt-6"
                defaultAutoFucus
                blockQuote={fetchedData}
              />
            </>
          )}
          <FilterIconWithSortMenu
            likeSort={() => setSort("likeCountDesc")}
            bookmarkSort={() => setSort("bookmarkCountDesc")}
            createdAtAscSort={() => setSort("createdAtAsc")}
            createdAtDescSort={() => setSort("createdAtDesc")}
            currentSortType={sort}
            className={isSearchOpen ? "mt-6" : "mt-12"}
          />
          <InfiniteScroll
            itemsLength={insightPosts.length}
            nextFetchFunction={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isLoading}
            className="border-t-2 border-secondary-200"
          >
            {insightPosts.map((post, index) => (
              <InsightPostDetailWithEditForm
                key={post.id}
                insightPost={post}
                mentions={fetchNewData?.mentionableEmployees || []}
                selectableProvidingServices={
                  fetchNewData?.selectableProvidingServices || []
                }
                selectableJobCategoryPhases={
                  fetchNewData?.selectableJobCategoryPhases || []
                }
                openComment={openComment}
                deleteItem={() => {
                  deleteIndexInsightPost(index);
                  if (selectGetCommentInsightPostId === post.id) {
                    setSelectGetCommentInsightPostId("");
                    setClose();
                  }
                }}
                updateItem={(newItem) => updateIndexInsightPost(newItem, index)}
              />
            ))}
          </InfiniteScroll>
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          <>
            <RightSidebarToggleIconWithLabel
              iconOnClick={searchCalendarOpenToggle}
              handleOnClose={isCalendarOpen ? undefined : setCalendarOpen}
              label={currentOpenLabel}
              isOpen={isOpen}
            >
              {isCalendarOpen && (
                <div className="mt-6 space-y-6">
                  <InsightPostsCountCalendar />
                  <InsightPostsPerformance />
                </div>
              )}
              {isSearchOpen && (
                <InsightPostSearchForm
                  onSubmit={onSearchSubmit}
                  onConditionReset={onConditionReset}
                  date={{
                    startDate: startDate,
                    endDate: endDate,
                    onChange: onChangeDateRange,
                  }}
                  providingService={{
                    options: selectableProvidingServices,
                    value: selectedProvidingServices,
                    onChange: onChangeProvidingServices,
                  }}
                  jobCategoryAndJobCategoryPhase={{
                    selectableJobCategories: selectableJobCategories,
                    selectedJobCategories: selectedJobCategories,
                    selectableJobCategoryPhases:
                      optionSelectableJobCategoryPhases,
                    selectedJobCategoryPhases: optionSelectedJobCategoryPhases,
                    onJobCategoryChange: onChangeJobCategories,
                    onJobCategoryPhaseChange: onChangeJobCategoryPhases,
                  }}
                  hasComment={{
                    options: selectableDropdownHasCommentIds,
                    value: selectedDropdownHasCommentId,
                    onChange: onChangeDropdownHasCommentId,
                  }}
                  hasBookmark={{
                    options: selectableDropdownHasBookmarkIds,
                    value: selectedDropdownHasBookmarkId,
                    onChange: onChangeDropdownHasBookmarkId,
                  }}
                  shared={{
                    options: selectableDropdownSharedIds,
                    value: selectedDropdownSharedId,
                    onChange: onChangeDropdownSharedId,
                  }}
                  searchKeyword={{
                    value: keyword,
                    onChange: onChangeKeyword,
                  }}
                />
              )}
              {isReplyOpen && insightPostCommentsIndexData && (
                <InsightPostCommentsWithInsightPost
                  className="mt-6"
                  insightPost={insightPostCommentsIndexData.insightPost}
                  mentions={fetchNewData?.mentionableEmployees || []}
                  insightPostComments={
                    insightPostCommentsIndexData.insightPostComments || []
                  }
                  refetch={commentsRefetch}
                />
              )}
            </RightSidebarToggleIconWithLabel>
          </>
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
