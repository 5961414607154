import React, { useEffect, FC } from "react";

import { calculateMaxPoint } from "~/utils";

import {
  PickSkillEvaluationStandardType,
  useBoolean,
  useEditorState,
} from "~/hooks";

import { Editor } from "~/components/molecules";

import { ArchivedSkillEvaluationStandardDetail } from "./ArchivedSkillEvaluationStandardDetail";
import { SkillEvaluationStandardSummaryDetailTitle } from "../SkillEvaluationStandardSummaryCategory/SkillEvaluationStandardSummaryDetailTitle";

import { ArchivedSkillEvaluationStandardSummaryWithStandardsType } from "~/domains";

type PropsType = {
  archivedSkillEvaluationStandardSummary: ArchivedSkillEvaluationStandardSummaryWithStandardsType;
  selectSkillEvaluationStandard?: (
    newSkillEvaluationStand: PickSkillEvaluationStandardType,
  ) => void;
  memberSelectedSkillEvaluationStandardIds: string[];
  managerSelectedSkillEvaluationStandardIds: string[];
  isOpen: boolean;
  index: number;
};

export const ArchivedSkillEvaluationStandardSummaryDetail: FC<PropsType> = ({
  archivedSkillEvaluationStandardSummary,
  selectSkillEvaluationStandard,
  memberSelectedSkillEvaluationStandardIds,
  managerSelectedSkillEvaluationStandardIds,
  isOpen,
  index,
}: PropsType) => {
  const { title, content, archivedSkillEvaluationStandards } =
    archivedSkillEvaluationStandardSummary;
  const [{ value: contentValue, onChange: onChangeContent }] =
    useEditorState(content);
  const { isChecked, toggle, handleChange } = useBoolean(false);
  const maxPoint = calculateMaxPoint(
    archivedSkillEvaluationStandards.map((v) => v.point),
  );

  useEffect(() => {
    handleChange(isOpen);
  }, [handleChange, isOpen]);

  return (
    <div>
      <SkillEvaluationStandardSummaryDetailTitle
        title={title}
        index={index}
        maxPoint={maxPoint}
        isChecked={isChecked}
        toggle={toggle}
      />
      {isChecked && (
        <>
          <div className="mt-4">
            <Editor
              editorState={contentValue}
              readOnly
              onChange={onChangeContent}
              canFileInText={false}
            />
          </div>
          <div className="mt-4 flex overflow-x-scroll w-full space-x-4 items-start pb-4">
            {archivedSkillEvaluationStandards.map((standard) => (
              <ArchivedSkillEvaluationStandardDetail
                key={standard.id}
                skillEvaluationStandard={standard}
                selectSkillEvaluationStandard={selectSkillEvaluationStandard}
                memberSelected={memberSelectedSkillEvaluationStandardIds.includes(
                  standard.id,
                )}
                managerSelected={managerSelectedSkillEvaluationStandardIds.includes(
                  standard.id,
                )}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
