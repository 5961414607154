import React, { FC } from "react";

import {
  useApisMembersInsightPostBookmarkFoldersCreate,
  useApisMembersKnowledgeInsightPostBookmarkFoldersCreate,
  useInput,
} from "~/hooks";

import { Link } from "~/components/atoms";
import { IconWithText } from "~/components/molecules";
import {
  Fetch,
  BookmarkFolderForm,
  BookmarkFolderNameWithEditForm,
} from "~/components/organisms";

import {
  InsightPostBookmarkFolderType,
  KnowledgeInsightPostBookmarkFolderType,
} from "~/domains";

type PropsType = {
  breadCrumbHref: string;
  className?: string;
  loading: boolean;
  error: string | null;
  bookmarkFolders:
    | InsightPostBookmarkFolderType[]
    | KnowledgeInsightPostBookmarkFolderType[];
  allBookmarks: {
    url: string;
    text: string;
  };
  refetch: () => void;
  folderType: "InsightPost" | "KnowledgeInsightPost";
};

export const BookmarkFoldersAccordion: FC<PropsType> = ({
  breadCrumbHref,
  loading,
  error,
  refetch,
  bookmarkFolders,
  allBookmarks,
  folderType,
  className = "",
}: PropsType) => {
  const { mutate: insightPostBookmarkFoldersCreate } =
    useApisMembersInsightPostBookmarkFoldersCreate();
  const { mutate: knowledgeInsightPostBookmarkFoldersCreate } =
    useApisMembersKnowledgeInsightPostBookmarkFoldersCreate();
  const [{ value, onChange }, setValue] = useInput("");

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = { name: value };
    if (folderType === "InsightPost") {
      insightPostBookmarkFoldersCreate(
        { body },
        {
          onSuccess,
        },
      );
    } else {
      knowledgeInsightPostBookmarkFoldersCreate(
        { body },
        {
          onSuccess,
        },
      );
    }
  };

  const onSuccess = () => {
    refetch();
    setValue("");
  };

  return (
    <div className={className}>
      <div className="">
        <Link
          to={allBookmarks.url}
          className={`px-4 py-1.5 flex items-center w-full justify-between ${
            breadCrumbHref === allBookmarks.url
              ? "bg-primary-600 text-white"
              : "hover:bg-primary-300"
          }`}
        >
          <IconWithText
            icon={{
              icon: "ioFolderOpenOutline",
              size: "1rem",
              color:
                breadCrumbHref === allBookmarks.url
                  ? "text-white"
                  : "text-secondary-600",
            }}
            text={allBookmarks.text}
          />
        </Link>
        <Fetch
          loading={loading}
          error={error}
          noContentMessage=""
          data={bookmarkFolders}
        >
          <>
            {bookmarkFolders.map((bookmarkFolder) => (
              <BookmarkFolderNameWithEditForm
                key={bookmarkFolder.id}
                bookmarkFolderId={bookmarkFolder.id}
                bookmarkFolderName={bookmarkFolder.name}
                refetch={refetch}
                folderType={folderType}
                breadCrumbHref={breadCrumbHref}
              />
            ))}
          </>
        </Fetch>
      </div>
      <BookmarkFolderForm
        onSubmit={onSubmit}
        value={value}
        onChange={onChange}
        className="mx-4 mt-4"
      />
    </div>
  );
};
