import {
  useQuery,
  ExtractFnReturnType,
  QueryConfigType,
  UseQueryResult,
} from "~/libs";

import { ApiClient } from "~/utils";

import { ApiMembersEmployeeSurveySubmissionsSearchItemsIndexResponseType } from "~/domains";

const request =
  async (): Promise<ApiMembersEmployeeSurveySubmissionsSearchItemsIndexResponseType> => {
    const response =
      await new ApiClient().get<ApiMembersEmployeeSurveySubmissionsSearchItemsIndexResponseType>(
        "/api/members/employee_survey_submissions_search_items",
      );
    return response.data;
  };

type QueryFnType = typeof request;

type PropsType = {
  config?: QueryConfigType<QueryFnType>;
};

export const useApisMembersEmployeeSurveySubmissionsSearchItemsSearchItemsIndex =
  ({
    config = {},
  }: PropsType = {}): UseQueryResult<ApiMembersEmployeeSurveySubmissionsSearchItemsIndexResponseType> => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [
        "ApisMembersEmployeeSurveySubmissionsSearchItemsSearchItemsIndex",
      ],
      queryFn: () => request(),
    });
  };
