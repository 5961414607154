import React, { FC, MouseEvent } from "react";

import { ButtonWithIcon } from "~/components/atoms";

type PropsType = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const FloatingChatIcon: FC<PropsType> = ({ onClick }: PropsType) => {
  return (
    <ButtonWithIcon
      onClick={onClick}
      icon={{
        icon: "ioChatboxOutline",
        size: "2rem",
        color: "text-white",
      }}
      className="!fixed z-modal bottom-24 miniTablet:bottom-5 left-0 mx-4 miniTablet:ml-0 miniTablet:left-4 bg-primary-600 rounded-full p-2.5"
      srOnlyText="Chatを開く"
    />
  );
};
