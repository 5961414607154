import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersInsightFocusKaizenContentTemplatesDestroy,
  useSearchManagersInsightFocusKaizenContentTemplates,
} from "~/hooks";

import {
  Form,
  Button,
  FormSubmitButton,
  GridHeaderCells,
  BooleanDropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  LabelWithDropDownField,
  LabelWithTextField,
  GridRow,
  InfiniteScrollWithMoreButton,
  OpenedMenuInGrid,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { InsightFocusKaizenContentTemplateType } from "~/domains";

export const ManagersInsightFocusKaizenContentTemplatesIndex: FC = () => {
  const { mutate: deleteRequest } =
    useApisManagersInsightFocusKaizenContentTemplatesDestroy();
  const breadCrumbItems = [
    {
      label: "各種テンプレ",
      href: "/managers/insight_focus_kaizen_content_templates",
    },
    { label: "一覧", href: "/managers/insight_focus_kaizen_content_templates" },
  ];

  const {
    insightFocusKaizenContentTemplates,
    titleKeyword,
    isLoading,
    hasNextPage,
    allBusinessSectionsDistribution,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownInsightFocusKaizenContentTypeIds,
    selectedDropdownInsightFocusKaizenContentTypeId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    onChangeDropdownInsightFocusKaizenContentTypeId,
    fetchNextPage,
    onChangeTitleKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  } = useSearchManagersInsightFocusKaizenContentTemplates();
  const handleDelete = (
    insightFocusKaizenContentTemplate: InsightFocusKaizenContentTemplateType,
  ) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: insightFocusKaizenContentTemplate.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove(
            (item) => item.id === insightFocusKaizenContentTemplate.id,
          );
        },
      },
    );
  };

  const generateInsightFocusKaizenContentTemplateLists = (
    insightFocusKaizenContentTemplate: InsightFocusKaizenContentTemplateType,
  ) => {
    return [
      insightFocusKaizenContentTemplate.title,
      insightFocusKaizenContentTemplate.allBusinessSectionsDistribution
        ? "すべての部署"
        : insightFocusKaizenContentTemplate.businessDivisions
            .map((division) => division.name)
            .join(","),
      insightFocusKaizenContentTemplate.allBusinessSectionsDistribution
        ? "すべての課"
        : insightFocusKaizenContentTemplate.businessSections
            .map((section) => section.name)
            .join(","),
      insightFocusKaizenContentTemplate.insightFocusKaizenContentType.name,
      insightFocusKaizenContentTemplate.publicStatus.name,
    ];
  };
  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <Form onSubmit={onSearchSubmit} className="space-y-6">
          <div className="grid miniTablet:grid-cols-2 gap-4">
            <MultiParentsWithMultiChildrenDropdownField
              allParents={selectableDropdownDivisions}
              parentsValue={selectedDropdownDivisions}
              parentLabel="部署"
              parentsOnChange={onDivisionChange}
              allChildren={selectableDropdownSections}
              childrenValue={selectedDropdownSections}
              childLabel="課"
              childrenOnChange={onSectionChange}
            />
            <BooleanDropDownField
              name="isAllBusinessSections"
              trueLabel="すべての課に配布する"
              falseLabel="個別の課に配布する"
              labelText="配布種別の選択"
              value={allBusinessSectionsDistribution}
              onChange={setAllBusinessSectionsDistribution}
            />
            <LabelWithDropDownField
              labelText="種類"
              name="InsightFocusKaizenContentType"
              options={selectableDropdownInsightFocusKaizenContentTypeIds}
              value={selectedDropdownInsightFocusKaizenContentTypeId}
              onChange={onChangeDropdownInsightFocusKaizenContentTypeId}
            />
            <LabelWithDropDownField
              labelText="ステータス"
              name="publicStatus"
              options={selectableDropdownPublicStatusIds}
              value={selectedDropdownPublicStatusId}
              onChange={onChangeDropdownPublicStatusId}
            />
            <LabelWithTextField
              labelText="フリーワード"
              type="search"
              name="keyword"
              placeholder="フリーワード検索"
              value={titleKeyword}
              onChange={onChangeTitleKeyword}
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <Button
              color="gray"
              outline
              className="w-full miniTablet:w-auto"
              text="リセット"
              onClick={onConditionReset}
            />
            <FormSubmitButton
              value="検索する"
              color="primary"
              className="w-full miniTablet:w-auto"
            />
          </div>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={insightFocusKaizenContentTemplates.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-9"
        >
          <div className="grid grid-cols-5">
            <GridHeaderCells
              texts={["タイトル", "部署", "課", "種類", "ステータス"]}
            />
            {insightFocusKaizenContentTemplates.map(
              (insightFocusKaizenContentTemplate) => (
                <GridRow
                  key={insightFocusKaizenContentTemplate.id}
                  lists={generateInsightFocusKaizenContentTemplateLists(
                    insightFocusKaizenContentTemplate,
                  )}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={5}
                      buttons={[
                        {
                          text: "編集",
                          link: `/managers/insight_focus_kaizen_content_templates/${insightFocusKaizenContentTemplate.id}/edit`,
                        },
                        {
                          text: "削除",
                          onClick: () =>
                            handleDelete(insightFocusKaizenContentTemplate),
                        },
                      ]}
                    />
                  }
                />
              ),
            )}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
