import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  Button,
  DropDownField,
  Form,
  FormSubmitButton,
  MultipleDropDownField,
  TextField,
} from "~/components/atoms";
import { TitleWithChild } from "~/components/molecules";

import { MultiValueType, OptionType, SingleValueType } from "~/domains";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  hasBookmark: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  documentFileCategory: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  providingService: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  tag: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  fileExtend: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
};

export const DocumentFileCategorySearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  searchKeyword,
  documentFileCategory,
  hasBookmark,
  tag,
  providingService,
  fileExtend,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="フリーワード">
          <TextField
            value={searchKeyword.value}
            placeholder="キーワード"
            type="search"
            name="keyword"
            onChange={searchKeyword.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="タイトル">
          <MultipleDropDownField
            name="documentFileCategory"
            options={documentFileCategory.option}
            value={documentFileCategory.value}
            onChange={documentFileCategory.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="ジャンル">
          <MultipleDropDownField
            name="documentFileProvidingService"
            options={providingService.option}
            value={providingService.value}
            onChange={providingService.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="タグ">
          <MultipleDropDownField
            name="documentFileTag"
            options={tag.option}
            value={tag.value}
            onChange={tag.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="ファイルの種類">
          <>
            <MultipleDropDownField
              name="fileExtendType"
              options={fileExtend.option}
              value={fileExtend.value}
              onChange={fileExtend.onChange}
            />
          </>
        </TitleWithChild>
        <TitleWithChild title="ブックマーク">
          <DropDownField
            name="hasBookmark"
            options={hasBookmark.options}
            value={hasBookmark.value}
            onChange={hasBookmark.onChange}
          />
        </TitleWithChild>
        <div className="space-x-4 flex">
          <Button
            color="gray"
            outline
            onClick={onConditionReset}
            className="w-full"
            text="リセット"
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
