import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp, getCalendarDates, toDateString } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import { ActivityOriginalCalendar } from "~/components/molecules";
import { DivisionAndSectionAndEmployeeDropdownField } from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApisAnalyticsPlansEmployeesPostsCalendarsIndexResponseTypes,
  ChildType,
} from "~/domains";

type PropsType = {
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  data?: ApisAnalyticsPlansEmployeesPostsCalendarsIndexResponseTypes;
  className?: string;
  isLoading: boolean;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
  selectedEmployee?: ParentType;
  onEmployeeChange: (newValue: ParentType | undefined) => void;
  selectableEmployees: ParentType[];
  targetDate: Date;
  handlePrevMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
  handleNextMonthClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const AnalyticsPostsCalendarsTemplate: FC<PropsType> = ({
  selectedDivisions,
  optionSelectedSections,
  isLoading,
  className = "",
  onDivisionChange,
  onSectionChange,
  selectableDivisions,
  optionSelectableSections,
  data,
  selectedEmployee,
  onEmployeeChange,
  selectableEmployees,
  targetDate,
  handlePrevMonthClick,
  handleNextMonthClick,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-posts_calendars_analysis.csv`,
    );
  };

  const generateCsvData = () => {
    const { calendar } = getCalendarDates(targetDate);
    const suffix = selectedEmployee ? "" : "%";
    return [
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections?.length
          ? optionSelectedSections.map((section) => section.name).join(", ")
          : selectedDivisions?.length
            ? "選択された部署に属するすべての課"
            : "すべての課",
      ],
      ["ユーザー", selectedEmployee?.name || "すべてのユーザー"],
      ["日付", "リフレクション", "チャレンジ", "改善・相談"],
      ...calendar.flat().map((date) => {
        const dateString = toDateString(date);
        const insightCount =
          data?.insightPostsCalendar.find((cal) => cal.date === dateString)
            ?.postsCount || 0;
        const focusCount =
          data?.focusChallengeEmployeeEffortCompletesCalendar.find(
            (cal) => cal.date === dateString,
          )?.postsCount || 0;
        const kaizenCount =
          data?.kaizenIdeasCalendar.find((cal) => cal.date === dateString)
            ?.postsCount || 0;
        return [
          dateString,
          `${insightCount}${suffix}`,
          `${focusCount}${suffix}`,
          `${kaizenCount}${suffix}`,
        ];
      }),
    ];
  };

  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <DivisionAndSectionAndEmployeeDropdownField
          selectableDropdownDivisions={selectableDivisions}
          selectedDropdownDivisions={selectedDivisions}
          onDivisionChange={onDivisionChange}
          selectableDropdownSections={optionSelectableSections}
          selectedDropdownSections={optionSelectedSections}
          onSectionChange={onSectionChange}
          selectableDropdownEmployees={selectableEmployees}
          selectedDropdownEmployee={selectedEmployee}
          onEmployeeChange={onEmployeeChange}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
        <ActivityOriginalCalendar
          title="リフレクション"
          isLoading={isLoading}
          dailyActivity={data?.insightPostsCalendar}
          targetDate={targetDate}
          onPrevMonthClick={handlePrevMonthClick}
          onNextMonthClick={handleNextMonthClick}
          isForRate={!selectedEmployee}
        />
        <ActivityOriginalCalendar
          title="チャレンジ"
          isLoading={isLoading}
          dailyActivity={data?.focusChallengeEmployeeEffortCompletesCalendar}
          targetDate={targetDate}
          onPrevMonthClick={handlePrevMonthClick}
          onNextMonthClick={handleNextMonthClick}
          isForRate={!selectedEmployee}
        />
        <ActivityOriginalCalendar
          title="改善・相談"
          isLoading={isLoading}
          dailyActivity={data?.kaizenIdeasCalendar}
          targetDate={targetDate}
          onPrevMonthClick={handlePrevMonthClick}
          onNextMonthClick={handleNextMonthClick}
          isForRate={!selectedEmployee}
        />
      </div>
    </div>
  );
};
