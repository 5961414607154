import React, { FC } from "react";

import { toast } from "react-toastify";

import { rawDraftContentStateToDraftJsContent } from "~/utils";

import {
  useApisManagersFocusChallengesDestroy,
  useSearchManagersFocusChallenges,
} from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Button,
  GridHeaderCells,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  InfiniteScrollWithMoreButton,
  RangeDatePicker,
  LabelWithDropDownField,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { FocusChallengeType } from "~/domains";

export const ManagersFocusChallengesIndex: FC = () => {
  const { mutate: deleteRequest } = useApisManagersFocusChallengesDestroy();
  const {
    focusChallenges,
    keyword,
    isLoading,
    hasNextPage,
    startDate,
    endDate,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeDateRange,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
  } = useSearchManagersFocusChallenges();

  const deletePost = (post: FocusChallengeType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: post.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === post.id);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "チャレンジ", href: "/managers/focus_challenges" },
    { label: "一覧", href: "/managers/focus_challenges" },
  ];

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <Form onSubmit={onSearchSubmit} className="space-y-6">
            <div className="grid miniTablet:grid-cols-2 gap-4">
              <MultiParentsWithMultiChildrenDropdownField
                allParents={selectableDropdownDivisions}
                parentsValue={selectedDropdownDivisions}
                parentLabel="部署"
                parentsOnChange={onDivisionChange}
                allChildren={selectableDropdownSections}
                childrenValue={selectedDropdownSections}
                childLabel="課"
                childrenOnChange={onSectionChange}
              />
              <RangeDatePicker
                labelText="チャレンジ期間指定"
                startDate={startDate}
                endDate={endDate}
                onChange={onChangeDateRange}
              />
              <LabelWithDropDownField
                labelText="ステータス"
                name="publicStatus"
                options={selectableDropdownPublicStatusIds}
                value={selectedDropdownPublicStatusId}
                onChange={onChangeDropdownPublicStatusId}
              />
              <LabelWithTextField
                labelText="フリーワード"
                type="search"
                name="keyword"
                placeholder="フリーワード検索"
                value={keyword}
                onChange={onChangeKeyword}
              />
            </div>
            <div className="flex items-center justify-end space-x-4">
              <Button
                color="gray"
                outline
                className="w-full miniTablet:w-auto"
                text="リセット"
                onClick={onConditionReset}
              />
              <FormSubmitButton
                value="検索する"
                color="primary"
                className="w-full miniTablet:w-auto"
              />
            </div>
          </Form>
          <InfiniteScrollWithMoreButton
            itemsLength={focusChallenges.length}
            nextFetchFunction={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isLoading}
            className="mt-9"
          >
            <div className="grid grid-cols-4">
              <GridHeaderCells
                texts={["日付", "内容", "ユーザー", "ステータス"]}
              />
              {focusChallenges.map((post) => (
                <GridRow
                  key={post.id}
                  lists={[
                    `${post.challengeStartDate}~${post.challengeFinishDate}`,
                    rawDraftContentStateToDraftJsContent(post.content)
                      .getCurrentContent()
                      .getPlainText(),
                    post.employees.map((employee) => employee.name).join(", "),
                    post.publicStatus.name,
                  ]}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={4}
                      buttons={[
                        {
                          text: "編集",
                          link: `/managers/focus_challenges/${post.id}/edit`,
                        },
                        {
                          text: "削除",
                          onClick: () => deletePost(post),
                        },
                      ]}
                    />
                  }
                />
              ))}
            </div>
          </InfiniteScrollWithMoreButton>
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
