import { captureException, withScope } from "@sentry/react";
import axios, { AxiosError, AxiosInstance } from "axios";
import camelcaseKeys from "camelcase-keys";
import Cookies from "js-cookie";
import snakecaseKeys from "snakecase-keys";

import { ResponseHeadersType, ErrorResponse } from "~/domains";

export type AxiosResponseType<T> = {
  data: T;
  headers?: ResponseHeadersType;
  error?: AxiosError;
};

const apiBaseRequestUrl = (externalUrl?: string): string => {
  if (externalUrl) return externalUrl;

  const firstSubdomain =
    location.hostname === process.env.FRONTEND_ROOT_DOMAIN
      ? ""
      : `${location.hostname.split(".")[0]}.`;
  const baseUrl = `${process.env.API_PROTOCOL}://${firstSubdomain}${process.env.API_BASE_URL}`;
  return process.env.NODE_ENV === "development" ? `${baseUrl}:3000` : baseUrl;
};

export class ApiClient {
  axiosInstance: AxiosInstance;
  constructor(externalUrl?: string) {
    const baseUrl = apiBaseRequestUrl(externalUrl);
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        acceptType: "application/json",
        "X-Content-Type-Options": "nosniff",
        ...this.getCookies(),
      },
    });
    this.axiosInstance.interceptors.response.use((response) => {
      const responseData = response.data as Record<string, unknown>;
      const data = camelcaseKeys(responseData, { deep: true });
      return { ...response, data };
    });
  }

  /**
   * @description GETリクエストを送信する
   * @param path リクエスト先のパス string
   * @param params リクエストパラメータ Record<string, unknown>
   * @returns レスポンスデータ Promise<AxiosResponseType<T>>
   */
  async get<T>(
    path: string,
    params?: Record<string, unknown>,
  ): Promise<AxiosResponseType<T>> {
    try {
      const formattedParams = params
        ? snakecaseKeys(params, { deep: true })
        : params;
      const response = await this.axiosInstance.get<T>(path, {
        params: formattedParams,
      });
      return this.createSuccessPromiseWithHeaders<T>(
        response.data,
        response.headers as ResponseHeadersType,
      );
    } catch (err) {
      return this.createFailurePromise<T>(err as ErrorResponse);
    }
  }

  /**
   * @description POSTリクエストを送信する
   * @param path リクエスト先のパス string
   * @param params リクエストパラメータ Record<string, unknown> | FormData
   * @returns レスポンスデータ Promise<AxiosResponseType<T>>
   */
  async post<T>(
    path: string,
    params: Record<string, unknown> | FormData = {},
  ): Promise<AxiosResponseType<T>> {
    try {
      const formattedParams =
        params instanceof FormData
          ? params
          : snakecaseKeys(params, { deep: true });
      const response = await this.axiosInstance.post<T>(path, formattedParams);
      return this.createSuccessPromiseWithHeaders<T>(
        response.data,
        response.headers as ResponseHeadersType,
      );
    } catch (err) {
      return this.createFailurePromise<T>(err as ErrorResponse);
    }
  }

  /**
   * @description PUTリクエストを送信する
   * @param path リクエスト先のパス string
   * @param params リクエストパラメータ Record<string, unknown> | FormData
   * @returns レスポンスデータ Promise<AxiosResponseType<T>>
   */
  async put<T>(
    path: string,
    params: Record<string, unknown> | FormData = {},
  ): Promise<AxiosResponseType<T>> {
    try {
      const formattedParams =
        params instanceof FormData
          ? params
          : snakecaseKeys(params, { deep: true });
      const response = await this.axiosInstance.put<T>(path, formattedParams);
      return this.createSuccessPromiseWithHeaders<T>(
        response.data,
        response.headers as ResponseHeadersType,
      );
    } catch (err) {
      return this.createFailurePromise<T>(err as ErrorResponse);
    }
  }

  /**
   * @description DELETEリクエストを送信する
   * @param path リクエスト先のパス string
   * @param params リクエストパラメータ Record<string, unknown>
   * @returns レスポンスデータ Promise<AxiosResponseType<T>>
   */
  async delete<T>(
    path: string,
    params?: Record<string, unknown>,
  ): Promise<AxiosResponseType<T>> {
    try {
      const formattedParams = params
        ? snakecaseKeys(params, { deep: true })
        : params;
      const response = await this.axiosInstance.delete<T>(
        path,
        formattedParams as undefined,
      );
      return this.createSuccessPromiseWithHeaders<T>(
        response.data,
        response.headers as ResponseHeadersType,
      );
    } catch (err) {
      return this.createFailurePromise<T>(err as ErrorResponse);
    }
  }

  private getCookies = () => {
    return {
      uid: Cookies.get("uid"),
      "access-token": Cookies.get("access-token"),
      client: Cookies.get("client"),
    };
  };

  private createSuccessPromiseWithHeaders<T>(
    data: T,
    headers: ResponseHeadersType,
  ): Promise<
    Omit<AxiosResponseType<T>, "headers"> & { headers: ResponseHeadersType }
  > {
    return Promise.resolve<
      Omit<AxiosResponseType<T>, "headers"> & { headers: ResponseHeadersType }
    >({ data, headers });
  }

  private createFailurePromise<T>(
    error: ErrorResponse,
  ): Promise<AxiosResponseType<T>> {
    const errMsg = error.response.data.errors?.length
      ? error.response.data.errors[0] || "エラーが発生しました"
      : "エラーが発生しました";
    sentryErrorLogger(error, errMsg);
    throw new ApiResponseError(error.response.status, errMsg, error);
  }
}

const sentryErrorLogger = (error: ErrorResponse, errMsg: string) => {
  let contexts = {};
  const response = error.response;
  const endpoint = response?.config.url || "";
  const status = response?.status;
  const method = response?.config.method || "";

  contexts = { response };

  withScope((scope) => {
    scope.setFingerprint([
      "{{ default }}",
      errMsg,
      endpoint,
      String(status),
      method,
    ]);
    captureException(error, {
      contexts,
    });
  });
};

export class ApiResponseError extends Error {
  status: number;
  error: ErrorResponse;
  constructor(status: number, msg: string, error: ErrorResponse) {
    super(msg);
    this.status = status;
    this.error = error;
  }
}
