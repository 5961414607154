import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersQuestionAnswerCategoriesDestroy,
  useSearchManagersQuestionAnswerCategories,
} from "~/hooks";

import {
  FormSubmitButton,
  GridHeaderCells,
  Form,
  Button,
  BooleanDropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  LabelWithDropDownField,
  InfiniteScrollWithMoreButton,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { QuestionAnswerCategoryType } from "~/domains";

export const ManagersQuestionAnswerCategoriesIndex: FC = () => {
  const { mutate: deleteRequest } =
    useApisManagersQuestionAnswerCategoriesDestroy();
  const {
    questionAnswerCategories,
    nameKeyword,
    isLoading,
    hasNextPage,
    allBusinessSectionsDistribution,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeNameKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  } = useSearchManagersQuestionAnswerCategories();
  const handleDelete = (questionAnswerCategory: QuestionAnswerCategoryType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;

    deleteRequest(
      {
        id: questionAnswerCategory.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === questionAnswerCategory.id);
        },
      },
    );
  };

  const generateQuestionAnswerCategoryLists = (
    questionAnswerCategory: QuestionAnswerCategoryType,
  ) => {
    return [
      questionAnswerCategory.providingService?.name || "すべて",
      questionAnswerCategory.name,
      questionAnswerCategory.allBusinessSectionsDistribution
        ? "すべての部署"
        : questionAnswerCategory.businessDivisions
            .map((division) => division.name)
            .join(","),
      questionAnswerCategory.allBusinessSectionsDistribution
        ? "すべての課"
        : questionAnswerCategory.businessSections
            .map((section) => section.name)
            .join(","),
      questionAnswerCategory.publicStatus.name,
    ];
  };

  const breadCrumbItems = [
    { label: "Q&A", href: "/managers/question_answer_categories" },
    { label: "一覧", href: "/managers/question_answer_categories" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <Form onSubmit={onSearchSubmit} className="space-y-6 mt-8">
          <>
            <div className="grid miniTablet:grid-cols-2 gap-4">
              <MultiParentsWithMultiChildrenDropdownField
                allParents={selectableDropdownDivisions}
                parentsValue={selectedDropdownDivisions}
                parentLabel="部署"
                parentsOnChange={onDivisionChange}
                allChildren={selectableDropdownSections}
                childrenValue={selectedDropdownSections}
                childLabel="課"
                childrenOnChange={onSectionChange}
              />
              <BooleanDropDownField
                name="isAllBusinessSections"
                trueLabel="すべての課に配布する"
                falseLabel="個別の課に配布する"
                labelText="配布種別の選択"
                value={allBusinessSectionsDistribution}
                onChange={setAllBusinessSectionsDistribution}
              />
              <LabelWithDropDownField
                labelText="ステータス"
                name="publicStatus"
                options={selectableDropdownPublicStatusIds}
                value={selectedDropdownPublicStatusId}
                onChange={onChangeDropdownPublicStatusId}
              />
              <LabelWithTextField
                labelText="フリーワード"
                type="search"
                name="keyword"
                placeholder="フリーワード検索"
                value={nameKeyword}
                onChange={onChangeNameKeyword}
              />
            </div>
            <div className="flex items-center justify-end space-x-4">
              <Button
                color="gray"
                outline
                className="w-full miniTablet:w-auto"
                text="リセット"
                onClick={onConditionReset}
              />
              <FormSubmitButton
                value="検索する"
                color="primary"
                className="w-full miniTablet:w-auto"
              />
            </div>
          </>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={questionAnswerCategories.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-12"
        >
          <div className="grid grid-cols-5">
            <GridHeaderCells
              texts={["ジャンル", "タイトル", "部署", "課", "ステータス"]}
            />
            {questionAnswerCategories.map((questionAnswer) => (
              <GridRow
                key={questionAnswer.id}
                lists={generateQuestionAnswerCategoryLists(questionAnswer)}
                openedMenu={
                  <OpenedMenuInGrid
                    cols={5}
                    buttons={[
                      {
                        text: "編集",
                        link: `/managers/question_answer_categories/${questionAnswer.id}/edit`,
                      },
                      {
                        text: "削除",
                        onClick: () => handleDelete(questionAnswer),
                      },
                    ]}
                  />
                }
              />
            ))}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
