import React, { FormEvent, FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { toDateString } from "~/libs";

import {
  useApisManagersEmployeeSurveysCreate,
  useBoolean,
  useDropdown,
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import { Form, FormSubmitButton } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  DatePicker,
  LabelWithDropDownField,
  LabelWithTextArea,
  LabelWithTextField,
} from "~/components/molecules";
import { AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { AllAnswerShareOptions } from "~/domains";

export const ManagersEmployeeSurveysNew: FC = () => {
  const navigate = useNavigate();

  const { currentEmployee } = useProvidersCurrentEmployee();
  const { mutate: createRequest, isLoading: isSubmitting } =
    useApisManagersEmployeeSurveysCreate();
  const [{ value: title, onChange: onChangeTitle }] = useInput("");
  const [{ value: description, onChange: onChangeDescription }] = useInput("");
  const [dropdownAnswerShareOption] = useDropdown();
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(false);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const breadCrumbItems = [
    { label: "チェックアウト", href: "/managers/employee_surveys" },
    { label: "新規作成", href: "/managers/employee_surveys/new" },
  ];

  const handleDraftSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      title,
      description,
      answerShareOptionId: dropdownAnswerShareOption.dropdownValue?.value || "",
      startDate: startDate ? toDateString(startDate) : "",
      endDate: endDate ? toDateString(endDate) : "",
      allBusinessSectionsDistribution,
    };
    createRequest(
      { body },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate(`/managers/employee_surveys/${data.employeeSurvey.id}/edit`);
        },
      },
    );
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <Form className="space-y-6" onSubmit={handleDraftSubmit}>
          <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
            allParents={selectableDivisions}
            parentsValue={selectedDivisions}
            parentsOnChange={onDivisionChange}
            parentRequired={true}
            parentLabel="配布先: 部署"
            allChildren={optionSelectableSections}
            childrenValue={optionSelectedSections}
            childrenOnChange={onSectionChange}
            childRequired={true}
            childLabel="配布先: 課"
            allBusinessSectionsDistribution={allBusinessSectionsDistribution}
            onChangeAllBusinessSectionsDistribution={
              onChangeAllBusinessSectionsDistribution
            }
            currentEmployeeRole={currentEmployee?.employeeRole}
          />
          <LabelWithTextField
            labelText="タイトル"
            type="text"
            name="title"
            placeholder="タイトル"
            required
            value={title}
            onChange={onChangeTitle}
          />
          <LabelWithTextArea
            labelText="説明"
            name="description"
            placeholder="説明"
            required
            value={description}
            onChange={onChangeDescription}
          />
          <LabelWithDropDownField
            labelText="共有に追加"
            required
            name="dropdownAnswerShareOption"
            options={AllAnswerShareOptions.map((status) => ({
              value: status.id.toString(),
              label: status.name,
            }))}
            value={dropdownAnswerShareOption.dropdownValue}
            onChange={dropdownAnswerShareOption.onChange}
          />
          <DatePicker
            labelText="開始日"
            selectDate={startDate}
            onChange={setStartDate}
            parentClassName="mt-6"
            withoutButton
            required
            textLeft
          />
          <DatePicker
            labelText="終了日"
            selectDate={endDate}
            onChange={setEndDate}
            parentClassName="mt-6"
            withoutButton
            textLeft
          />
          <FormSubmitButton
            value="チェックアウトの作成"
            color="primary"
            className="w-full"
            isReadOnly={isSubmitting}
          />
        </Form>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
