import { SlideNavigationType } from "~/components/molecules";

import {
  EmployeeRoleType,
  MemberRole,
  ManagerRole,
  OwnerRole,
} from "~/domains";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  url: string;
  selectedRole?: EmployeeRoleType;
};

export const useKnowledgeSlideNavigation = ({
  url,
  selectedRole,
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "チーム",
      href: `${url}/team`,
      selected: selectedRole?.type === MemberRole.type,
    },
    {
      label: "本部",
      href: `${url}/owner`,
      selected: selectedRole?.type === OwnerRole.type,
    },
    {
      label: "部署",
      href: `${url}/manager`,
      selected: selectedRole?.type === ManagerRole.type,
    },
  ];

  return {
    navigations: navigations,
  };
};
