import React, { FC } from "react";

import { useApisMembersEmployeesShow, useBoolean } from "~/hooks";

import { Avatar } from "~/components/atoms";

import { AvatarCard } from "./AvatarCard";
import { DmLink } from "./DmLink";

import { AvatarAndNameEmployeeType } from "~/domains";

type PropsType = {
  className?: string;
  avatarSize: "sm" | "md" | "lg";
  employee: AvatarAndNameEmployeeType;
  isDmOnly?: boolean;
};

export const PopupAvatar: FC<PropsType> = ({
  className = "",
  avatarSize,
  employee,
  isDmOnly = false,
}: PropsType) => {
  const { isChecked: alreadyHover, setTrue: onMouseEnter } = useBoolean(false);

  const { data } = useApisMembersEmployeesShow({
    id: employee.id,
    config: {
      enabled: alreadyHover && !!employee.id,
    },
  });

  return (
    <div onMouseEnter={onMouseEnter} className={`group relative ${className}`}>
      <Avatar src={employee.avatarUrl} size={avatarSize} />
      {data && (
        <div
          className={`hidden group-hover:block absolute ${
            isDmOnly ? "-top-2" : "-top-10"
          } left-full pl-3 z-modal`}
        >
          {isDmOnly ? (
            <DmLink employeeId={employee.id} />
          ) : (
            <AvatarCard
              employeeRole={data.employee.employeeRole}
              businessSectionNames={data.employee.businessSections.map(
                (businessSection) => businessSection.name,
              )}
              employeeId={employee.id}
              employeeName={employee.name}
              avatarUrl={employee.avatarUrl}
              jobCategoryName={data.employee.jobCategory.name}
              jobTitle={data.employee.jobTitle}
            />
          )}
        </div>
      )}
    </div>
  );
};
