import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { formatDateTime } from "~/libs";

import {
  useApisManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShow,
  useDropdown,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  DoughnutChartBox,
  LabelWithDropDownField,
  RangeDatePicker,
} from "~/components/molecules";
import { AnalysisCumulativeOfPostGraph } from "~/components/organisms";

export const ManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShow: FC =
  () => {
    const { insightPostObjectiveId = "" } = useParams<{
      insightPostObjectiveId: string;
    }>();

    const [
      {
        dropdownValue: employeeDropdownValue,
        onChange: onChangeEmployee,
        handleSetSelectableOption: handleSetSelectableEmployee,
        dropdownSelectableValue: selectableEmployees,
      },
    ] = useDropdown();

    const { data, isLoading } =
      useApisManagersInsightPostObjectivesEmployeeAndInsightPostObjectiveShow({
        insightPostObjectiveId,
        params: {
          employeeId: employeeDropdownValue?.value || "",
        },
        config: {
          onSuccess(data) {
            handleSetSelectableEmployee(
              data.insightPostObjective.employeeAndInsightPostObjectives.map(
                (employeeAndInsightPostObjective) =>
                  employeeAndInsightPostObjective.employee,
              ),
            );
          },
        },
      });

    const breadCrumbItems = [
      {
        label: "リフレクション目標",
        href: "/managers/insight_post_objectives",
      },
      {
        label: "状況確認",
        href: `/managers/insight_post_objectives/${insightPostObjectiveId}/employee_and_insight_post_objectives`,
      },
    ];
    console.log({ data });
    return (
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent withRightSidebar>
          {data && (
            <div className="space-y-6">
              <LabelWithDropDownField
                name="employees"
                options={selectableEmployees}
                value={employeeDropdownValue}
                onChange={onChangeEmployee}
                labelText="ユーザー"
                required
              />
              <RangeDatePicker
                readOnly
                wrapperClassName="w-full text-center justify-center"
                className="w-full text-center"
                startDate={new Date(data.insightPostObjective.startDate)}
                endDate={new Date(data.insightPostObjective.endDate)}
              />
              <h3 className="font-semibold text-center text-xl text-secondary-600">
                {data.insightPostObjective.title}
              </h3>
              {data.calculatePerformance && (
                <div className="mt-4 grid miniTablet:grid-cols-2 gap-x-6 gap-y-8">
                  <DoughnutChartBox
                    title="目標に対するリフレクション数"
                    doughnut={{
                      rate: data.calculatePerformance.performanceCountRate,
                      numerator: data.calculatePerformance.performanceCount,
                      denominator:
                        data.calculatePerformance.objectivePerformance,
                    }}
                    isLoading={isLoading}
                  />
                  <DoughnutChartBox
                    title="目標に対するポイント数"
                    doughnut={{
                      rate: data.calculatePerformance.performancePointRate,
                      numerator: data.calculatePerformance.performancePoint,
                      denominator:
                        data.calculatePerformance.objectivePerformance,
                    }}
                    isLoading={isLoading}
                  />
                  <AnalysisCumulativeOfPostGraph
                    title="ポイント"
                    isLoading={isLoading}
                    postAnalysis={data.dailyCumulativeInsightPostPoints}
                    className="col-span-2"
                  />
                  <AnalysisCumulativeOfPostGraph
                    title="リフレクション投稿数"
                    isLoading={isLoading}
                    postAnalysis={data?.insightPostAnalysis.map((post) => ({
                      ...post,
                      name: formatDateTime(post.name, "MM-dd"),
                    }))}
                    className="col-span-2"
                  />
                </div>
              )}
            </div>
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar />
      </EmployeeLayout>
    );
  };
