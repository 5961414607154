import { SlideNavigationType } from "~/components/molecules";

import { MyRoutes } from "~/config/Paths";

type ReturnType = {
  navigations: SlideNavigationType[];
};

type PropsType = {
  selectedArray: [boolean, boolean];
  businessSectionId?: string;
};

export const useEmployeeSurveysSlideNavigation = ({
  selectedArray,
  businessSectionId = "",
}: PropsType): ReturnType => {
  const navigations = [
    {
      label: "入力",
      href: MyRoutes.Members.inputEmployeeSurveysIndex({
        q: { businessSectionId },
      }),
      selected: selectedArray[0],
    },
    {
      label: "一覧",
      href: MyRoutes.Members.employeeSurveySubmissionsIndex({
        q: { businessSectionId },
      }),
      selected: selectedArray[1],
    },
  ];

  return {
    navigations,
  };
};
