import React, { FC, FormEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useEditorState,
  useDateRange,
  useDropdown,
  useMultipleDropdown,
  useApisManagersFocusChallengesUpdate,
  useApisManagersFocusChallengesEdit,
} from "~/hooks";

import { EmployeeLayout } from "~/components/layouts";
import { NotFoundIndex } from "~/components/pages";
import { ManagersFocusChallengesFormTemplate } from "~/components/templates";

import { PreviewWithFileType } from "~/domains";

export const ManagersFocusChallengesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: fetchData, isError } = useApisManagersFocusChallengesEdit({
    id,
    config: {
      onSuccess: (data) => {
        dropdownEmployees.setSelectableOption(data.employees);
        setDropdownEmployees(data.focusChallenge.employees);
        setDropdownPublicStatus({
          id: data.focusChallenge.publicStatus.id.toString(),
          name: data.focusChallenge.publicStatus.name,
        });
        setEditorState(data.focusChallenge.content);

        setDateRange([
          new Date(data.focusChallenge.challengeStartDate),
          new Date(data.focusChallenge.challengeFinishDate),
        ]);
      },
    },
  });
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useDateRange([null, null]);
  const [editorState, setEditorState] = useEditorState();
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);

  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisManagersFocusChallengesUpdate();
  const [dropdownEmployees, setDropdownEmployees] = useMultipleDropdown();
  const [dropdownPublicStatus, setDropdownPublicStatus] = useDropdown();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editorState.hasText) {
      toast.error("テキストが空です");
      return;
    }
    if (!dropdownPublicStatus.dropdownValue) {
      toast.error("公開ステータスが選択されていません");
      return;
    }
    const body = {
      content: editorState.jsonContent,
      employeeIds: dropdownEmployees.dropdownValue.map((v) => v.value),
      challengeStartDate: dateRange.formattedStartDate || "",
      challengeFinishDate: dateRange.formattedEndDate || "",
      attachFiles: files,
      publicStatusId: Number(dropdownPublicStatus.dropdownValue?.value),
    };
    postRequest(
      { id, body },
      {
        onSuccess: (data) => {
          navigate("/managers/focus_challenges");
          toast(data?.message);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "チャレンジ", href: "/managers/focus_challenges" },
    { label: "編集", href: `/managers/focus_challenges/${id}/edit` },
  ];

  if (isError) {
    return <NotFoundIndex />;
  }

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <ManagersFocusChallengesFormTemplate
        mentions={fetchData?.employees || []}
        isSubmitting={isSubmitting}
        editorState={{
          value: editorState.value,
          onChange: editorState.onChange,
        }}
        employees={{
          options: dropdownEmployees.dropdownSelectableValue,
          dropdownValue: dropdownEmployees.dropdownValue,
          onChange: dropdownEmployees.onChange,
        }}
        publicStatus={{
          dropdownValue: dropdownPublicStatus.dropdownValue,
          onChange: dropdownPublicStatus.onChange,
        }}
        files={files}
        setFiles={setFiles}
        dateRange={{
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          onChange: dateRange.onChange,
        }}
        handleSubmit={handleSubmit}
      />
    </EmployeeLayout>
  );
};
