import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp, formatDateTime, toDateString } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import { RangeDatePicker } from "~/components/molecules";
import {
  AnalysisCumulativeOfPostGraph,
  AnalysisDetailOfPostGraph,
  MultiParentsWithMultiChildrenDropdownField,
} from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApiAnalyticsPlansKnowledgeInsightPostsIndexResponseType,
  RangeDatePropsType,
  ChildType,
} from "~/domains";

type PropsType = {
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  data?: ApiAnalyticsPlansKnowledgeInsightPostsIndexResponseType;
  className?: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
  dateArray: Date[];
};

const today = new Date();

export const AnalyticsKnowledgeInsightPostsTemplate: FC<PropsType> = ({
  formattedStartDate,
  formattedEndDate,
  selectedDivisions,
  optionSelectedSections,
  isLoading,
  className = "",
  startDate,
  endDate,
  onChangeDateRange,
  onDivisionChange,
  onSectionChange,
  selectableDivisions,
  optionSelectableSections,
  data,
  dateArray,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-knowledge_analysis.csv`,
    );
  };

  const generateCsvData = () => {
    return [
      ["期間", `${formattedStartDate} ~ ${formattedEndDate}`],
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections?.length
          ? optionSelectedSections.map((section) => section.name).join(", ")
          : selectedDivisions?.length
            ? "選択された部署に属するすべての課"
            : "すべての課",
      ],
      [
        "",
        "チーム",
        "",
        "",
        "",
        "マネージャー",
        "",
        "",
        "",
        "オーナー",
        "",
        "",
        "",
      ],
      [
        "",
        "日毎の実施状況",
        "日毎の実施状況(平均)",
        "累計の実施",
        "累計の実施(平均)",
        "日毎の実施状況",
        "日毎の実施状況(平均)",
        "累計の実施",
        "累計の実施(平均)",
        "日毎の実施状況",
        "日毎の実施状況(平均)",
        "累計の実施",
        "累計の実施(平均)",
      ],
      ...dateArray.map((date) => {
        const membersDailyPostsCount = data?.membersDailyPostsCounts.find(
          (item) => item.name === toDateString(date),
        );
        const membersDailyCumulativePostsCount =
          data?.membersDailyCumulativePostsCounts.find(
            (item) => item.name === toDateString(date),
          );
        const managersDailyPostsCount = data?.managersDailyPostsCounts.find(
          (item) => item.name === toDateString(date),
        );
        const managersDailyCumulativePostsCount =
          data?.managersDailyCumulativePostsCounts.find(
            (item) => item.name === toDateString(date),
          );
        const ownersDailyPostsCount = data?.ownersDailyPostsCounts.find(
          (item) => item.name === toDateString(date),
        );
        const ownersDailyCumulativePostsCount =
          data?.ownersDailyCumulativePostsCounts.find(
            (item) => item.name === toDateString(date),
          );

        return [
          toDateString(date),
          membersDailyPostsCount?.postsTotalCount,
          membersDailyPostsCount?.postsAverageCount,
          membersDailyCumulativePostsCount?.postsTotalCount,
          membersDailyCumulativePostsCount?.postsAverageCount,
          managersDailyPostsCount?.postsTotalCount,
          managersDailyPostsCount?.postsAverageCount,
          managersDailyCumulativePostsCount?.postsTotalCount,
          managersDailyCumulativePostsCount?.postsAverageCount,
          ownersDailyPostsCount?.postsTotalCount,
          ownersDailyPostsCount?.postsAverageCount,
          ownersDailyCumulativePostsCount?.postsTotalCount,
          ownersDailyCumulativePostsCount?.postsAverageCount,
        ];
      }),
    ];
  };
  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentLabel="部署"
          parentsOnChange={onDivisionChange}
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childLabel="課"
          childrenOnChange={onSectionChange}
        />
        <RangeDatePicker
          labelText="期間"
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
          maxDate={today}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="mt-6 space-y-6">
        <div>
          <h2 className="text-primary-600 text-lg font-semibold text-center">
            チーム
          </h2>
          <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
            <AnalysisDetailOfPostGraph
              title="日毎の実施状況"
              isLoading={isLoading}
              postAnalysis={data?.membersDailyPostsCounts.map((item) => ({
                ...item,
                name: formatDateTime(item.name, "MM-dd"),
              }))}
            />
            <AnalysisCumulativeOfPostGraph
              title="累計の実施"
              isLoading={isLoading}
              postAnalysis={data?.membersDailyCumulativePostsCounts.map(
                (item) => ({
                  ...item,
                  name: formatDateTime(item.name, "MM-dd"),
                }),
              )}
            />
          </div>
        </div>
        <div>
          <h2 className="text-primary-600 text-lg font-semibold text-center">
            部署
          </h2>
          <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
            <AnalysisDetailOfPostGraph
              title="日毎の実施状況"
              isLoading={isLoading}
              postAnalysis={data?.managersDailyPostsCounts.map((item) => ({
                ...item,
                name: formatDateTime(item.name, "MM-dd"),
              }))}
            />
            <AnalysisCumulativeOfPostGraph
              title="累計の実施"
              isLoading={isLoading}
              postAnalysis={data?.managersDailyCumulativePostsCounts.map(
                (item) => ({
                  ...item,
                  name: formatDateTime(item.name, "MM-dd"),
                }),
              )}
            />
          </div>
        </div>
        <div>
          <h2 className="text-primary-600 text-lg font-semibold text-center">
            本部
          </h2>
          <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
            <AnalysisDetailOfPostGraph
              title="日毎の実施状況"
              isLoading={isLoading}
              postAnalysis={data?.ownersDailyPostsCounts.map((item) => ({
                ...item,
                name: formatDateTime(item.name, "MM-dd"),
              }))}
            />
            <AnalysisCumulativeOfPostGraph
              title="累計の実施"
              isLoading={isLoading}
              postAnalysis={data?.ownersDailyCumulativePostsCounts.map(
                (item) => ({
                  ...item,
                  name: formatDateTime(item.name, "MM-dd"),
                }),
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
