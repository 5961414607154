import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useRightSidebarState,
  useAnchorScroll,
  useApisMembersEmployeeSurveySubmissionRepliesShow,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  EmployeeSurveySubmissionDetail,
  EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

import { MyRoutes } from "~/config/Paths";

export const MembersEmployeeSurveySubmissionRepliesShow: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { isReplyOpen, setReplyOpen, setClose } =
    useRightSidebarState("isReplyOpen");
  const navigate = useNavigate();
  const breadCrumbItems = [
    {
      label: "チェックアウト",
      href: MyRoutes.Members.inputEmployeeSurveysIndex(),
    },
    {
      label: "共有",
      href: MyRoutes.Members.employeeSurveySubmissionRepliesShow({ id }),
    },
  ];

  const { data, refetch, isError } =
    useApisMembersEmployeeSurveySubmissionRepliesShow({
      id: id,
    });

  useEffect(() => {
    setReplyOpen();
  }, [setReplyOpen]);

  useAnchorScroll(!!data);

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          {data && (
            <EmployeeSurveySubmissionDetail
              employeeSurveySubmission={data.employeeSurveySubmission}
              openReply={setReplyOpen}
              className="mt-2"
              refetch={() => {
                setClose();
                navigate(MyRoutes.Members.employeeSurveySubmissionsIndex());
              }}
              canDelete
            />
          )}
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {isReplyOpen && data && (
            <>
              <RightSidebarTitle title="リプライ" />
              <EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission
                className="mt-6 mx-4"
                employeeSurveySubmission={data.employeeSurveySubmission}
                mentions={[]}
                employeeSurveySubmissionReplies={
                  data.employeeSurveySubmissionReplies
                }
                refetch={refetch}
                highlightEmployeeSurveySubmissionReplyId={id}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
