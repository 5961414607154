import React, { useEffect, FC } from "react";

import { calculateMaxPoint } from "~/utils";

import { useBoolean, useEditorState } from "~/hooks";

import { Editor } from "~/components/molecules";

import { SkillEvaluationStandardDetail } from "./SkillEvaluationStandardDetail";
import { SkillEvaluationStandardSummaryDetailTitle } from "./SkillEvaluationStandardSummaryDetailTitle";

import { SkillEvaluationStandardSummaryWithStandardsType } from "~/domains";

type PropsType = {
  skillEvaluationStandardSummary: SkillEvaluationStandardSummaryWithStandardsType;
  memberSelectedSkillEvaluationStandardIds: string[];
  managerSelectedSkillEvaluationStandardIds: string[];
  isOpen: boolean;
  index: number;
};

export const SkillEvaluationStandardSummaryDetail: FC<PropsType> = ({
  skillEvaluationStandardSummary,
  memberSelectedSkillEvaluationStandardIds,
  managerSelectedSkillEvaluationStandardIds,
  isOpen,
  index,
}: PropsType) => {
  const { isChecked, toggle, handleChange } = useBoolean(false);
  const { title, content, skillEvaluationStandards } =
    skillEvaluationStandardSummary;
  const [{ value: contentValue, onChange: onChangeContent }] =
    useEditorState(content);
  const maxPoint = calculateMaxPoint(
    skillEvaluationStandards.map((v) => v.point),
  );

  useEffect(() => {
    handleChange(isOpen);
  }, [handleChange, isOpen]);

  return (
    <div>
      <SkillEvaluationStandardSummaryDetailTitle
        title={title}
        index={index}
        maxPoint={maxPoint}
        isChecked={isChecked}
        toggle={toggle}
      />
      {isChecked && (
        <>
          <div className="mt-4">
            <Editor
              editorState={contentValue}
              readOnly
              onChange={onChangeContent}
              canFileInText={false}
            />
          </div>
          <div className="mt-4 flex overflow-x-scroll w-full space-x-4 items-start pb-4">
            {skillEvaluationStandards.map((standard) => (
              <SkillEvaluationStandardDetail
                key={standard.id}
                skillEvaluationStandard={standard}
                memberSelected={memberSelectedSkillEvaluationStandardIds.includes(
                  standard.id,
                )}
                managerSelected={managerSelectedSkillEvaluationStandardIds.includes(
                  standard.id,
                )}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
