import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp, formatDateTime, toDateString } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import { RangeDatePicker } from "~/components/molecules";
import {
  AnalysisCumulativeOfPostGraph,
  DivisionAndSectionAndEmployeeDropdownField,
} from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApiAnalyticsPlansCumulativeAnalysisIndexResponseType,
  RangeDatePropsType,
  ChildType,
} from "~/domains";

type PropsType = {
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  selectedEmployee?: ParentType;
  data?: ApiAnalyticsPlansCumulativeAnalysisIndexResponseType;
  className?: string;
  isLoading: boolean;
  dateArray: Date[];
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  onEmployeeChange: (newValue: ParentType | undefined) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
  selectableEmployees: ParentType[];
};

const today = new Date();

export const AnalyticsCumulativeAnalysisTemplate: FC<PropsType> = ({
  formattedStartDate,
  formattedEndDate,
  selectedDivisions,
  optionSelectedSections,
  selectedEmployee,
  isLoading,
  className = "",
  startDate,
  endDate,
  dateArray,
  onChangeDateRange,
  onDivisionChange,
  onSectionChange,
  onEmployeeChange,
  selectableDivisions,
  optionSelectableSections,
  selectableEmployees,
  data,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-cumulative_analysis.csv`,
    );
  };

  const generateCsvData = () => {
    return [
      ["期間", `${formattedStartDate} ~ ${formattedEndDate}`],
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections?.length
          ? optionSelectedSections.map((section) => section.name).join(", ")
          : selectedDivisions?.length
            ? "選択された部署に属するすべての課"
            : "すべての課",
      ],
      ["ユーザー", selectedEmployee?.name || "すべてのユーザー"],
      [
        "日付",
        "リフレクション",
        "リフレクション(平均)",
        "リフレクションへのいいね数",
        "リフレクションへのいいね数(平均)",
        "リフレクションへのブクマ数",
        "リフレクションへのブクマ数(平均)",
        "チャレンジ",
        "チャレンジ(平均)",
        "チャレンジへのいいね数",
        "チャレンジへのいいね数(平均)",
        "改善・相談",
        "改善・相談(平均)",
        "改善・相談へのいいね数",
        "改善・相談へのいいね数(平均)",
        "ナレッジシェア",
        "ナレッジシェア(平均)",
      ],
      ...dateArray.map((date) => {
        const dateString = toDateString(date);
        const insightPostsDailyPostsCounts =
          data?.insightPostsDailyPostsCounts.find(
            (item) => item.name === dateString,
          );
        const insightPostLikesDailyPostsCounts =
          data?.insightPostLikesDailyPostsCounts.find(
            (item) => item.name === dateString,
          );
        const insightPostBookmarksDailyPostsCounts =
          data?.insightPostBookmarksDailyPostsCounts.find(
            (item) => item.name === dateString,
          );
        const focusChallengeEmployeesDailyPostsCounts =
          data?.focusChallengeEmployeesDailyPostsCounts.find(
            (item) => item.name === dateString,
          );
        const focusChallengeEmployeeLikesDailyPostsCounts =
          data?.focusChallengeEmployeeLikesDailyPostsCounts.find(
            (item) => item.name === dateString,
          );
        const kaizenIdeasDailyPostsCounts =
          data?.kaizenIdeasDailyPostsCounts.find(
            (item) => item.name === dateString,
          );
        const kaizenIdeaLikesDailyPostsCounts =
          data?.kaizenIdeaLikesDailyPostsCounts.find(
            (item) => item.name === dateString,
          );
        const sharedInsightPostsDailyPostsCounts =
          data?.sharedInsightPostsDailyPostsCounts.find(
            (item) => item.name === dateString,
          );
        return [
          dateString,
          insightPostsDailyPostsCounts?.postsTotalCount,
          insightPostsDailyPostsCounts?.postsAverageCount,
          insightPostLikesDailyPostsCounts?.postsTotalCount,
          insightPostLikesDailyPostsCounts?.postsAverageCount,
          insightPostBookmarksDailyPostsCounts?.postsTotalCount,
          insightPostBookmarksDailyPostsCounts?.postsAverageCount,
          focusChallengeEmployeesDailyPostsCounts?.postsTotalCount,
          focusChallengeEmployeesDailyPostsCounts?.postsAverageCount,
          focusChallengeEmployeeLikesDailyPostsCounts?.postsTotalCount,
          focusChallengeEmployeeLikesDailyPostsCounts?.postsAverageCount,
          kaizenIdeasDailyPostsCounts?.postsTotalCount,
          kaizenIdeasDailyPostsCounts?.postsAverageCount,
          kaizenIdeaLikesDailyPostsCounts?.postsTotalCount,
          kaizenIdeaLikesDailyPostsCounts?.postsAverageCount,
          sharedInsightPostsDailyPostsCounts?.postsTotalCount,
          sharedInsightPostsDailyPostsCounts?.postsAverageCount,
        ];
      }),
    ];
  };

  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <DivisionAndSectionAndEmployeeDropdownField
          selectableDropdownDivisions={selectableDivisions}
          selectedDropdownDivisions={selectedDivisions}
          onDivisionChange={onDivisionChange}
          selectableDropdownSections={optionSelectableSections}
          selectedDropdownSections={optionSelectedSections}
          onSectionChange={onSectionChange}
          selectableDropdownEmployees={selectableEmployees}
          selectedDropdownEmployee={selectedEmployee}
          onEmployeeChange={onEmployeeChange}
        />
        <RangeDatePicker
          labelText="期間"
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
          maxDate={today}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
        <AnalysisCumulativeOfPostGraph
          title="リフレクション"
          isLoading={isLoading}
          postAnalysis={data?.insightPostsDailyPostsCounts.map((item) => ({
            ...item,
            name: formatDateTime(item.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="リフレクションへのいいね数"
          isLoading={isLoading}
          postAnalysis={data?.insightPostLikesDailyPostsCounts.map((item) => ({
            ...item,
            name: formatDateTime(item.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="リフレクションへのブクマ数"
          isLoading={isLoading}
          postAnalysis={data?.insightPostBookmarksDailyPostsCounts.map(
            (item) => ({
              ...item,
              name: formatDateTime(item.name, "MM-dd"),
            }),
          )}
        />
        <AnalysisCumulativeOfPostGraph
          title="チャレンジ"
          isLoading={isLoading}
          postAnalysis={data?.focusChallengeEmployeesDailyPostsCounts.map(
            (item) => ({
              ...item,
              name: formatDateTime(item.name, "MM-dd"),
            }),
          )}
        />
        <AnalysisCumulativeOfPostGraph
          title="チャレンジへのいいね数"
          isLoading={isLoading}
          postAnalysis={data?.focusChallengeEmployeeLikesDailyPostsCounts.map(
            (item) => ({
              ...item,
              name: formatDateTime(item.name, "MM-dd"),
            }),
          )}
        />
        <AnalysisCumulativeOfPostGraph
          title="改善・相談"
          isLoading={isLoading}
          postAnalysis={data?.kaizenIdeasDailyPostsCounts.map((item) => ({
            ...item,
            name: formatDateTime(item.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="改善・相談へのいいね数"
          isLoading={isLoading}
          postAnalysis={data?.kaizenIdeaLikesDailyPostsCounts.map((item) => ({
            ...item,
            name: formatDateTime(item.name, "MM-dd"),
          }))}
        />
        <AnalysisCumulativeOfPostGraph
          title="ナレッジシェアされた数"
          isLoading={isLoading}
          postAnalysis={data?.sharedInsightPostsDailyPostsCounts.map(
            (item) => ({
              ...item,
              name: formatDateTime(item.name, "MM-dd"),
            }),
          )}
        />
      </div>
    </div>
  );
};
