import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisManagersDocumentFileCategoriesEdit,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { Loading } from "~/components/molecules";
import { DocumentFileCategoryWithDocumentFilesForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersDocumentFileCategoriesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { data: fetchEditData, isError } =
    useApisManagersDocumentFileCategoriesEdit({
      id,
    });

  const breadCrumbItems = [
    { label: "ライブラリ", href: "/managers/document_file_categories" },
    { label: "編集", href: `/managers/document_file_categories/${id}/edit` },
  ];

  if (isError) {
    return <NotFoundIndex />;
  }

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {currentEmployee && fetchEditData ? (
          <DocumentFileCategoryWithDocumentFilesForm
            currentEmployee={currentEmployee}
            documentFileCategory={fetchEditData.documentFileCategory}
            selectableDocumentFileTags={
              fetchEditData.selectableDocumentFileTags
            }
            selectableProvidingServices={
              fetchEditData.selectableProvidingServices
            }
          />
        ) : (
          <Loading />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
