import React, { FormEvent, MouseEvent, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useDateRange,
  useProvidersCurrentEmployee,
  useDropdown,
  useApisManagersSkillEvaluationTermsCreate,
  useApisManagersSkillEvaluationTermsUpdate,
} from "~/hooks";

import { Button, Form, FormSubmitButton } from "~/components/atoms";
import {
  LabelWithDropDownField,
  LabelWithTextField,
  RangeDatePicker,
  RangeDatePropsType,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import {
  PublicStatus,
  SkillEvaluationStandardSummaryCategoryType,
  SkillEvaluationTermType,
} from "~/domains";

type PropsType = {
  skillEvaluationStandardSummaryCategories: SkillEvaluationStandardSummaryCategoryType[];
  skillEvaluationTerm?: SkillEvaluationTermType;
};

export const SkillEvaluationTermForm: FC<PropsType> = ({
  skillEvaluationStandardSummaryCategories,
  skillEvaluationTerm,
}: PropsType) => {
  const navigate = useNavigate();
  const { currentEmployee } = useProvidersCurrentEmployee();
  const { mutate: updateRequest, isLoading: isCreating } =
    useApisManagersSkillEvaluationTermsUpdate();
  const { mutate: createRequest, isLoading: isUpdating } =
    useApisManagersSkillEvaluationTermsCreate();

  const initialDate: RangeDatePropsType = skillEvaluationTerm
    ? [
        new Date(skillEvaluationTerm.fromDate),
        new Date(skillEvaluationTerm.toDate),
      ]
    : [null, null];
  const [title] = useInput(skillEvaluationTerm?.title || "");
  const [dateRange] = useDateRange(initialDate);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
    defaultDivisions: skillEvaluationTerm?.businessDivisions,
    defaultSections: skillEvaluationTerm?.businessSections,
  });

  const [categoryDropdown] = useDropdown({
    selectableValue: skillEvaluationStandardSummaryCategories,
    initialValue:
      skillEvaluationTerm?.archivedSkillEvaluationStandardSummaryCategory,
  });

  const handlePublishSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    skillEvaluationTerm
      ? handleUpdateRequest(PublicStatus.PUBLISHED.id)
      : handleCreateRequest(PublicStatus.PUBLISHED.id);
  };

  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    skillEvaluationTerm
      ? handleUpdateRequest(PublicStatus.DRAFTED.id)
      : handleCreateRequest(PublicStatus.DRAFTED.id);
  };

  const handleCreateRequest = (publicStatusId: number) => {
    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      title: title.value,
      fromDate: dateRange.formattedStartDate || "",
      toDate: dateRange.formattedEndDate || "",
      publicStatusId: publicStatusId,
      skillEvaluationStandardSummaryCategoryId:
        categoryDropdown.dropdownValue?.value || "",
    };

    createRequest(
      {
        body,
      },
      {
        onSuccess: (data) => {
          onSuccessAction(data.message);
        },
      },
    );
  };

  const handleUpdateRequest = (publicStatusId: number) => {
    const body = {
      title: title.value,
      fromDate: dateRange.formattedStartDate || "",
      toDate: dateRange.formattedEndDate || "",
      publicStatusId: publicStatusId,
    };

    updateRequest(
      {
        id: skillEvaluationTerm?.id || "",
        body,
      },
      {
        onSuccess: (data) => {
          onSuccessAction(data.message);
        },
      },
    );
  };

  const onSuccessAction = (successMessage: string) => {
    toast(successMessage);
    navigate("/managers/skill_evaluation_terms");
  };
  const isSubmitting = isCreating || isUpdating;
  return (
    <>
      <Form className="space-y-6" onSubmit={handlePublishSubmit}>
        <MultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentsOnChange={onDivisionChange}
          parentRequired
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childrenOnChange={onSectionChange}
          childRequired
          isDisabled={Boolean(skillEvaluationTerm)}
        />
        <LabelWithTextField
          labelText="タイトル"
          type="text"
          name="title"
          placeholder="タイトル"
          required
          value={title.value}
          onChange={title.onChange}
        />
        <RangeDatePicker
          labelText="期間指定"
          required={true}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChange={dateRange.onChange}
        />
        <LabelWithDropDownField
          labelText="スキルマップ"
          name="providingServiceId"
          options={categoryDropdown.dropdownSelectableValue}
          placeholder="全ジャンル共通(ジャンル選択)"
          value={categoryDropdown.dropdownValue}
          onChange={categoryDropdown.onChange}
          required
          isDisabled={Boolean(skillEvaluationTerm)}
        />
        <div className="flex items-center space-x-4 mx-auto">
          <Button
            text="下書き保存"
            color="gray"
            outline
            className="w-full"
            onClick={handleDraftSubmit}
            readonly={isSubmitting}
          />
          <FormSubmitButton
            value="配布をする"
            color="primary"
            className="w-full"
            isReadOnly={isSubmitting}
          />
        </div>
      </Form>
    </>
  );
};
