import React, { MouseEvent, useState, FC } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useMultiDivisionMultiSectionDropdown,
  useDocumentFileForms,
  useApisManagersDocumentFileCategoriesUpdate,
  useBoolean,
} from "~/hooks";

import { Button, DragDropProvider, NoContentMessage } from "~/components/atoms";
import { VerticalProgressLine } from "~/components/atoms";
import {
  LabelWithTextField,
  TabList,
  AddFormFieldButton,
  NameOrderableForm,
} from "~/components/molecules";
import { AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { DocumentFileForm } from "./DocumentFileForm";

import {
  DocumentFileCategoryWithDocumentFileType,
  PublicStatus,
  OptionType,
  MultiValueType,
  DocumentFileTagType,
  EmployeeProfileType,
  ProvidingServiceType,
} from "~/domains";

type PropsType = {
  currentEmployee: EmployeeProfileType;
  selectableDocumentFileTags: DocumentFileTagType[];
  selectableProvidingServices: ProvidingServiceType[];
  documentFileCategory: DocumentFileCategoryWithDocumentFileType;
};

export const DocumentFileCategoryWithDocumentFilesForm: FC<PropsType> = ({
  selectableDocumentFileTags,
  selectableProvidingServices,
  documentFileCategory,
  currentEmployee,
}: PropsType) => {
  const navigate = useNavigate();
  const { mutate: doRequest, isLoading: isUpdating } =
    useApisManagersDocumentFileCategoriesUpdate();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [{ value: categoryName, onChange: onChangeCategoryName }] = useInput(
    documentFileCategory.name,
  );
  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(documentFileCategory.allBusinessSectionsDistribution);

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee.businessDivisions,
    selectableSections: currentEmployee.businessSections,
    defaultDivisions: documentFileCategory.businessDivisions,
    defaultSections: documentFileCategory.businessSections,
  });

  const {
    isSubmitting,
    documentFiles,
    onChangeName,
    saveDocumentFile,
    addDocumentFile,
    removeDocumentFile,
    changeDisplayOrder,
    onChangeAttachFile,
    onChangeThumbnailImage,
    handleTagNameCreate,
    onChangeTags,
    onChangeProvidingServices,
    onChangeSetNotReadOnly,
    onChangeSetReadOnly,
    selectableDocumentFileTagOptions,
    selectableProvidingServiceOptions,
  } = useDocumentFileForms({
    documentFileCategoryId: documentFileCategory.id,
    documentFiles: documentFileCategory.documentFiles,
    selectableDocumentFileTags,
    selectableProvidingServices,
  });

  const handleDraftSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.DRAFTED.id);
  };

  const handlePublishSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const isConfirm = confirm(
      "作成・編集した「各項目」を「保存」しなければ反映されません。配布を実行してよろしいですか？",
    );
    if (!isConfirm) return;

    handleSubmit(PublicStatus.PUBLISHED.id);
  };

  const handleSubmit = (publicStatusId: number) => {
    const body = {
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      name: categoryName,
      publicStatusId,
      allBusinessSectionsDistribution,
    };

    doRequest(
      {
        id: documentFileCategory.id,
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          navigate("/managers/document_file_categories");
        },
      },
    );
  };

  const isCurrentDocumentFile = selectedTabIndex === 0;
  const savedDocumentFiles = documentFiles.filter(
    (documentFile) => !documentFile.isNew,
  );
  return (
    <>
      <div className="space-y-6">
        <AllDistributionCheckboxMultiParentsWithMultiChildrenDropdownField
          allParents={selectableDivisions}
          parentsValue={selectedDivisions}
          parentsOnChange={onDivisionChange}
          parentRequired={true}
          parentLabel="配布先: 部署"
          allChildren={optionSelectableSections}
          childrenValue={optionSelectedSections}
          childrenOnChange={onSectionChange}
          childRequired={true}
          childLabel="配布先: 課"
          allBusinessSectionsDistribution={allBusinessSectionsDistribution}
          onChangeAllBusinessSectionsDistribution={
            onChangeAllBusinessSectionsDistribution
          }
          currentEmployeeRole={currentEmployee.employeeRole}
        />
        <LabelWithTextField
          labelText="名前"
          type="text"
          name="name"
          placeholder="名前"
          required
          value={categoryName}
          onChange={onChangeCategoryName}
        />
        <TabList
          tabTexts={["作成", "並び替え"]}
          onClick={(index: number) => setSelectedTabIndex(index)}
          selectedIndex={selectedTabIndex}
        />
        {isCurrentDocumentFile ? (
          documentFiles.length ? (
            <VerticalProgressLine>
              {documentFiles.map((documentFile) => (
                <DocumentFileForm
                  isSubmitting={isSubmitting}
                  defaultAccordionOpen={Boolean(documentFile.isNew)}
                  key={documentFile.id}
                  documentFile={documentFile}
                  onChangeSetNotReadOnly={() =>
                    onChangeSetNotReadOnly(documentFile)
                  }
                  onChangeSetReadOnly={() => onChangeSetReadOnly(documentFile)}
                  removeDocumentFile={() => removeDocumentFile(documentFile)}
                  saveDocumentFile={() => saveDocumentFile(documentFile)}
                  onChangeName={(newValue: string) =>
                    onChangeName({
                      documentFile,
                      newValue,
                    })
                  }
                  onChangeAttachFile={(newValue: File | undefined) =>
                    onChangeAttachFile({
                      documentFile,
                      newValue,
                    })
                  }
                  onChangeThumbnailImage={(newValue: File | undefined) =>
                    onChangeThumbnailImage({
                      documentFile,
                      newValue,
                    })
                  }
                  handleTagNameCreate={(newValue: string) =>
                    handleTagNameCreate({
                      documentFile,
                      newValue,
                    })
                  }
                  onChangeTags={(newValue: MultiValueType<OptionType>) =>
                    onChangeTags({
                      documentFile,
                      newValue,
                    })
                  }
                  onChangeProvidingServices={(
                    newValue: MultiValueType<OptionType>,
                  ) =>
                    onChangeProvidingServices({
                      documentFile,
                      newValue,
                    })
                  }
                  selectableDocumentFileTagOptions={
                    selectableDocumentFileTagOptions
                  }
                  selectableProvidingServiceOptions={
                    selectableProvidingServiceOptions
                  }
                />
              ))}
            </VerticalProgressLine>
          ) : (
            <NoContentMessage
              text="ライブラリファイルがありません"
              className="mt-4"
            />
          )
        ) : savedDocumentFiles.length ? (
          <DragDropProvider>
            <VerticalProgressLine>
              {savedDocumentFiles.map((documentFile, index) => (
                <NameOrderableForm
                  key={`documentFile${documentFile.id}`}
                  name={documentFile.name}
                  index={index}
                  changeDisplayOrder={(beforeIndex: number, newIndex: number) =>
                    changeDisplayOrder(beforeIndex, newIndex)
                  }
                />
              ))}
            </VerticalProgressLine>
          </DragDropProvider>
        ) : (
          <NoContentMessage
            text="保存されたライブラリがありません"
            className="mt-4"
          />
        )}
        {isCurrentDocumentFile && (
          <AddFormFieldButton onClick={addDocumentFile} />
        )}
        <div className="flex items-center space-x-4 mx-auto">
          <Button
            text="下書き保存"
            color="gray"
            outline
            className="w-full"
            onClick={handleDraftSubmit}
            readonly={isUpdating}
          />
          <Button
            text="配布をする"
            color="primary"
            className="w-full"
            onClick={handlePublishSubmit}
            readonly={isUpdating}
          />
        </div>
      </div>
    </>
  );
};
