import React, { FC } from "react";
import { useParams } from "react-router-dom";

import {
  useApisManagersEmployeeSurveysShow,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { EmployeeSurveyWithQuestionsForm } from "~/components/organisms";

export const ManagersEmployeeSurveysEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const { data: fetchShowData } = useApisManagersEmployeeSurveysShow({
    id,
  });

  const { currentEmployee } = useProvidersCurrentEmployee();

  const breadCrumbItems = [
    { label: "チェックアウト", href: "/managers/employee_surveys" },
    { label: "編集", href: `/managers/employee_surveys/${id}/edit` },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {fetchShowData && (
          <EmployeeSurveyWithQuestionsForm
            selectableBusinessDivisions={
              currentEmployee?.businessDivisions || []
            }
            selectableBusinessSections={currentEmployee?.businessSections || []}
            employeeSurvey={fetchShowData.employeeSurvey}
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
