import React, { FC, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { SignOut } from "~/utils";

import { useBoolean, useIsStartPathPage } from "~/hooks";

import { LogoImage, Link, ModalWrapper } from "~/components/atoms";
import { HomeSidebar } from "~/components/organisms";

import {
  AnalyticsPlanType,
  BreadCrumbItemType,
  ManagerOrOwnerRoles,
  EmployeeProfileType,
  ApiMembersUnreadNotificationCountsType,
} from "~/domains";

type PropsType = {
  breadCrumbItems: BreadCrumbItemType[];
  currentEmployee?: EmployeeProfileType;
  unreadNotification?: ApiMembersUnreadNotificationCountsType;
  className?: string;
};

export const LogoWithHeaderMenu: FC<PropsType> = ({
  className = "",
  breadCrumbItems,
  currentEmployee,
  unreadNotification,
}: PropsType) => {
  const isManagerPage = useIsStartPathPage("managers");
  const { isChecked: isHeaderMenuOpen, toggle: toggleHeaderMenu } =
    useBoolean(false);
  const navigate = useNavigate();

  const isManagerOrOwner = ManagerOrOwnerRoles.some(
    (role) => role.id === currentEmployee?.employeeRole.id,
  );
  const isAnalyticsPlan =
    currentEmployee?.company.contractPlan.id === AnalyticsPlanType.id;

  const onClickSignOut = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = confirm("本当にログアウトしますか？");
    if (!result) return;

    await SignOut({
      isAdmin: false,
      onSuccess: () => {
        toast("ログアウトしました");
        navigate("/sign_in");
      },
      onError: (err) => {
        toast.error(err.message);
      },
    });
  };

  const handleToggleHeaderMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleHeaderMenu();
  };

  return (
    <div
      className={`h-full flex items-center pl-0 miniTablet:pl-3.5 pr-0 miniTablet:pr-2 border-0 miniTablet:border-r border-secondary-200 ${className}`}
    >
      <Link to="/" className="hidden miniTablet:inline">
        <LogoImage size="md" />
      </Link>
      <button
        type="button"
        onClick={handleToggleHeaderMenu}
        className="inline miniTablet:hidden ml-6"
      >
        <LogoImage size="md" />
      </button>
      {isHeaderMenuOpen && (
        <ModalWrapper onClose={toggleHeaderMenu} headerTitle="メニュー">
          <div className="mt-3.5">
            <HomeSidebar
              breadCrumbText={breadCrumbItems[0]?.label || "リフレクション"}
              unreadNotification={unreadNotification}
            />
            <hr className="bg-secondary-300 my-2" />
            <Link
              to={isManagerPage ? "/managers/profile" : "/profile"}
              className="block truncate font-medium px-4 py-1.5"
            >
              マイページ
            </Link>
            <Link
              to="/"
              color="primary"
              className="block truncate font-medium px-4 py-1.5"
            >
              メンバーページ
            </Link>
            {isManagerOrOwner && (
              <>
                <Link
                  to="/managers"
                  color="primary"
                  className="block truncate font-medium px-4 py-1.5"
                >
                  管理者ページ
                </Link>
                {isAnalyticsPlan && (
                  <Link
                    to="/analytics_plans"
                    color="primary"
                    className="block truncate font-medium px-4 py-1.5"
                  >
                    アナリティクスページ
                  </Link>
                )}
              </>
            )}
            <button
              type="button"
              onClick={onClickSignOut}
              className="block truncate font-medium px-4 py-1.5"
            >
              ログアウト
            </button>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};
