import React, { FC, FormEvent, ChangeEvent } from "react";

import {
  BooleanDropDownField,
  Form,
  FormSubmitButton,
} from "~/components/atoms";
import {
  LabelWithTextField,
  LabelWithMultipleDropdownField,
} from "~/components/molecules";

import { OptionType, MultiValueType } from "~/domains";

type PropsType = {
  isSubmitting: boolean;
  isOwner: boolean;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  providingServiceName: string;
  onChangeProvidingServiceName: (e: ChangeEvent<HTMLInputElement>) => void;
  selectableBusinessSections: OptionType[];
  selectedBusinessSections?: MultiValueType<OptionType>;
  onChangeBusinessSections: (newValue: MultiValueType<OptionType>) => void;
  allBusinessSectionsDistribution: boolean;
  onChangeAllBusinessSectionsDistribution: (newValue: boolean) => void;
};

export const ProvidingServiceForm: FC<PropsType> = ({
  isSubmitting,
  isOwner,
  handleSubmit,
  providingServiceName,
  onChangeProvidingServiceName,
  selectableBusinessSections,
  selectedBusinessSections,
  onChangeBusinessSections,
  allBusinessSectionsDistribution,
  onChangeAllBusinessSectionsDistribution,
}) => {
  const handleChangeAllBusinessSectionsDistribution = (newValue?: boolean) => {
    if (!isOwner) return;
    onChangeAllBusinessSectionsDistribution(newValue || false);
    if (newValue) {
      onChangeBusinessSections(selectableBusinessSections);
    } else {
      onChangeBusinessSections([]);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="space-y-6">
      <BooleanDropDownField
        name="isAllBusinessSections"
        trueLabel="すべての課に配布する"
        falseLabel="個別の課に配布する"
        labelText="配布種別の選択"
        value={allBusinessSectionsDistribution}
        isDisabled={!isOwner}
        onChange={handleChangeAllBusinessSectionsDistribution}
        required
      />
      {!allBusinessSectionsDistribution && (
        <LabelWithMultipleDropdownField
          labelText="課"
          required
          name="businessSections"
          options={selectableBusinessSections}
          value={selectedBusinessSections}
          onChange={onChangeBusinessSections}
        />
      )}
      <LabelWithTextField
        labelText="ジャンル名"
        type="text"
        name="providingService"
        required
        placeholder="ジャンル名"
        value={providingServiceName}
        onChange={onChangeProvidingServiceName}
      />
      <FormSubmitButton
        color="primary"
        value="保存"
        className="w-full"
        isReadOnly={isSubmitting}
      />
    </Form>
  );
};
