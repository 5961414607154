import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  Form,
  FormSubmitButton,
  TextField,
  MultipleDropDownField,
  DropDownField,
  Button,
} from "~/components/atoms";
import {
  LabelWithCheckBox,
  RangeDatePropsType,
  TitleWithChild,
} from "~/components/molecules";
import {
  DivisionAndSectionAndEmployeesDropdownField,
  SelectDateRangeButtons,
} from "~/components/organisms";

import {
  OptionType,
  MultiValueType,
  ParentType,
  ChildType,
  SingleValueType,
} from "~/domains";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  date: {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (value: RangeDatePropsType) => void;
  };
  providingService: {
    options: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  priorityRating: {
    options: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  isBelongingEmployee?: {
    isChecked: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  divisionAndSections?: {
    selectableDivisions: ParentType[];
    selectedDivisions: ParentType[];
    onDivisionChange: (newValue: ParentType[]) => void;
    selectableSections: ChildType[];
    selectedSection: ChildType[];
    onSectionChange: (newValue: ChildType[]) => void;
    selectableEmployees: ParentType[];
    selectedEmployees: ParentType[];
    onEmployeesChange: (newValue: ParentType[]) => void;
    selectableDropdownArchivedEmployees: ParentType[];
    selectedDropdownArchivedEmployees: ParentType[];
    onArchivedEmployeesChange: (value: ParentType[]) => void;
  };
  kaizenCompleted: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  isFromClient: {
    options: OptionType[];
    value: SingleValueType<OptionType> | undefined;
    onChange: (newValue: SingleValueType<OptionType>) => void;
  };
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
};

export const KaizenIdeaSearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  date,
  providingService,
  priorityRating,
  isBelongingEmployee,
  divisionAndSections,
  kaizenCompleted,
  isFromClient,
  searchKeyword,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="期間指定">
          <SelectDateRangeButtons
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={date.onChange}
            className="my-2"
          />
        </TitleWithChild>
        {divisionAndSections && (
          <TitleWithChild title="部署・課・ユーザー" className="mt-2 space-y-6">
            <DivisionAndSectionAndEmployeesDropdownField
              selectableDropdownDivisions={
                divisionAndSections.selectableDivisions
              }
              selectedDropdownDivisions={divisionAndSections.selectedDivisions}
              onDivisionChange={divisionAndSections.onDivisionChange}
              selectableDropdownSections={
                divisionAndSections.selectableSections
              }
              selectedDropdownSections={divisionAndSections.selectedSection}
              onSectionChange={divisionAndSections.onSectionChange}
              selectableDropdownEmployees={
                divisionAndSections.selectableEmployees
              }
              selectedDropdownEmployees={divisionAndSections.selectedEmployees}
              onEmployeesChange={divisionAndSections.onEmployeesChange}
              selectableDropdownArchivedEmployees={
                divisionAndSections.selectableDropdownArchivedEmployees
              }
              selectedDropdownArchivedEmployees={
                divisionAndSections.selectedDropdownArchivedEmployees
              }
              onArchivedEmployeesChange={
                divisionAndSections.onArchivedEmployeesChange
              }
            />
          </TitleWithChild>
        )}
        <TitleWithChild title="ジャンル">
          <MultipleDropDownField
            name="providingService"
            options={providingService.options}
            value={providingService.value}
            onChange={providingService.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="優先度">
          <MultipleDropDownField
            name="priorityRating"
            options={priorityRating.options}
            value={priorityRating.value}
            onChange={priorityRating.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="改善・相談状況">
          <DropDownField
            name="kaizenCompleted"
            options={kaizenCompleted.options}
            value={kaizenCompleted.value}
            onChange={kaizenCompleted.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="提案元">
          <DropDownField
            name="isFromClient"
            options={isFromClient.options}
            value={isFromClient.value}
            onChange={isFromClient.onChange}
          />
        </TitleWithChild>
        {isBelongingEmployee && (
          <TitleWithChild title="詳細条件">
            <LabelWithCheckBox
              labelText="ユーザー紐付き"
              labelEnd
              checked={isBelongingEmployee.isChecked}
              name="hasBookmark"
              onChange={isBelongingEmployee.onChange}
            />
          </TitleWithChild>
        )}
        <TitleWithChild title="フリーワード">
          <TextField
            value={searchKeyword.value}
            placeholder="キーワード"
            type="search"
            name="keyword"
            onChange={searchKeyword.onChange}
          />
        </TitleWithChild>
        <div className="space-x-4 flex">
          <Button
            text="リセット"
            color="gray"
            outline
            onClick={onConditionReset}
            className="w-full"
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
