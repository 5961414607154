import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersArchivedJobCategoriesDestroy,
  useApisManagersArchivedJobCategoriesIndex,
  useApisManagersArchivedJobCategoriesRestoresUpdate,
  useArray,
} from "~/hooks";

import { GridHeaderCells } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { GridRow, OpenedMenuInGrid, Loading } from "~/components/molecules";

import {
  JobCategoryWithJobCategoryPhaseType,
  JobCategoryPhaseWithOutDisplayOrderType,
} from "~/domains";

export const ManagersArchivedJobCategoriesIndex: FC = () => {
  const {
    items: jobCategories,
    setItems: setJobCategories,
    findAndRemove,
  } = useArray<JobCategoryWithJobCategoryPhaseType>([]);
  const { isFetching } = useApisManagersArchivedJobCategoriesIndex({
    config: {
      onSuccess: (data) => {
        setJobCategories(data.jobCategories);
      },
    },
  });

  const { mutate: jobCategoryDestroy } =
    useApisManagersArchivedJobCategoriesDestroy();
  const { mutate: jobCategoryRestore } =
    useApisManagersArchivedJobCategoriesRestoresUpdate();

  const jobCategoryPhasesInCellLayout = (
    jobCategoryPhases: JobCategoryPhaseWithOutDisplayOrderType[],
  ) => {
    return jobCategoryPhases
      .map((jobCategoryPhase) => `${jobCategoryPhase.name}`)
      .join(" / ");
  };

  const deleteJobCategory = (
    jobCategory: JobCategoryWithJobCategoryPhaseType,
  ) => {
    const isConfirm = confirm("この操作は取り消せません。本当に削除しますか？");
    if (!isConfirm) return;
    jobCategoryDestroy(
      {
        id: jobCategory.id,
      },
      {
        onSuccess: (res) => {
          findAndRemove((job) => job.id === jobCategory.id);
          toast(res.message);
        },
      },
    );
  };

  const restoreJobCategory = (
    jobCategory: JobCategoryWithJobCategoryPhaseType,
  ) => {
    const isConfirm = confirm("本当に有効化しますか？");
    if (!isConfirm) return;
    jobCategoryRestore(
      {
        archivedJobCategoryId: jobCategory.id,
      },
      {
        onSuccess: (res) => {
          findAndRemove((job) => job.id === jobCategory.id);
          toast(res.message);
        },
      },
    );
  };

  const generateGridLists = (
    jobCategory: JobCategoryWithJobCategoryPhaseType,
  ) => {
    return [
      jobCategory.name,
      jobCategoryPhasesInCellLayout(jobCategory.jobCategoryPhases),
    ];
  };

  const breadCrumbItems = [
    { label: "職種設定", href: "/managers/job_categories" },
    { label: "無効職種", href: "/managers/archived_job_categories" },
  ];

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <div className="mt-12 grid grid-cols-2">
            <GridHeaderCells texts={["職種", "リフレクション項目"]} />
            {isFetching ? (
              <Loading />
            ) : (
              jobCategories.map((jobCategory) => (
                <GridRow
                  key={jobCategory.id}
                  lists={generateGridLists(jobCategory)}
                  openedMenu={
                    <OpenedMenuInGrid
                      cols={2}
                      buttons={[
                        {
                          text: "有効化",
                          onClick: () => restoreJobCategory(jobCategory),
                        },
                        {
                          text: "削除",
                          onClick: () => deleteJobCategory(jobCategory),
                        },
                      ]}
                    />
                  }
                />
              ))
            )}
          </div>
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
