import React, { useState, FC } from "react";
import { useParams } from "react-router-dom";

import {
  useRightSidebarState,
  useApisMembersKnowledgeInsightPostsNew,
  useSearchMembersKnowledgeInsightPosts,
  useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsIndex,
  useInterval,
  useKnowledgeSlideNavigation,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  InfiniteScroll,
  FilterIconWithSortMenu,
} from "~/components/molecules";
import {
  KnowledgeInsightPostDetailWithEditForm,
  KnowledgeInsightPostCommentsWithKnowledgeInsightPost,
  KnowledgeInsightPostSearchForm,
} from "~/components/organisms";

import { AllRoles, ManagerRole } from "~/domains";

export const MembersKnowledgeInsightPostsFromManagersIndex: FC = () => {
  const { employeeRole } = useParams<{
    employeeRole: string;
  }>();
  const role =
    AllRoles.find((role) => role.type === employeeRole) || ManagerRole;

  const { data: fetchNewData } = useApisMembersKnowledgeInsightPostsNew();

  const {
    isLoading,
    knowledgeInsightPosts,
    startDate,
    endDate,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    hasNextPage,
    sort,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    keyword,
    deleteIndexKnowledgeInsightPost,
    updateIndexKnowledgeInsightPost,
    onChangeDropdownEmployees,
    onChangeDateRange,
    onChangeKeyword,
    fetchNextPage,
    setSort,
    onChangeDropdownHasBookmarkId,
    onChangeDropdownHasCommentId,
    onSearchSubmit,
    onConditionReset,
  } = useSearchMembersKnowledgeInsightPosts({
    employeeRoleId: role.id,
  });
  const [
    selectGetCommentKnowledgeInsightPostId,
    setSelectGetCommentKnowledgeInsightPostId,
  ] = useState<string>("");
  const {
    data: knowledgeInsightPostCommentsIndexData,
    refetch: commentsRefetch,
  } = useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsIndex({
    knowledgeInsightPostId: selectGetCommentKnowledgeInsightPostId,
    config: {
      enabled: Boolean(selectGetCommentKnowledgeInsightPostId),
    },
  });

  useInterval({
    onUpdate: async () => {
      isReplyOpen &&
        selectGetCommentKnowledgeInsightPostId &&
        (await commentsRefetch());
    },
    intervalMs: 1000,
  });

  const {
    isSearchOpen,
    isReplyOpen,
    searchOpenToggle,
    setReplyOpen,
    setClose,
    isOpen,
  } = useRightSidebarState("isClose");

  const openComment = (knowledgeInsightPostId: string) => {
    setSelectGetCommentKnowledgeInsightPostId(knowledgeInsightPostId);
    setReplyOpen();
  };
  const { navigations } = useKnowledgeSlideNavigation({
    url: "/knowledge_post",
    selectedRole: role,
  });

  const breadCrumbItems = [
    { label: "ナレッジシェア", href: `/knowledge_post/${ManagerRole.type}` },
    {
      label: role === ManagerRole ? "部署" : "本部",
      href: `/knowledge_post/${employeeRole}`,
    },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          bookmarkSort={() => setSort("bookmarkCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
          className="mt-6"
        />
        <InfiniteScroll
          itemsLength={knowledgeInsightPosts.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="border-t-2 border-secondary-200"
        >
          {knowledgeInsightPosts.map((post, index) => (
            <KnowledgeInsightPostDetailWithEditForm
              key={post.id}
              knowledgeInsightPost={post}
              openComment={openComment}
              deleteItem={() => {
                deleteIndexKnowledgeInsightPost(index);
                if (selectGetCommentKnowledgeInsightPostId === post.id) {
                  setClose();
                  setSelectGetCommentKnowledgeInsightPostId("");
                }
              }}
              updateItem={(newItem) =>
                updateIndexKnowledgeInsightPost(newItem, index)
              }
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label={isSearchOpen ? "検索条件" : "リプライ"}
          isOpen={isOpen}
        >
          {isSearchOpen && (
            <KnowledgeInsightPostSearchForm
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
              date={{
                startDate: startDate,
                endDate: endDate,
                onChange: onChangeDateRange,
              }}
              employee={{
                options: selectableDropdownEmployees,
                value: selectedDropdownEmployees,
                onChange: onChangeDropdownEmployees,
              }}
              hasComment={{
                options: selectableDropdownHasCommentIds,
                value: selectedDropdownHasCommentId,
                onChange: onChangeDropdownHasCommentId,
              }}
              hasBookmark={{
                options: selectableDropdownHasBookmarkIds,
                value: selectedDropdownHasBookmarkId,
                onChange: onChangeDropdownHasBookmarkId,
              }}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
            />
          )}
          {isReplyOpen && knowledgeInsightPostCommentsIndexData && (
            <KnowledgeInsightPostCommentsWithKnowledgeInsightPost
              className="mt-6"
              refetch={commentsRefetch}
              mentions={fetchNewData?.mentionableEmployees || []}
              knowledgeInsightPost={
                knowledgeInsightPostCommentsIndexData.knowledgeInsightPost
              }
              knowledgeInsightPostComments={
                knowledgeInsightPostCommentsIndexData.knowledgeInsightPostComments
              }
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
