import Cookies from "js-cookie";

import { ApiClient } from "./ApiClient";

import {
  SignInParamsType,
  ResponseHeadersType,
  ApisPartnerConsolesCompaniesGuestSignInsMutateResponseType,
} from "~/domains";

type CookieType = {
  expires: number;
  sameSite: "Lax";
  secure: boolean;
};

const cookieOptions: CookieType = {
  expires: 7, // NOTE: Cookieの有効期限を7日間に設定
  sameSite: "Lax",
  secure: process.env.NODE_ENV === "development" ? false : true,
} as const satisfies CookieType;

const rootDomain = `.${process.env.FRONTEND_ROOT_DOMAIN}`;

export const setAuthenticationCookies = (
  headers: ResponseHeadersType & {
    subdomain?: string;
  },
): void => {
  const option = headers["subdomain"]
    ? {
        ...cookieOptions,
        domain: rootDomain,
      }
    : cookieOptions;
  Cookies.set("access-token", headers["access-token"], option);
  Cookies.set("client", headers["client"], option);
  Cookies.set("uid", headers["uid"], option);
};

export const setAuthenticationUidCookies = (uid: string): void => {
  Cookies.set("uid", uid, cookieOptions);
};

const removeAuthenticationCookies = () => {
  Cookies.remove("access-token", cookieOptions);
  Cookies.remove("access-token", { ...cookieOptions, domain: rootDomain });
  Cookies.remove("client", cookieOptions);
  Cookies.remove("client", { ...cookieOptions, domain: rootDomain });
  Cookies.remove("uid", cookieOptions);
  Cookies.remove("uid", { ...cookieOptions, domain: rootDomain });
};

type SignInType = {
  signInParams: SignInParamsType;
  isAdmin: boolean;
  onSuccess: () => void;
  onError: (err: { message: string }) => void;
};

type GuestSignInType = {
  companyId: string;
};

export const GuestSignInRequest = async ({
  companyId,
}: GuestSignInType): Promise<void> => {
  const apiClient = new ApiClient();
  await apiClient
    .post<ApisPartnerConsolesCompaniesGuestSignInsMutateResponseType>(
      `/api/partner_consoles/companies/${companyId}/guest_sign_ins`,
    )
    .then((response) => {
      removeAuthenticationCookies();
      return response;
    })
    .then((response) => {
      setAuthenticationCookies({
        "access-token": response.data.token,
        client: response.data.client,
        uid: response.data.uid,
        subdomain: response.data.subdomain,
      });
      return response;
    })
    .then((response) => {
      const url = `${process.env.API_PROTOCOL}://${response.data.subdomain}.${
        process.env.FRONTEND_ROOT_DOMAIN
      }${process.env.NODE_ENV === "development" ? ":8080" : ""}`;
      window.location.assign(url);
    });
};

export const SignInRequest = async ({
  signInParams,
  isAdmin,
  onSuccess,
  onError,
}: SignInType): Promise<void> => {
  const apiClient = new ApiClient();
  const apiUrl = isAdmin
    ? "/api/admin_operator_authenticate/sign_in"
    : "/api/authenticate/sign_in";
  await apiClient
    .post(apiUrl, signInParams)
    .then((response) => {
      removeAuthenticationCookies();
      return response;
    })
    .then((response) => {
      response.headers && setAuthenticationCookies(response.headers);
    })
    .then(() => {
      onSuccess();
    })
    .catch((err) => {
      onError(err as { message: string });
    });
};

type SignOutType = {
  isAdmin: boolean;
  onSuccess: () => void;
  onError: (err: { message: string }) => void;
};
export const SignOut = async ({
  isAdmin,
  onSuccess,
  onError,
}: SignOutType): Promise<void> => {
  const apiClient = new ApiClient();
  const apiUrl = isAdmin
    ? "/api/admin_operator_authenticate/sign_out"
    : "/api/authenticate/sign_out";
  await apiClient
    .delete(apiUrl)
    .then(() => {
      removeAuthenticationCookies();
    })
    .then(() => {
      onSuccess();
    })
    .catch((err) => {
      onError(err as { message: string });
    });
};
