import React, { FC } from "react";

import { Link } from "~/components/atoms";

type PropsType = {
  companyId?: string;
  breadCrumbText: string;
};

export const PartnerConsoleSidebar: FC<PropsType> = ({
  companyId = "",
  breadCrumbText,
}: PropsType) => {
  const navs = [
    {
      label: "会社一覧",
      href: "/partner_consoles",
    },
    {
      label: "プロフィール編集",
      href: "/partner_consoles/setting/profile",
    },
    {
      label: "累計ランキング",
      href: `/partner_consoles/analytics?company_id=${companyId}`,
    },
    {
      label: "累計分析",
      href: `/partner_consoles/analytics/cumulative_analysis?company_id=${companyId}`,
    },
    {
      label: "内訳分析",
      href: `/partner_consoles/analytics/detail_analysis?company_id=${companyId}`,
    },
    {
      label: "リフレクション・チャレンジ・改善・相談",
      href: `/partner_consoles/analytics/posts_calendars?company_id=${companyId}`,
    },
    {
      label: "フィードバック分析",
      href: `/partner_consoles/analytics/feedback_analysis?company_id=${companyId}`,
    },
    {
      label: "リフレクション目標分析",
      href: `/partner_consoles/analytics/insight_post_objectives?company_id=${companyId}`,
    },
    {
      label: "KPI・KGI分析",
      href: `/partner_consoles/analytics/kpi_terms?company_id=${companyId}`,
    },
    {
      label: "ナレッジシェア分析",
      href: `/partner_consoles/analytics/knowledge_insight_posts?company_id=${companyId}`,
    },
    {
      label: "ライブラリ分析",
      href: `/partner_consoles/analytics/document_file_categories?company_id=${companyId}`,
    },
    {
      label: "ロールプレイング分析",
      href: `/partner_consoles/analytics/roleplaying_conditions?company_id=${companyId}`,
    },
    {
      label: "1on1・MTG分析",
      href: `/partner_consoles/analytics/meeting_notes?company_id=${companyId}`,
    },
    {
      label: "Q&A分析",
      href: `/partner_consoles/analytics/question_answer_categories?company_id=${companyId}`,
    },
    {
      label: "スキルマップ分析",
      href: `/partner_consoles/analytics/skill_evaluation_standard_summary_categories?company_id=${companyId}`,
    },
    {
      label: "スキルチェック分析",
      href: `/partner_consoles/analytics/skill_evaluation_terms?company_id=${companyId}`,
    },
    {
      label: "ブログ分析",
      href: `/partner_consoles/analytics/sales_concept_categories?company_id=${companyId}`,
    },
  ];

  return (
    <>
      {navs.map((nav) => (
        <Link
          key={nav.label}
          to={nav.href}
          className={`block truncate font-medium px-4 py-1.5 ${
            nav.label === breadCrumbText ? "bg-primary-600 text-white" : ""
          }`}
        >
          {nav.label}
        </Link>
      ))}
    </>
  );
};
