import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { useApisManagersInsightFocusKaizenContentTemplatesEdit } from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { InsightFocusKaizenContentTemplateForm } from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const ManagersInsightFocusKaizenContentTemplatesEdit: FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data, isError } =
    useApisManagersInsightFocusKaizenContentTemplatesEdit({
      id,
    });

  const breadCrumbItems = [
    {
      label: "各種テンプレ",
      href: "/managers/insight_focus_kaizen_content_templates",
    },
    {
      label: "編集",
      href: `/managers/insight_focus_kaizen_content_templates/${id}/edit`,
    },
  ];

  if (isError) return <NotFoundIndex />;

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        {data && (
          <InsightFocusKaizenContentTemplateForm
            insightFocusKaizenContentTemplate={
              data.insightFocusKaizenContentTemplate
            }
          />
        )}
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
