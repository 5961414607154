import React, { FC } from "react";

import { startOfMonth, endOfMonth } from "date-fns";

import {
  useApisAnalyticsPlansDocumentFileCategoriesIndex,
  useDateRange,
  useMultiDivisionMultiSectionDropdown,
  useProvidersCurrentEmployee,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import { AnalyticsPlansCountDisplayBoxes } from "~/components/organisms";

const today = new Date();

export const AnalyticsPlansDocumentFileCategoriesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const breadCrumbItems = [
    {
      label: "ライブラリ分析",
      href: "/analytics_plans/document_file_categories",
    },
  ];

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const [
    {
      startDate,
      formattedStartDate,
      endDate,
      formattedEndDate,
      onChange: onChangeDateRange,
    },
  ] = useDateRange([startOfMonth(today), endOfMonth(today)]);

  const { data, isLoading } = useApisAnalyticsPlansDocumentFileCategoriesIndex({
    params: {
      businessDivisionIds: selectedDivisions.map((division) => division.id),
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      fromDate: formattedStartDate || undefined,
      toDate: formattedEndDate || undefined,
    },
    config: {
      enabled: !!(formattedStartDate && formattedEndDate),
    },
  });

  return (
    <>
      <EmployeeLayout
        withRightSidebar={false}
        breadCrumbItems={breadCrumbItems}
      >
        <EmployeeLayoutMainContent withRightSidebar={false}>
          <AnalyticsPlansCountDisplayBoxes
            selectableDivisions={selectableDivisions}
            selectedDivisions={selectedDivisions}
            onDivisionChange={onDivisionChange}
            optionSelectableSections={optionSelectableSections}
            optionSelectedSections={optionSelectedSections}
            onSectionChange={onSectionChange}
            startDate={startDate}
            endDate={endDate}
            onChangeDateRange={onChangeDateRange}
            data={
              data
                ? {
                    managersPostsCount: data.managersPostsCount,
                    ownersPostsCount: data.ownersPostsCount,
                    others: [
                      {
                        count: data.postsDownloadsCount,
                        title: "ダウンロード数",
                      },
                      {
                        count: data.postsPreviewsCount,
                        title: "プレビュー数",
                      },
                    ],
                  }
                : undefined
            }
            rankings={[
              {
                title: "ユーザー別ダウンロード数",
                data: data?.postsDownloadsEmployeesRankings,
              },
              {
                title: "ライブラリ別ダウンロード数",
                data: data?.postsDownloadsDocumentFilesRankings,
              },
              {
                title: "ユーザー別プレビュー数",
                data: data?.postsPreviewsEmployeesRankings,
              },
              {
                title: "ライブラリ別プレビュー数",
                data: data?.postsPreviewsDocumentFilesRankings,
              },
            ]}
            isLoading={isLoading}
          />
        </EmployeeLayoutMainContent>
      </EmployeeLayout>
    </>
  );
};
