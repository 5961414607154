import React, { FC, MouseEvent } from "react";

import { currentFormatTimestamp } from "~/libs";

import { downloadBlob, arrayToCsv } from "~/utils";

import { Button } from "~/components/atoms";
import { RangeDatePicker } from "~/components/molecules";
import {
  AnalysisDetailOfPostGraph,
  DivisionAndSectionAndEmployeeDropdownField,
} from "~/components/organisms";

import {
  BusinessDivisionType,
  ParentType,
  ApiAnalyticsPlansDetailAnalysisIndexResponseType,
  RangeDatePropsType,
  ChildType,
} from "~/domains";

type PropsType = {
  formattedStartDate: string | null;
  formattedEndDate: string | null;
  selectedDivisions: BusinessDivisionType[];
  optionSelectedSections: ChildType[];
  selectedEmployee?: ParentType;
  data?: ApiAnalyticsPlansDetailAnalysisIndexResponseType;
  className?: string;
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  onChangeDateRange: (value: RangeDatePropsType) => void;
  onDivisionChange: (newValue: ParentType[]) => void;
  onSectionChange: (newValue: ChildType[]) => void;
  onEmployeeChange: (newValue: ParentType | undefined) => void;
  selectableDivisions: BusinessDivisionType[];
  optionSelectableSections: ChildType[];
  selectedDropdownSections: ChildType[];
  selectableEmployees: ParentType[];
};

const today = new Date();

export const AnalyticsDetailAnalysisTemplate: FC<PropsType> = ({
  formattedStartDate,
  formattedEndDate,
  selectedDivisions,
  optionSelectedSections,
  selectedEmployee,
  isLoading,
  className = "",
  startDate,
  endDate,
  onChangeDateRange,
  onDivisionChange,
  onSectionChange,
  onEmployeeChange,
  selectableDivisions,
  optionSelectableSections,
  selectableEmployees,
  data,
}: PropsType) => {
  const handleExportCsv = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    downloadBlob(
      arrayToCsv(generateCsvData()),
      `${currentFormatTimestamp}-detail_analysis.csv`,
    );
  };

  const generateCsvData = () => {
    return [
      ["期間", `${formattedStartDate} ~ ${formattedEndDate}`],
      [
        "部署",
        selectedDivisions.map((division) => division.name).join(", ") ||
          "すべての部署",
      ],
      [
        "課",
        optionSelectedSections?.length
          ? optionSelectedSections.map((section) => section.name).join(", ")
          : selectedDivisions?.length
            ? "選択された部署に属するすべての課"
            : "すべての課",
      ],
      ["ユーザー", selectedEmployee?.name || "すべてのユーザー"],
      ...[
        (data?.focusChallengeEmployeesDetailAnalysis || []).flatMap(
          (item, index) => [
            [
              index === 0 ? "リフレクション" : "",
              item.name,
              item.postsTotalCount,
            ],
            ["", `${item.name}(平均)`, item.postsAverageCount],
          ],
        ),
        (data?.focusChallengeEmployeesDetailAnalysis || []).flatMap(
          (item, index) => [
            [index === 0 ? "チャレンジ" : "", item.name, item.postsTotalCount],
            ["", `${item.name}(平均)`, item.postsAverageCount],
          ],
        ),
        (data?.kaizenIdeasDetailAnalysis || []).flatMap((item, index) => [
          [index === 0 ? "改善・相談" : "", item.name, item.postsTotalCount],
          ["", `${item.name}(平均)`, item.postsAverageCount],
        ]),
        (data?.sharedInsightPostsDetailAnalysis || []).flatMap(
          (item, index) => [
            [
              index === 0 ? "ナレッジシェア" : "",
              item.name,
              item.postsTotalCount,
            ],
            ["", `${item.name}(平均)`, item.postsAverageCount],
          ],
        ),
      ].flat(),
    ];
  };

  return (
    <div className={className}>
      <div className="grid miniTablet:grid-cols-2 gap-4">
        <DivisionAndSectionAndEmployeeDropdownField
          selectableDropdownDivisions={selectableDivisions}
          selectedDropdownDivisions={selectedDivisions}
          onDivisionChange={onDivisionChange}
          selectableDropdownSections={optionSelectableSections}
          selectedDropdownSections={optionSelectedSections}
          onSectionChange={onSectionChange}
          selectableDropdownEmployees={selectableEmployees}
          selectedDropdownEmployee={selectedEmployee}
          onEmployeeChange={onEmployeeChange}
        />
        <RangeDatePicker
          labelText="期間"
          startDate={startDate}
          endDate={endDate}
          onChange={onChangeDateRange}
          maxDate={today}
        />
      </div>
      <div className="mt-6 text-right">
        <Button
          outline
          text="CSVエクスポート"
          onClick={handleExportCsv}
          color="primary"
          readonly={isLoading}
        />
      </div>
      <div className="grid miniTablet:grid-cols-2 gap-6 mt-6">
        <AnalysisDetailOfPostGraph
          title="リフレクション"
          isLoading={isLoading}
          postAnalysis={data?.insightPostsDetailAnalysis}
        />
        <AnalysisDetailOfPostGraph
          title="チャレンジ"
          isLoading={isLoading}
          postAnalysis={data?.focusChallengeEmployeesDetailAnalysis}
        />
        <AnalysisDetailOfPostGraph
          title="改善・相談"
          isLoading={isLoading}
          postAnalysis={data?.kaizenIdeasDetailAnalysis}
        />
        <AnalysisDetailOfPostGraph
          title="ナレッジシェアされた数"
          isLoading={isLoading}
          postAnalysis={data?.sharedInsightPostsDetailAnalysis}
        />
      </div>
    </div>
  );
};
