import React, { FC, useState } from "react";

import {
  useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesIndex,
  useEmployeeSurveysSlideNavigation,
  useRightSidebarState,
  useSearchMembersEmployeeSurveySubmissions,
} from "~/hooks";

import {
  Button,
  DropDownField,
  Form,
  FormSubmitButton,
  MultipleDropDownField,
  TextField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  FilterIconWithSortMenu,
  InfiniteScroll,
  RightSidebarToggleIconWithLabel,
  TitleWithChild,
} from "~/components/molecules";
import {
  DivisionAndSectionAndEmployeesDropdownField,
  EmployeeSurveySubmissionDetail,
  EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission,
  SelectDateRangeButtons,
} from "~/components/organisms";

import { MyRoutes } from "~/config/Paths";

export const MembersEmployeeSurveySubmissionsIndex: FC = () => {
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const {
    searchOpenToggle,
    setClose,
    isOpen,
    isReplyOpen,
    isSearchOpen,
    setReplyOpen,
    currentOpenLabel,
  } = useRightSidebarState("isClose");
  const [selectGetReplyId, setSelectGetReplyId] = useState<string>("");
  const { data: repliesIndexData, refetch: repliesRefetch } =
    useApisMembersEmployeeSurveySubmissionsEmployeeSurveySubmissionRepliesIndex(
      {
        employeeSurveySubmissionId: selectGetReplyId,
        config: {
          enabled: Boolean(selectGetReplyId),
        },
      },
    );

  const breadCrumbItems = [
    {
      label: "チェックアウト",
      href: MyRoutes.Members.inputEmployeeSurveysIndex({
        q: { businessSectionId: businessSectionId },
      }),
    },
    {
      label: "共有",
      href: MyRoutes.Members.employeeSurveySubmissionsIndex({
        q: { businessSectionId },
      }),
    },
  ];

  const { navigations } = useEmployeeSurveysSlideNavigation({
    businessSectionId,
    selectedArray: [false, true],
  });

  const {
    sort,
    setSort,
    keyword,
    isFetching,
    fetchNextPage,
    hasNextPage,
    startDate,
    endDate,
    employeeSurveySubmissions,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    onChangeDropdownHasBookmarkId,
    selectableDropdownHasReplyIds,
    selectedDropdownHasReplyId,
    onChangeDropdownHasReplyId,
    onChangeDateRange,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
    refetch,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    onEmployeesChange,
    selectableEmployeeSurveys,
    selectedEmployeeSurveys,
    onChangeEmployeeSurveys,
  } = useSearchMembersEmployeeSurveySubmissions({
    onlyShareable: true,
  });

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent
        navigations={navigations}
        withRightSidebar
        className="bg-primary-200"
        navigationClassName="!bg-primary-200"
      >
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          bookmarkSort={() => setSort("bookmarkCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
          className="mt-6"
        />
        <InfiniteScroll
          itemsLength={employeeSurveySubmissions.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isFetching}
          className="border-t-2 border-secondary-200"
        >
          {employeeSurveySubmissions.map((submission) => (
            <EmployeeSurveySubmissionDetail
              key={submission.id}
              employeeSurveySubmission={submission}
              className="mt-12"
              openReply={() => {
                setSelectGetReplyId(submission.id);
                setReplyOpen();
              }}
              refetch={refetch}
              canDelete
            />
          ))}
        </InfiniteScroll>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar wrapperClassName="bg-primary-200">
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label={currentOpenLabel}
          isOpen={isOpen}
        >
          {isSearchOpen && (
            <Form onSubmit={onSearchSubmit} className="mt-6 space-y-6">
              <TitleWithChild title="期間指定">
                <SelectDateRangeButtons
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onChangeDateRange}
                  className="mt-3.5"
                />
              </TitleWithChild>
              <TitleWithChild
                title="部署・課・ユーザー"
                className="mt-2 space-y-6"
              >
                <DivisionAndSectionAndEmployeesDropdownField
                  selectableDropdownDivisions={selectableDropdownDivisions}
                  selectedDropdownDivisions={selectedDropdownDivisions}
                  onDivisionChange={onDivisionChange}
                  selectableDropdownSections={selectableDropdownSections}
                  selectedDropdownSections={selectedDropdownSections}
                  onSectionChange={onSectionChange}
                  selectableDropdownEmployees={selectableDropdownEmployees}
                  selectedDropdownEmployees={selectedDropdownEmployees}
                  onEmployeesChange={onEmployeesChange}
                />
              </TitleWithChild>
              <TitleWithChild title="タイトル">
                <MultipleDropDownField
                  name="employeeSurveyIds"
                  options={selectableEmployeeSurveys}
                  value={selectedEmployeeSurveys}
                  onChange={onChangeEmployeeSurveys}
                />
              </TitleWithChild>
              <TitleWithChild title="リプライ">
                <DropDownField
                  name="hasReply"
                  options={selectableDropdownHasReplyIds}
                  value={selectedDropdownHasReplyId}
                  onChange={onChangeDropdownHasReplyId}
                />
              </TitleWithChild>
              <TitleWithChild title="自分のブックマーク">
                <DropDownField
                  name="hasBookmark"
                  options={selectableDropdownHasBookmarkIds}
                  value={selectedDropdownHasBookmarkId}
                  onChange={onChangeDropdownHasBookmarkId}
                />
              </TitleWithChild>
              <TitleWithChild title="フリーワード">
                <TextField
                  value={keyword}
                  placeholder="キーワード"
                  type="search"
                  name="keyword"
                  onChange={onChangeKeyword}
                />
              </TitleWithChild>
              <div className="space-x-4 flex">
                <Button
                  text="リセット"
                  color="gray"
                  outline
                  className="w-full"
                  onClick={onConditionReset}
                />
                <FormSubmitButton
                  value="検索する"
                  color="primary"
                  className="w-full"
                />
              </div>
            </Form>
          )}
          {isReplyOpen && repliesIndexData && (
            <>
              <EmployeeSurveySubmissionRepliesWithEmployeeSurveySubmission
                className="mt-6"
                employeeSurveySubmission={
                  repliesIndexData.employeeSurveySubmission
                }
                mentions={[]}
                employeeSurveySubmissionReplies={
                  repliesIndexData.employeeSurveySubmissionReplies || []
                }
                refetch={repliesRefetch}
              />
            </>
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
