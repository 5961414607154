import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";

import {
  useSalesConceptCategoriesSlideNavigation,
  useDropdown,
  useProvidersCurrentEmployee,
  useRightSidebarState,
  useSearchMembersBusinessSectionsSalesConceptCategories,
} from "~/hooks";

import { CatchTitleBorder, DropDownField } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  InfiniteScrollWithMoreButton,
  RightSidebarToggleIconWithLabel,
  Thumbnail,
} from "~/components/molecules";
import { SalesConceptSearchForm } from "~/components/organisms";

import { AllRoles, ManagerRole } from "~/domains";

export const MembersSalesConceptCategoriesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";
  const { employeeRole } = useParams<{
    employeeRole: string;
  }>();
  const role =
    AllRoles.find((role) => role.type === employeeRole) || ManagerRole;

  const [
    {
      dropdownValue: selectedBusinessSection,
      dropdownSelectableValue: selectableBusinessSections,
      onChange: onChangeBusinessSection,
    },
  ] = useDropdown({
    selectableValue: currentEmployee?.businessSections || [],
    initialValue: currentEmployee?.businessSections.find(
      (businessSection) => businessSection.id === businessSectionId,
    ),
  });

  const {
    salesConceptCategories,
    selectableTags,
    selectedTags,
    selectableProvidingServices,
    selectedProvidingServices,
    hasNextPage,
    keyword,
    isLoading,
    onSearchSubmit,
    fetchNextPage,
    onChangeTags,
    onChangeProvidingServices,
    onChangeKeyword,
    onConditionReset,
  } = useSearchMembersBusinessSectionsSalesConceptCategories({
    businessSectionId: selectedBusinessSection?.value,
    employeeRoleId: role.id,
  });
  const { navigations } = useSalesConceptCategoriesSlideNavigation({
    businessSectionId: selectedBusinessSection?.value,
    selectedRole: role,
  });

  const { searchOpenToggle, setClose, isOpen } =
    useRightSidebarState("isClose");
  const breadCrumbItems = [
    {
      label: "ブログ",
      href: `/sales_concept_category/${ManagerRole.type}`,
    },
    {
      label: role === ManagerRole ? "部署" : "本部",
      href: `/sales_concept_category/${employeeRole}`,
    },
  ];
  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
          <DropDownField
            placeholder="課を選択"
            name="businessSectionId"
            className="mt-9"
            value={selectedBusinessSection}
            options={selectableBusinessSections}
            onChange={onChangeBusinessSection}
          />
          <InfiniteScrollWithMoreButton
            itemsLength={salesConceptCategories.length}
            nextFetchFunction={fetchNextPage}
            hasMore={hasNextPage}
            isLoading={isLoading}
            className="mt-8"
            scrollClassName="space-y-9"
          >
            {salesConceptCategories.map((item) => (
              <div key={item.id}>
                <CatchTitleBorder
                  text={item.name}
                  count={item.salesConcepts.length}
                />
                <div className="grid grid-cols-2 miniTablet:grid-cols-3 gap-y-6 gap-x-4 mt-6">
                  {item.salesConcepts.map((concept) => (
                    <Link
                      key={concept.id}
                      to={`/sales_concepts/${concept.id}?employee_role=${employeeRole}&business_section_id=${selectedBusinessSection?.value}`}
                    >
                      <Thumbnail
                        title={concept.title}
                        thumbnailImageUrl={concept.topImage.url}
                        tags={concept.providingServices.map(
                          (service) => service.name,
                        )}
                        contentBgColorClassName="bg-transparent"
                      />
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </InfiniteScrollWithMoreButton>
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          <RightSidebarToggleIconWithLabel
            iconOnClick={searchOpenToggle}
            handleOnClose={setClose}
            label="検索条件"
            isOpen={isOpen}
          >
            <SalesConceptSearchForm
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
              providingService={{
                option: selectableProvidingServices,
                value: selectedProvidingServices,
                onChange: onChangeProvidingServices,
              }}
              tag={{
                option: selectableTags,
                value: selectedTags,
                onChange: onChangeTags,
              }}
            />
          </RightSidebarToggleIconWithLabel>
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
