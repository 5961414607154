import React, { FC } from "react";

import { useSearchMembersEmployees } from "~/hooks";

import { Avatar, Link, TextField } from "~/components/atoms";
import { InfiniteScrollWithMoreButton } from "~/components/molecules";

type PropsType = {
  breadCrumbHref: string;
};

export const EmployeesSidebar: FC<PropsType> = ({
  breadCrumbHref,
}: PropsType) => {
  const {
    isLoading,
    employees,
    hasNextPage,
    fetchNextPage,
    onChangeNameKeyword,
  } = useSearchMembersEmployees();
  return (
    <div className="miniTablet:mb-16">
      <div className="mx-4">
        <TextField
          type="text"
          name="nameKeyword"
          placeholder="ユーザー名で検索"
          onChange={onChangeNameKeyword}
        />
      </div>
      <InfiniteScrollWithMoreButton
        itemsLength={employees.length}
        nextFetchFunction={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading}
        className="mt-4"
        scrollClassName=""
        moreButtonClassName="mx-4"
      >
        {employees.map((employee) => {
          const href = `/employees/${employee.id}/direct_message_rooms`;
          const isHrefMatch = breadCrumbHref === href;
          return (
            <Link
              key={employee.id}
              to={href}
              className={`flex items-center justify-between px-4 py-2 ${
                isHrefMatch
                  ? "bg-primary-600 text-white"
                  : "hover:bg-primary-300"
              }`}
            >
              <div className="flex items-center truncate space-x-2">
                <Avatar src={employee.avatarUrl} size="xs" />
                <span className={"text-sm truncate w-full"}>
                  {employee.name}
                </span>
              </div>
              <span
                className={`${
                  isHrefMatch ? "text-white" : "text-secondary-600"
                }`}
              >
                {employee.unreadDirectMessagesCount || null}
              </span>
            </Link>
          );
        })}
      </InfiniteScrollWithMoreButton>
    </div>
  );
};
