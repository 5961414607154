import React, { FC, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useInput,
  useMultipleDropdown,
  useProvidersCurrentEmployee,
  useApisManagersProvidingServicesCreate,
  useBoolean,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { ProvidingServiceForm } from "~/components/organisms";

import { OwnerRole } from "~/domains";

export const ManagersProvidingServicesNew: FC = () => {
  const navigate = useNavigate();

  const { currentEmployee } = useProvidersCurrentEmployee();

  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(false);
  const [
    { value: providingServiceName, onChange: onChangeProvidingServiceName },
  ] = useInput("");
  const [dropdownBusinessSections] = useMultipleDropdown({
    selectableValue: currentEmployee?.businessSections || [],
  });

  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisManagersProvidingServicesCreate();
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!dropdownBusinessSections.value) {
      toast("課が選択されていません");
      return;
    }
    const body = {
      name: providingServiceName,
      allBusinessSectionsDistribution,
      businessSectionIds: dropdownBusinessSections.value?.map((val) => val.id),
    };
    postRequest(
      {
        body,
      },
      {
        onSuccess: (data) => {
          navigate("/managers/providing_services");
          toast(data?.message);
        },
      },
    );
  };

  const breadCrumbItems = [
    { label: "ジャンル設定", href: "/managers/providing_services" },
    { label: "新規作成", href: "/managers/providing_services/new" },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <ProvidingServiceForm
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          providingServiceName={providingServiceName}
          onChangeProvidingServiceName={onChangeProvidingServiceName}
          selectedBusinessSections={dropdownBusinessSections.dropdownValue}
          selectableBusinessSections={
            dropdownBusinessSections.dropdownSelectableValue
          }
          onChangeBusinessSections={dropdownBusinessSections.onChange}
          allBusinessSectionsDistribution={allBusinessSectionsDistribution}
          onChangeAllBusinessSectionsDistribution={
            onChangeAllBusinessSectionsDistribution
          }
          isOwner={currentEmployee?.employeeRole.id === OwnerRole.id}
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
