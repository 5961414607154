import React, { FC } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

type PropsType = {
  title?: string;
  datasets: {
    name: string;
    value: number;
  }[];
  className?: string;
};

export const AreaChart: FC<PropsType> = ({
  title,
  datasets,
  className = "",
}: PropsType) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: !!title,
        text: title,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const data = {
    labels: datasets.map((item) => item.name),
    datasets: [
      {
        fill: true,
        label: "実績",
        data: datasets.map((item) => item.value),
        borderColor: "#0086CC",
        backgroundColor: "#EBF8FD",
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className={`min-h-[250px] w-full ${className}`}>
      <Line options={options} data={data} />
    </div>
  );
};
