import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useBoolean,
  useApisMembersInsightPostsInsightPostWithCommentsShow,
} from "~/hooks";

import { RightSidebarTitle } from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  InsightPostDetailWithEditForm,
  InsightPostCommentsWithInsightPost,
} from "~/components/organisms";
import { NotFoundIndex } from "~/components/pages";

export const MembersInsightPostsShow: FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams<{ id: string }>();
  const { isChecked: replayOpen, toggle } = useBoolean(true);
  const breadCrumbItems = [
    { label: "リフレクション", href: "/insight_posts" },
    { label: "詳細", href: `/insight_posts/${id}` },
  ];

  const { data, refetch, isError } =
    useApisMembersInsightPostsInsightPostWithCommentsShow({
      insightPostId: id,
    });

  if (isError) return <NotFoundIndex />;

  return (
    <>
      <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
        <EmployeeLayoutMainContent navigations={[]} withRightSidebar>
          <div className="mt-2">
            {data && (
              <InsightPostDetailWithEditForm
                insightPost={data.insightPost}
                mentions={data.mentionableEmployees}
                selectableProvidingServices={data.selectableProvidingServices}
                selectableJobCategoryPhases={data?.selectableJobCategoryPhases}
                openComment={toggle}
                updateItem={() => refetch()}
                deleteItem={() => navigate("/")}
                isHighlight={data.insightPost.id === id}
              />
            )}
          </div>
        </EmployeeLayoutMainContent>
        <EmployeeLayoutRightSidebar>
          {replayOpen && data && (
            <>
              <RightSidebarTitle title="リプライ" />
              <InsightPostCommentsWithInsightPost
                className="mt-6 mx-4"
                insightPost={data.insightPost}
                mentions={data?.mentionableEmployees}
                insightPostComments={data.insightPostComments}
                refetch={refetch}
              />
            </>
          )}
        </EmployeeLayoutRightSidebar>
      </EmployeeLayout>
    </>
  );
};
