import React, { FC } from "react";

import {
  useProvidersCurrentEmployee,
  useManagerCategoryDisplayOrderSearch,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import { CategoryDisplayOrderChange } from "~/components/organisms";

import { ApiManagersDocumentFileCategoriesDisplayOrderIndex } from "~/domains";

export const ManagersDocumentFileCategoriesDisplayOrderIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const fetchUrl = (id: string) => {
    return `/api/managers/business_sections/${id}/document_file_categories`;
  };

  const updateUrl = (id: string) => {
    return `/api/managers/business_sections/${id}/document_file_category/display_order`;
  };

  const { onSearchSubmit, fetch, divisionSection, changeDisplayOrder } =
    useManagerCategoryDisplayOrderSearch<ApiManagersDocumentFileCategoriesDisplayOrderIndex>(
      {
        selectableBusinessDivisions: currentEmployee?.businessDivisions || [],
        selectableBusinessSections: currentEmployee?.businessSections || [],
        fetchUrl: fetchUrl,
        updateUrl: updateUrl,
      },
    );

  const breadCrumbItems = [
    { label: "ライブラリ", href: "/managers/document_file_categories" },
    {
      label: "並び替え",
      href: "/managers/document_file_category/display_orders",
    },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar>
        <CategoryDisplayOrderChange
          onSearchSubmit={onSearchSubmit}
          fetch={{
            loading: fetch.loading,
            error: fetch.error,
            items: fetch.data?.documentFileCategories || [],
          }}
          divisionSection={divisionSection}
          changeDisplayOrder={changeDisplayOrder}
          catchTitle="ライブラリの並び替え"
          noContentMessage="ライブラリがありません"
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar />
    </EmployeeLayout>
  );
};
