import React, { FC, FormEvent, ChangeEvent, MouseEvent } from "react";

import {
  Button,
  Form,
  FormSubmitButton,
  MultipleDropDownField,
  TextField,
} from "~/components/atoms";
import { TitleWithChild } from "~/components/molecules";

import { MultiValueType, OptionType } from "~/domains";

type PropsType = {
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onConditionReset: (e: MouseEvent<HTMLButtonElement>) => void;
  searchKeyword: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  questionAnswerCategory: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  service: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
  tag: {
    option: OptionType[];
    value: MultiValueType<OptionType> | undefined;
    onChange: (newValue: MultiValueType<OptionType>) => void;
  };
};

export const QuestionAnswerCategorySearchForm: FC<PropsType> = ({
  onSubmit,
  onConditionReset,
  searchKeyword,
  questionAnswerCategory,
  service,
  tag,
}: PropsType) => {
  return (
    <Form onSubmit={onSubmit} className="mt-6 space-y-6">
      <>
        <TitleWithChild title="ジャンル">
          <MultipleDropDownField
            name="questionAnswerService"
            options={service.option}
            value={service.value}
            onChange={service.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="タイトル">
          <MultipleDropDownField
            name="questionAnswerCategory"
            options={questionAnswerCategory.option}
            value={questionAnswerCategory.value}
            onChange={questionAnswerCategory.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="タグ">
          <MultipleDropDownField
            name="documentFileTag"
            options={tag.option}
            value={tag.value}
            onChange={tag.onChange}
          />
        </TitleWithChild>
        <TitleWithChild title="フリーワード">
          <TextField
            value={searchKeyword.value}
            placeholder="キーワード"
            type="search"
            name="keyword"
            onChange={searchKeyword.onChange}
          />
        </TitleWithChild>
        <div className="space-x-4 flex">
          <Button
            text="リセット"
            color="gray"
            outline
            onClick={onConditionReset}
            className="w-full"
          />
          <FormSubmitButton
            value="検索する"
            color="primary"
            className="w-full"
          />
        </div>
      </>
    </Form>
  );
};
