import React, { FC, FormEvent } from "react";

import { toast } from "react-toastify";

import LogoLandscape from "~/assets/images/logos/kagami_landscape.png";

import { useApisPasswordResetsCreate, useInput } from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Link,
  SignInWrapper,
} from "~/components/atoms";
import { LabelWithTextField } from "~/components/molecules";

export const PasswordResetIndex: FC = () => {
  const { mutate, isLoading } = useApisPasswordResetsCreate();
  const [email] = useInput("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      {
        body: {
          email: email.value,
          redirectUrl: `${location.protocol}//${location.host}/password/edit`,
        },
      },
      {
        onSuccess: (data) => {
          toast(data.message);
        },
      },
    );
  };

  return (
    <SignInWrapper>
      <div className="mx-6 px-4 pb-12 pt-11 miniTablet:px-14 max-w-xl w-full bg-white border-secondary-400 border-solid border rounded-lg  shadow">
        <div className="flex justify-center w-8/12 mx-auto p-3">
          <img
            src={LogoLandscape as string}
            alt="Logo"
            width="269"
            height="58.5"
          />
        </div>
        <h2 className="text-lg text-center text-secondary-600 mt-9">
          パスワードを再設定
        </h2>
        <Form className="mt-9 rounded-lg mx-auto" onSubmit={handleSubmit}>
          <>
            <LabelWithTextField
              labelText="メールアドレス"
              type="email"
              placeholder="email@example.com"
              name="email"
              autoComplete="email"
              onChange={email.onChange}
              size="md"
            />
            <div className="text-center mt-6">
              <FormSubmitButton
                value="再設定用のメールを送信する"
                color="primary"
                isReadOnly={isLoading}
                size="lg"
                className="w-full"
              />
            </div>
            <div className="text-center mt-9">
              <Link to="/sign_in" className="text-primary-600">
                ログインページへ戻る
              </Link>
            </div>
          </>
        </Form>
      </div>
    </SignInWrapper>
  );
};
