import React, { FC } from "react";

import {
  useDropdown,
  useMembersBusinessSectionsSkillEvaluationStandardSummaryCategoriesSearch,
  useProvidersCurrentEmployee,
  useRightSidebarState,
  useSkillEvaluationStandardSummaryCategorySlideNavigation,
} from "~/hooks";

import {
  TextField,
  FormSubmitButton,
  Form,
  Button,
  DropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  TitleWithChild,
} from "~/components/molecules";
import { SkillEvaluationStandardSummaryCategoryTitleInfinitiesScroll } from "~/components/organisms";

export const MembersSkillEvaluationStandardSummaryCategoriesIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();
  const query = new URLSearchParams(location.search);
  const businessSectionId = query.get("business_section_id") || "";

  const [
    {
      dropdownValue: selectedBusinessSection,
      dropdownSelectableValue: selectableBusinessSections,
      onChange: onChangeBusinessSection,
    },
  ] = useDropdown({
    selectableValue: currentEmployee?.businessSections || [],
    initialValue: currentEmployee?.businessSections.find(
      (businessSection) => businessSection.id === businessSectionId,
    ),
  });

  const {
    managersSkillEvaluationStandardSummaryCategories,
    managersHasNextPage,
    ownersSkillEvaluationStandardSummaryCategories,
    ownersHasNextPage,
    keyword,
    ownersFetchNextPage,
    managersFetchNextPage,
    onChangeKeyword,
    onSearchSubmit,
    onConditionReset,
  } = useMembersBusinessSectionsSkillEvaluationStandardSummaryCategoriesSearch({
    businessSectionId: selectedBusinessSection?.value,
  });
  const { searchOpenToggle, setClose, isOpen } =
    useRightSidebarState("isClose");
  const { navigations } =
    useSkillEvaluationStandardSummaryCategorySlideNavigation({
      businessSectionId: selectedBusinessSection?.value,
      selectedArray: [true, false, false],
    });
  const breadCrumbItems = [
    {
      label: "スキルマップ",
      href: `/skill_evaluation_standard_summary_categories?business_section_id=${selectedBusinessSection?.value}`,
    },
    {
      label: "一覧",
      href: `/skill_evaluation_standard_summary_categories?business_section_id=${selectedBusinessSection?.value}`,
    },
  ];

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        <DropDownField
          placeholder="課を選択"
          name="businessSectionId"
          value={selectedBusinessSection}
          options={selectableBusinessSections}
          onChange={onChangeBusinessSection}
          className="mt-9"
        />
        <SkillEvaluationStandardSummaryCategoryTitleInfinitiesScroll
          title="本部"
          skillEvaluationStandardSummaryCategories={
            ownersSkillEvaluationStandardSummaryCategories
          }
          fetchNextPage={ownersFetchNextPage}
          hasMore={ownersHasNextPage}
          businessSectionId={selectedBusinessSection?.value}
          className="mt-6"
        />
        <SkillEvaluationStandardSummaryCategoryTitleInfinitiesScroll
          title="部署"
          skillEvaluationStandardSummaryCategories={
            managersSkillEvaluationStandardSummaryCategories
          }
          fetchNextPage={managersFetchNextPage}
          hasMore={managersHasNextPage}
          businessSectionId={selectedBusinessSection?.value}
          className="mt-9"
        />
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label="検索条件"
          isOpen={isOpen}
        >
          <Form onSubmit={onSearchSubmit} className="mt-6 space-y-6">
            <TitleWithChild title="フリーワード">
              <TextField
                value={keyword}
                placeholder="キーワード"
                type="search"
                name="keyword"
                onChange={onChangeKeyword}
              />
            </TitleWithChild>
            <div className="space-x-4 flex">
              <Button
                text="リセット"
                color="gray"
                outline
                className="w-full"
                onClick={onConditionReset}
              />
              <FormSubmitButton
                value="検索する"
                color="primary"
                className="w-full"
              />
            </div>
          </Form>
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
