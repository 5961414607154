import React, { FC } from "react";

import {
  usePartnerConsolesMultiDivisionMultiSectionDropdown,
  usePartnerConsolesCompaniesDropdown,
  useApisPartnerConsolesCompaniesAnalyticsEmployeesInsightPostObjectivesIndex,
} from "~/hooks";

import { PartnerConsoleLayout } from "~/components/layouts";
import { LabelWithDropDownField } from "~/components/molecules";
import { AnalyticsInsightPostObjectivesTemplate } from "~/components/templates";

import { EmployeeRole } from "~/domains";

export const PartnerConsolesAnalyticsInsightPostObjectivesIndex: FC = () => {
  const query = new URLSearchParams(location.search);
  const companyId = query.get("company_id") || "";
  const breadCrumbItems = [
    {
      label: "パートナー画面",
      href: "/partner_consoles",
    },
    {
      label: "リフレクション目標分析",
      href: "/partner_consoles/analytics/insight_post_objectives",
    },
  ];

  const [{ onChangeCompany, dropdownSelectableValue, dropdownValue, company }] =
    usePartnerConsolesCompaniesDropdown({
      defaultCompanyId: companyId,
    });

  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
    selectableEmployees,
    selectedEmployee,
    onEmployeeChange,
  } = usePartnerConsolesMultiDivisionMultiSectionDropdown({
    companyId: company?.id || "",
    isSelectEmployee: true,
    selectableEmployeeRoles: [EmployeeRole.MANAGER, EmployeeRole.MEMBER],
  });

  const { data, isLoading, hasNextPage, fetchNextPage } =
    useApisPartnerConsolesCompaniesAnalyticsEmployeesInsightPostObjectivesIndex(
      {
        companyId: company?.id || "",
        employeeId: selectedEmployee?.id || "",
        config: {
          enabled: !!(company?.id && selectedEmployee?.id),
        },
      },
    );

  return (
    <>
      <PartnerConsoleLayout
        breadCrumbItems={breadCrumbItems}
        companyId={company?.id}
      >
        <LabelWithDropDownField
          required
          labelText="会社"
          name="company"
          options={dropdownSelectableValue}
          value={dropdownValue}
          onChange={onChangeCompany}
        />
        <AnalyticsInsightPostObjectivesTemplate
          isLoading={isLoading}
          data={data}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          selectedDivisions={selectedDivisions}
          optionSelectedSections={optionSelectedSections}
          selectableDivisions={selectableDivisions}
          optionSelectableSections={optionSelectableSections}
          selectedDropdownSections={optionSelectedSections}
          selectedEmployee={selectedEmployee}
          onEmployeeChange={onEmployeeChange}
          selectableEmployees={selectableEmployees}
          onDivisionChange={onDivisionChange}
          onSectionChange={onSectionChange}
        />
      </PartnerConsoleLayout>
    </>
  );
};
