import React, { FC, useState, FormEvent } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

import {
  useMultiDivisionMultiSectionDropdown,
  useEditorState,
  useProvidersCurrentEmployee,
  useRightSidebarState,
  useApisMembersKnowledgeInsightPostsNew,
  useApisManagersKnowledgeInsightPostsCreate,
  useSearchManagersKnowledgeInsightPosts,
  useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsIndex,
  useEditorStateLinkContentGetter,
  useInterval,
  useBoolean,
  useKnowledgeSlideNavigation,
} from "~/hooks";

import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
  EmployeeLayoutRightSidebar,
} from "~/components/layouts";
import {
  RightSidebarToggleIconWithLabel,
  InfiniteScrollWithMoreButton,
  FilterIconWithSortMenu,
} from "~/components/molecules";
import {
  KnowledgeInsightPostForm,
  KnowledgeInsightPostDetailWithEditForm,
  KnowledgeInsightPostCommentsWithKnowledgeInsightPost,
  KnowledgeInsightPostSearchForm,
} from "~/components/organisms";

import { PreviewWithFileType, AllRoles, ManagerRole } from "~/domains";

export const ManagersKnowledgeInsightPostsFromManagersIndex: FC = () => {
  const { currentEmployee } = useProvidersCurrentEmployee();

  const { employeeRole } = useParams<{
    employeeRole: string;
  }>();
  const role =
    AllRoles.find((role) => role.type === employeeRole) || ManagerRole;
  const postAble = role?.type === currentEmployee?.employeeRole.type;

  const {
    knowledgeInsightPosts,
    startDate,
    endDate,
    selectableDropdownHasBookmarkIds,
    selectedDropdownHasBookmarkId,
    selectableDropdownHasCommentIds,
    selectedDropdownHasCommentId,
    hasNextPage,
    isLoading,
    sort,
    selectableDropdownEmployees,
    selectedDropdownEmployees,
    keyword,
    addKnowledgeInsightPost,
    deleteIndexKnowledgeInsightPost,
    updateIndexKnowledgeInsightPost,
    onChangeDropdownEmployees,
    onChangeDateRange,
    onChangeKeyword,
    fetchNextPage,
    setSort,
    onChangeDropdownHasBookmarkId,
    onChangeDropdownHasCommentId,
    onSearchSubmit,
    onConditionReset,
  } = useSearchManagersKnowledgeInsightPosts({
    employeeRoleId: role.id,
  });

  const [
    selectGetCommentKnowledgeInsightPostId,
    setSelectGetCommentKnowledgeInsightPostId,
  ] = useState<string>("");
  const [files, setFiles] = useState<PreviewWithFileType[]>([]);
  const [
    {
      jsonContent: editorStateJsonContent,
      setPlainText: setEditorStatePlainText,
      value: editorState,
      onChange: onChangeEditorState,
    },
  ] = useEditorState();
  const { fetchedData } = useEditorStateLinkContentGetter({
    watchEditorState: editorState,
    isWatch: true,
  });
  const {
    isSearchOpen,
    isReplyOpen,
    searchOpenToggle,
    setReplyOpen,
    setClose,
    isOpen,
  } = useRightSidebarState("isClose");
  const {
    data: knowledgeInsightPostCommentsIndexData,
    refetch: commentsRefetch,
  } = useApisMembersKnowledgeInsightPostsKnowledgeInsightPostCommentsIndex({
    knowledgeInsightPostId: selectGetCommentKnowledgeInsightPostId,
    config: {
      enabled: Boolean(selectGetCommentKnowledgeInsightPostId),
    },
  });

  useInterval({
    onUpdate: async () => {
      isReplyOpen &&
        selectGetCommentKnowledgeInsightPostId &&
        (await commentsRefetch());
    },
    intervalMs: 1000,
  });

  const postFormDisplay = postAble && !isOpen;

  const { mutate: postRequest, isLoading: isSubmitting } =
    useApisManagersKnowledgeInsightPostsCreate();
  const { data: fetchNewData } = useApisMembersKnowledgeInsightPostsNew();
  const {
    selectableDivisions,
    selectedDivisions,
    onDivisionChange,
    optionSelectableSections,
    optionSelectedSections,
    onSectionChange,
  } = useMultiDivisionMultiSectionDropdown({
    selectableDivisions: currentEmployee?.businessDivisions,
    selectableSections: currentEmployee?.businessSections,
  });

  const {
    isChecked: allBusinessSectionsDistribution,
    handleChange: onChangeAllBusinessSectionsDistribution,
  } = useBoolean(false);
  const { navigations } = useKnowledgeSlideNavigation({
    url: "/managers/knowledge_post",
    selectedRole: role,
  });

  const breadCrumbItems = [
    {
      label: "ナレッジシェア",
      href: `/managers/knowledge_post/${ManagerRole.type}`,
    },
    {
      label: role === ManagerRole ? "部署" : "本部",
      href: `/managers/knowledge_post/${employeeRole}`,
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body = {
      content: editorStateJsonContent,
      allBusinessSectionsDistribution,
      businessSectionIds: optionSelectedSections.map((section) => section.id),
      attachFiles: files,
      quotePostId: fetchedData?.id,
    };

    postRequest(
      {
        body,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          addKnowledgeInsightPost(data.knowledgeInsightPost);
          postFormReset();
        },
      },
    );
  };

  const postFormReset = () => {
    setEditorStatePlainText("");
    setFiles([]);
    onSectionChange([]);
    onDivisionChange([]);
    onChangeAllBusinessSectionsDistribution(false);
  };

  const openComment = (knowledgeInsightPostId: string) => {
    setSelectGetCommentKnowledgeInsightPostId(knowledgeInsightPostId);
    setReplyOpen();
  };

  return (
    <EmployeeLayout withRightSidebar breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent navigations={navigations} withRightSidebar>
        {postFormDisplay && (
          <KnowledgeInsightPostForm
            isSubmitting={isSubmitting}
            mentions={fetchNewData?.mentionableEmployees || []}
            files={files}
            onChangeFiles={setFiles}
            editorState={editorState}
            blockQuote={fetchedData}
            dropdownField={{
              selectableDivisions,
              selectedDivisions,
              onDivisionChange,
              optionSelectableSections,
              optionSelectedSections,
              onSectionChange,
              allBusinessSectionsDistribution,
              onChangeAllBusinessSectionsDistribution,
              currentEmployeeRole: currentEmployee?.employeeRole,
            }}
            handleSubmit={handleSubmit}
            setEditorState={onChangeEditorState}
            className="mt-6"
            required
          />
        )}
        <FilterIconWithSortMenu
          likeSort={() => setSort("likeCountDesc")}
          bookmarkSort={() => setSort("bookmarkCountDesc")}
          createdAtAscSort={() => setSort("createdAtAsc")}
          createdAtDescSort={() => setSort("createdAtDesc")}
          currentSortType={sort}
          className={postFormDisplay ? "mt-12" : "mt-6"}
        />
        <InfiniteScrollWithMoreButton
          itemsLength={knowledgeInsightPosts.length}
          nextFetchFunction={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading}
          className="border-t-2 border-secondary-200"
        >
          {knowledgeInsightPosts.map((post, index) => (
            <KnowledgeInsightPostDetailWithEditForm
              key={post.id}
              knowledgeInsightPost={post}
              openComment={openComment}
              deleteItem={() => {
                deleteIndexKnowledgeInsightPost(index);
                if (selectGetCommentKnowledgeInsightPostId === post.id) {
                  setSelectGetCommentKnowledgeInsightPostId("");
                  setClose();
                }
              }}
              updateItem={(newItem) =>
                updateIndexKnowledgeInsightPost(newItem, index)
              }
            />
          ))}
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
      <EmployeeLayoutRightSidebar>
        <RightSidebarToggleIconWithLabel
          iconOnClick={searchOpenToggle}
          handleOnClose={setClose}
          label={isSearchOpen ? "検索条件" : "リプライ"}
          isOpen={isOpen}
        >
          {isSearchOpen && (
            <KnowledgeInsightPostSearchForm
              onSubmit={onSearchSubmit}
              onConditionReset={onConditionReset}
              date={{
                startDate: startDate,
                endDate: endDate,
                onChange: onChangeDateRange,
              }}
              employee={{
                options: selectableDropdownEmployees,
                value: selectedDropdownEmployees,
                onChange: onChangeDropdownEmployees,
              }}
              hasComment={{
                options: selectableDropdownHasCommentIds,
                value: selectedDropdownHasCommentId,
                onChange: onChangeDropdownHasCommentId,
              }}
              hasBookmark={{
                options: selectableDropdownHasBookmarkIds,
                value: selectedDropdownHasBookmarkId,
                onChange: onChangeDropdownHasBookmarkId,
              }}
              searchKeyword={{
                value: keyword,
                onChange: onChangeKeyword,
              }}
            />
          )}
          {isReplyOpen && knowledgeInsightPostCommentsIndexData && (
            <KnowledgeInsightPostCommentsWithKnowledgeInsightPost
              className="mt-6"
              mentions={fetchNewData?.mentionableEmployees || []}
              knowledgeInsightPost={
                knowledgeInsightPostCommentsIndexData.knowledgeInsightPost
              }
              knowledgeInsightPostComments={
                knowledgeInsightPostCommentsIndexData.knowledgeInsightPostComments
              }
              refetch={commentsRefetch}
            />
          )}
        </RightSidebarToggleIconWithLabel>
      </EmployeeLayoutRightSidebar>
    </EmployeeLayout>
  );
};
