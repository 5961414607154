import React, { FC } from "react";

import { toast } from "react-toastify";

import {
  useApisManagersDocumentFileCategoriesDestroy,
  useSearchManagersDocumentFileCategories,
} from "~/hooks";

import {
  FormSubmitButton,
  Form,
  Button,
  GridHeaderCells,
  BooleanDropDownField,
} from "~/components/atoms";
import {
  EmployeeLayout,
  EmployeeLayoutMainContent,
} from "~/components/layouts";
import {
  GridRow,
  OpenedMenuInGrid,
  LabelWithTextField,
  LabelWithDropDownField,
  InfiniteScrollWithMoreButton,
  LabelWithMultipleDropdownField,
} from "~/components/molecules";
import { MultiParentsWithMultiChildrenDropdownField } from "~/components/organisms";

import { DocumentFileCategoryType } from "~/domains";

export const ManagersDocumentFileCategoriesIndex: FC = () => {
  const {
    documentFileCategories,
    keyword,
    isLoading,
    allBusinessSectionsDistribution,
    hasNextPage,
    selectableDropdownTags,
    selectedDropdownTags,
    selectableDropdownPublicStatusIds,
    selectedDropdownPublicStatusId,
    selectableDropdownDivisions,
    selectedDropdownDivisions,
    selectableDropdownSections,
    selectedDropdownSections,
    onDivisionChange,
    onSectionChange,
    onChangeDropdownTags,
    findAndRemove,
    onChangeDropdownPublicStatusId,
    fetchNextPage,
    onChangeKeyword,
    onConditionReset,
    onSearchSubmit,
    setAllBusinessSectionsDistribution,
  } = useSearchManagersDocumentFileCategories();
  const { mutate: deleteRequest } =
    useApisManagersDocumentFileCategoriesDestroy();

  const handleDelete = (documentFileCategory: DocumentFileCategoryType) => {
    const isConfirm = confirm("本当に削除しますか？");
    if (!isConfirm) return;
    deleteRequest(
      {
        id: documentFileCategory.id,
      },
      {
        onSuccess: (data) => {
          toast(data.message);
          findAndRemove((item) => item.id === documentFileCategory.id);
        },
      },
    );
  };

  const generateDocumentFileCategoryLists = (
    documentFileCategory: DocumentFileCategoryType,
  ) => {
    return [
      documentFileCategory.name,
      documentFileCategory.allBusinessSectionsDistribution
        ? "すべての部署"
        : documentFileCategory.businessDivisions
            .map((division) => division.name)
            .join(","),
      documentFileCategory.allBusinessSectionsDistribution
        ? "すべての課"
        : documentFileCategory.businessSections
            .map((section) => section.name)
            .join(","),
      documentFileCategory.publicStatus.name,
    ];
  };

  const breadCrumbItems = [
    { label: "ライブラリ", href: "/managers/document_file_categories" },
    { label: "一覧", href: "/managers/document_file_categories" },
  ];

  return (
    <EmployeeLayout withRightSidebar={false} breadCrumbItems={breadCrumbItems}>
      <EmployeeLayoutMainContent withRightSidebar={false}>
        <Form onSubmit={onSearchSubmit} className="space-y-6">
          <div className="grid miniTablet:grid-cols-2 gap-4">
            <MultiParentsWithMultiChildrenDropdownField
              allParents={selectableDropdownDivisions}
              parentsValue={selectedDropdownDivisions}
              parentLabel="部署"
              parentsOnChange={onDivisionChange}
              allChildren={selectableDropdownSections}
              childrenValue={selectedDropdownSections}
              childLabel="課"
              childrenOnChange={onSectionChange}
            />
            <BooleanDropDownField
              name="isAllBusinessSections"
              trueLabel="すべての課に配布する"
              falseLabel="個別の課に配布する"
              labelText="配布種別の選択"
              value={allBusinessSectionsDistribution}
              onChange={setAllBusinessSectionsDistribution}
            />
            <LabelWithMultipleDropdownField
              labelText="タグ"
              name="publicStatus"
              options={selectableDropdownTags}
              value={selectedDropdownTags}
              onChange={onChangeDropdownTags}
            />
            <LabelWithDropDownField
              labelText="ステータス"
              name="publicStatus"
              options={selectableDropdownPublicStatusIds}
              value={selectedDropdownPublicStatusId}
              onChange={onChangeDropdownPublicStatusId}
            />
            <LabelWithTextField
              labelText="フリーワード"
              type="search"
              name="keyword"
              placeholder="フリーワード検索"
              value={keyword}
              onChange={onChangeKeyword}
            />
          </div>
          <div className="flex items-center justify-end space-x-4">
            <Button
              color="gray"
              outline
              className="w-full miniTablet:w-auto"
              text="リセット"
              onClick={onConditionReset}
            />
            <FormSubmitButton
              value="検索する"
              color="primary"
              className="w-full miniTablet:w-auto"
            />
          </div>
        </Form>
        <InfiniteScrollWithMoreButton
          itemsLength={documentFileCategories.length}
          nextFetchFunction={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasNextPage}
          className="mt-9"
        >
          <div className="grid grid-cols-4">
            <GridHeaderCells texts={["タイトル", "部署", "課", "ステータス"]} />
            {documentFileCategories.map((documentFileCategory) => (
              <GridRow
                key={documentFileCategory.id}
                lists={generateDocumentFileCategoryLists(documentFileCategory)}
                openedMenu={
                  <OpenedMenuInGrid
                    cols={4}
                    buttons={[
                      {
                        text: "編集",
                        link: `/managers/document_file_categories/${documentFileCategory.id}/edit`,
                      },
                      {
                        text: "削除",
                        onClick: () => handleDelete(documentFileCategory),
                      },
                    ]}
                  />
                }
              />
            ))}
          </div>
        </InfiniteScrollWithMoreButton>
      </EmployeeLayoutMainContent>
    </EmployeeLayout>
  );
};
